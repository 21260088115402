import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Select, message, InputNumber, Input } from 'antd';
import CustomModal from '@app/view/components/Modals/CustomModal';
import { Forms } from '@app/helpers/Constants/forms';
import { UserContext } from '@app/contexts/UserContext';
import { IDataResult } from '@app/model/IDataResult';
import { Task } from '@app/model/Task';
import { plainToClass } from 'class-transformer';
import CustomSelect from '@app/view/components/Select/CustomSelect';
import { ClientProjects } from '@app/model/ClientProjects';
import { Project } from '@app/model/Project';
import { format } from 'date-fns';
import { NotificationInstance } from 'antd/es/notification/interface';
const { Option, OptGroup } = Select;
const { TextArea } = Input;

type NewEntryModalProps = {
  visible: boolean;
  date: Date;
  onCancel: () => void;
  refresh: (update: boolean) => void;
  api: NotificationInstance;
};

export const NewEntryModal = ({
  visible,
  date,
  onCancel,
  refresh,
  api,
}: NewEntryModalProps) => {
  const [projects, setProjects] = useState<ClientProjects[]>([]);
  const [projectTasks, setProjectTasks] = useState<Task[]>([]);
  const { user, api: API } = useContext(UserContext);
  const [form] = Form.useForm();
  const fieldName = 'entry';

  const userData = user.getUser();

  useEffect(() => {
    getClients();

    return () => {
      form.resetFields();
    };
  }, []);

  const getClients = () => {
    API.get(`api/v2/project/${userData.id}/list`)
      .then((result: IDataResult) => {
        const projects: ClientProjects | any = plainToClass(
          ClientProjects,
          result.data,
        );

        setProjects(projects);
      })
      .catch((error: any) => {
        message.error(`Could not complete request ${error}`).then();
      });
  };

  const getClientTasks = (projectID: string) => {
    if (typeof projectID !== 'undefined' && projectID) {
      API.get(`api/v2/project/${projectID}/tasks`)
        .then((result: IDataResult) => {
          const tasks: Task[] | any = plainToClass(Task, result.data);
          setProjectTasks(tasks);
        })
        .catch((error: any) => {
          api.error({
            message: 'Error',
            description: `Could not complete request ${error}`,
          });
        });
    }
  };

  const submitTask = () => {
    const fields = form.getFieldsValue().entry;
    const dateValue = format(date, 'yyyy-MM-dd');
    const payload = Object.assign(fields, {
      account_id: userData.account?.id,
      tracked_on: dateValue,
    });

    if (payload.project_id === undefined) {
      api.error({
        message: 'Error',
        description: 'Please select a project',
      });
      return;
    }

    API.query(`api/v2/tracking/${payload.project_id}`, 'POST', payload)
      .then((result: IDataResult) => {
        refresh(true);
        onCancel();
      })
      .catch((error: any) => {
        api.error({
          message: 'Error',
          description: error.message,
        });
      });
  };

  const closeForm = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <CustomModal
      onCancel={closeForm}
      customOKButton={
        <Form.Item style={{ marginBottom: 0 }}>
          <Button
            onClick={() => submitTask()}
            form={Forms.NewProjectTaskForm}
            type={'primary'}
            key={'submit'}
            htmlType={'submit'}
          >
            Create Entry
          </Button>
        </Form.Item>
      }
      hasOkButton={true}
      okText={'Done'}
      visible={visible}
      title={`New Entry`}
    >
      <Form form={form}>
        <Form.Item
          label={'Project'}
          labelCol={{ span: 24 }}
          name={[fieldName, 'project_id']}
          key={'formItem-0'}
          style={{ marginBottom: 10 }}
          required={true}
        >
          <CustomSelect
            key={'customSelect-0'}
            allowClear={false}
            onClear={() => {
              form.setFieldsValue({
                task_id: null,
              });
            }}
            placeholder={'Select Project...'}
            onChange={(value: string) => {
              getClientTasks(value);
              form.setFieldsValue({
                project_id: value,
              });
            }}
          >
            {projects &&
              projects.map((project: ClientProjects, index) => {
                return (
                  <OptGroup label={project.client} key={`optgroup-${index}`}>
                    {project.projects &&
                      project.projects.map((project: Project, pIndex) => {
                        return (
                          <Option
                            value={project.id}
                            key={`project-${index}-${pIndex}`}
                          >
                            {project.name}
                          </Option>
                        );
                      })}
                  </OptGroup>
                );
              })}
          </CustomSelect>
        </Form.Item>

        <Form.Item
          label={'Task'}
          labelCol={{ span: 24 }}
          name={[fieldName, 'task_id']}
          key={'formItem-1'}
          required={true}
        >
          <CustomSelect
            key={'customSelect-1'}
            placeholder={'Select Task...'}
            onChange={(value: string) => {
              form.setFieldsValue({
                task_id: value,
              });
            }}
          >
            {projectTasks &&
              projectTasks.map((task: Task, index) => {
                return (
                  <Option value={task.id} key={`tasks-${index}`}>
                    {task.name}
                  </Option>
                );
              })}
          </CustomSelect>
        </Form.Item>

        <Form.Item
          label={'Hours'}
          labelCol={{ span: 24 }}
          name={[fieldName, 'hours']}
          key={'formItem-2'}
        >
          <InputNumber placeholder={'0.00'} />
        </Form.Item>

        <Form.Item
          label={'Description'}
          labelCol={{ span: 24 }}
          name={[fieldName, 'description']}
          key={'formItem-3'}
        >
          <TextArea />
        </Form.Item>
      </Form>
    </CustomModal>
  );
};

export default NewEntryModal;
