import React from 'react';
import Wrapper from '../../../components/Views/Wrapper';

function InvoicePage() {
  return (
    <Wrapper title={'Invoices'}>
      <></>
    </Wrapper>
  );
}

export default InvoicePage;
