import React from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { Plus } from 'react-feather';

type AddButtonProps = {
  link: string;
  title: string;
};

export const AddButton = ({ link, title }: AddButtonProps) => {
  return (
    <Link to={link}>
      <Button
        type={'primary'}
        icon={
          <Plus
            size={14}
            className={'feather-icon feather-icon-button-prefix'}
          />
        }
      >
        {title}
      </Button>
    </Link>
  );
};

export default AddButton;
