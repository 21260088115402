import React, { useContext, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Checkbox,
  Card,
  Button,
  message,
  Spin,
  notification,
} from 'antd';
import { ChevronDown, X } from 'react-feather';
import { statesTitleCase } from '@app/helpers/Other/statesTitleCase';
import { currencies } from '@app/helpers/Data/currencies';
import { Forms } from '@app/helpers/Constants/forms';
import { timezones } from '@app/helpers/Data/timezones';
import { ITimezone } from '@app/model/ITimezone';
import { Client, ClientLocation } from '@app/model/Client';
import { IErrorResult } from '@app/model/IErrorResult';
import { IDataResult } from '@app/model/IDataResult';
import { UserContext } from '@app/contexts/UserContext';
const { Option } = Select;

type LocationCardProps = {
  clientID: number | null | undefined;
  index: number;
  onRemove: (index: number) => void;
  client: Client | null;
  setClient: (client: Client) => void;
};

type Payload = {
  location: ClientLocation[] | [];
};

export const LocationCard = ({
  index,
  onRemove,
  clientID,
  client,
  setClient,
}: LocationCardProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();
  const { api: API } = useContext(UserContext);
  const [form] = Form.useForm();
  const fieldName = 'location';
  const fieldKey = index;

  useEffect(() => {
    if (client) {
      const locationData = {
        location: {
          locationName:
            client?.meta?.location && client?.meta?.location[index]
              ? client?.meta?.location[index]?.locationName
              : '',
          addressLineOne:
            client?.meta?.location && client?.meta?.location[index]
              ? client?.meta?.location[index].addressLineOne
              : '',
          addressLineTwo:
            client?.meta?.location && client?.meta?.location[index]
              ? client?.meta?.location[index].addressLineTwo
              : '',
          city:
            client?.meta?.location && client?.meta?.location[index]
              ? client?.meta?.location[index].city
              : '',
          state:
            client?.meta?.location && client?.meta?.location[index]
              ? client?.meta?.location[index].state
              : '',
          zipCode:
            client?.meta?.location && client?.meta?.location[index]
              ? client?.meta?.location[index].zipCode
              : '',
          country:
            client?.meta?.location && client?.meta?.location[index]
              ? client?.meta?.location[index].country
              : '',
          timezone:
            client?.meta?.location && client?.meta?.location[index]
              ? client?.meta?.location[index].timezone
              : '',
          preferredCurrency:
            client?.meta?.location && client?.meta?.location[index]
              ? client?.meta?.location[index].preferredCurrency
              : '',
          isPrimary:
            client?.meta?.location && client?.meta?.location[index]
              ? client?.meta?.location[index].isPrimary
              : '',
        },
      };
      form.setFieldsValue(locationData);
    }
  }, [client]);

  const onFinish = (values: any) => {
    setIsSaved(true);

    const payload: Payload = { location: [] };
    if (client?.meta?.location) {
      payload.location = client.meta.location;
    }
    payload.location[index] = values.location as ClientLocation;

    API.query(`api/v2/client/${clientID}`, 'PUT', payload)
      .then((result: IDataResult) => {
        api.success({
          message: 'Success',
          description: 'The clients location data has been saved',
        });
        setClient(result.data);
        setIsLoading(false);
      })
      .catch((error: IErrorResult) => {
        api.error({
          message: 'Error',
          description: 'Could not save client location data',
        });
        console.error(error.message);
      });
  };

  const onFinishFailed = () => {
    message.error('Unable to save').then();
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <>
      {contextHolder}
      <Form
        autoComplete={'on'}
        autoCapitalize={'off'}
        form={form}
        layout={'vertical'}
        id={Forms.CreateLocationForm + index}
        name={Forms.CreateLocationForm + index}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={true}
        style={{ paddingBottom: 0 }}
        wrapperCol={{ span: 24 }}
      >
        <Spin spinning={isLoading}>
          <Card style={{ marginBottom: 20 }} key={index}>
            <Row gutter={15} justify={'space-between'}>
              <Col xxl={6} xl={6} lg={6} md={24} sm={24} xs={24}>
                <Form.Item
                  label={'Location Name'}
                  name={[fieldName, 'locationName']}
                  key={fieldKey}
                >
                  <Input placeholder={'Headquarters, New York Office, etc.'} />
                </Form.Item>
              </Col>
              <Col xxl={9} xl={9} lg={9} md={12} sm={12} xs={24}>
                <Form.Item
                  required
                  label={'Address Line 1'}
                  name={[fieldName, 'addressLineOne']}
                  key={fieldKey}
                  rules={[{ required: true }, { type: 'string', min: 2 }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col xxl={9} xl={9} lg={9} md={12} sm={12} xs={24}>
                <Form.Item
                  label={'Address Line 2'}
                  name={[fieldName, 'addressLineTwo']}
                  key={fieldKey}
                >
                  <Input placeholder={'Mailbox, Apt. Number'} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={15}>
              <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Item
                  label={'City'}
                  name={[fieldName, 'city']}
                  key={fieldKey}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Item
                  label={'State'}
                  name={[fieldName, 'state']}
                  key={fieldKey}
                >
                  <Select
                    allowClear
                    suffixIcon={
                      <ChevronDown
                        size={16}
                        className={'feather-icon feather-icon-select'}
                      />
                    }
                    defaultValue={undefined}
                    onChange={(value: string) => {
                      form.setFieldsValue({
                        state: value,
                      });
                    }}
                  >
                    {statesTitleCase.map((state, index) => {
                      return (
                        <Option key={index} value={state.abbreviation}>
                          {state.name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Item
                  label={'Zip Code'}
                  name={[fieldName, 'zipCode']}
                  key={fieldKey}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col xxl={6} xl={6} lg={6} md={12} sm={12} xs={12}>
                <Form.Item
                  label={'Country'}
                  name={[fieldName, 'country']}
                  key={fieldKey}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={15} align={'top'}>
              <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                <Form.Item
                  label={'Timezone'}
                  name={[fieldName, 'timezone']}
                  key={fieldKey}
                >
                  <Select
                    showSearch
                    allowClear
                    defaultValue={undefined}
                    clearIcon={
                      <X
                        size={16}
                        className={'feather-icon feather-icon-select'}
                      />
                    }
                    suffixIcon={
                      <ChevronDown
                        size={16}
                        className={'feather-icon feather-icon-select'}
                      />
                    }
                    onChange={(value: string) => {
                      form.setFieldsValue({
                        timezone: value,
                      });
                    }}
                    placeholder={'Select Timezone...'}
                    options={timezones.map((timezones: ITimezone) => {
                      return { value: timezones.value, label: timezones.text };
                    })}
                  />
                </Form.Item>
              </Col>

              <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                <Form.Item
                  label={'Preferred Currency'}
                  name={[fieldName, 'preferredCurrency']}
                  key={fieldKey}
                  style={{ marginBottom: 0 }}
                >
                  <Select
                    showSearch
                    allowClear
                    defaultValue={undefined}
                    suffixIcon={
                      <ChevronDown
                        size={16}
                        className={'feather-icon feather-icon-select'}
                      />
                    }
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return (
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        option?.children
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                    filterSort={(optionA, optionB) => {
                      return (
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        optionA.children
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          .toLowerCase()
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          .localeCompare(optionB.children.toLowerCase())
                      );
                    }}
                    onChange={(value: string) => {
                      form.setFieldsValue({
                        preferredCurrency: value,
                      });
                    }}
                    placeholder={'Select Currency...'}
                  >
                    {Object.entries(currencies).map(([code, name], index) => {
                      return (
                        <Option value={code} key={index}>
                          {code + ': ' + name}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row justify={'space-between'} style={{ marginTop: 15 }}>
              <Col xxl={6} xl={6} lg={12} md={12} sm={12} xs={18}>
                <Form.Item
                  label={''}
                  name={[fieldName, 'isPrimary']}
                  key={fieldKey}
                  valuePropName={'checked'}
                >
                  <Checkbox>Primary Location or HQ</Checkbox>
                </Form.Item>
              </Col>
              <Row justify={'end'} gutter={10}>
                {(!isSaved ||
                  (client?.meta?.location && client?.meta?.location[index])) &&
                  !form.getFieldValue(['location', 'isPrimary']) && (
                    <Col>
                      <Form.Item style={{ marginBottom: 0 }}>
                        <Button
                          form={Forms.CreateLocationForm + index}
                          type={'ghost'}
                          onClick={() => {
                            {
                              client?.meta?.location &&
                              client?.meta?.location[index]
                                ? onRemove(index)
                                : onReset();
                            }
                          }}
                        >
                          {client?.meta?.location &&
                          client?.meta?.location[index] &&
                          !form.getFieldValue(['location', 'isPrimary'])
                            ? 'Remove'
                            : 'Clear'}
                        </Button>
                      </Form.Item>
                    </Col>
                  )}
                <Col>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Button
                      form={Forms.CreateLocationForm + index}
                      type={'primary'}
                      key={'submit'}
                      htmlType={'submit'}
                    >
                      {client?.meta?.location && client?.meta?.location[index]
                        ? 'Update'
                        : 'Save'}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Row>
          </Card>
        </Spin>
      </Form>
    </>
  );
};

export default LocationCard;
