import { camelizeKeys } from '../helpers/Serialization/camelizeKeys';
import { ICredentials } from '../view/pages/Auth/LoginPage';
import { Options } from '@app/data/types';
import UserManager from '@app/helpers/Auth/UserManager';
import { IDataResult } from '@app/model/IDataResult';

export type APIAssembly = {
  endpoint: string;
  method: string;
  body?: any;
};

export const Methods = {
  GET: 'GET',
  HEAD: 'HEAD',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
  CONNECT: 'CONNECT',
  OPTIONS: 'OPTIONS',
  TRACE: 'TRACE',
};

interface RequestsInterface {
  [key: string]: AbortController;
}

export class API {
  public static baseURL =
    process.env.REACT_APP_NODE_ENV === 'production'
      ? process.env.REACT_APP_URL_PROD
      : process.env.REACT_APP_URL_DEV;

  public static requests: RequestsInterface = {};

  private userManager: UserManager;

  constructor(manager: UserManager) {
    this.userManager = manager;
  }

  /**
   * Calls a static GET method on the API class.
   *
   * @return {function} A static instance of a query with options filled in.
   * @param endpoint An endpoint or route found in the backend.
   */
  public get = async (endpoint: string): Promise<IDataResult> => {
    return this.query(endpoint, Methods.GET, null, true);
  };

  /**
   * Logs a user in based on their credentials.
   *
   * @return {function} A static instance of a query with options filled in.
   * @param credentials Username and password of user.
   */
  public login = async (credentials: ICredentials): Promise<IDataResult> => {
    const route = `//${API.baseURL}/api/v2/login`;
    const body = JSON.stringify(credentials);
    const options = {
      method: Methods.POST,
      headers: {
        'Content-Type': 'application/json',
      },
      body: body,
    };

    return this.promise(route, options);
  };

  /**
   * A standard JS fetch method with additional options and a returned Promise.
   *
   * @return {Promise} A promise query with resolution and rejection callbacks.
   * @param endpoint An endpoint or route found in the backend.
   * @param method The HTTP method such as GET, POST, or DELETE.
   * @param body An object or formData that will be sent via POST, PUT, etc.
   * @param requiresToken If the query call requires an auth token.
   */
  public query = async (
    endpoint: string,
    method: string,
    body?: any,
    requiresToken = true,
  ): Promise<IDataResult> => {
    const route = `//${API.baseURL}/${endpoint}`;
    const options: Options = {
      method: method,
      headers: {},
      body: null,
    };

    if (requiresToken && this.userManager.hasUserToken()) {
      options.headers[
        'Authorization'
      ] = `Bearer ${this.userManager.getSessionToken()}`;
    }

    if (!route || !options) {
      console.error('API call cannot be made.');
      throw Error('API Call cannot be made.');
    }

    if (['POST', 'PUT', 'PATCH'].includes(method)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      options.headers['Content-Type'] = 'application/json';
    }

    if (body) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      options.body = JSON.stringify(body);
    }

    return this.promise(route, options);
  };

  /**
   * A fetch function with resolution and rejection callbacks.
   *
   * @return {Promise} A promise query with resolution and rejection callbacks.
   * @param route An endpoint or route found in the backend.
   * @param options An object or formData that will be sent via POST, PUT, etc.
   */
  private promise = async (
    route: string,
    options: any,
  ): Promise<IDataResult> => {
    if (route.includes('undefined')) {
      throw Error(`(Route parameter is undefined)`);
    }

    try {
      const response = await fetch(route, options);

      if (response.status === 204) {
        return {} as IDataResult;
      }

      if (response.status >= 400) {
        let errorMessage;
        try {
          errorMessage = await response.json();

          if (typeof errorMessage.message === 'object') {
            errorMessage = Object.values(errorMessage.message)[0];
          }
        } catch (e) {
          throw Error(`(Server response: Unauthenticated)`);
        }

        throw Error(errorMessage);
      }

      const result = await response.json();
      if (typeof result.error !== 'undefined') {
        throw Error(result.error);
      }

      return camelizeKeys(result) as IDataResult;
    } catch (e) {
      throw Error((e as Error).message);
    }
  };
}
