import React from 'react';
import { Select } from 'antd';
import { ChevronDown } from 'react-feather';

type CustomSelectProps = {
  children: React.ReactNode;
  allowClear?: boolean;
  placeholder: string;
  defaultValue?: string;
  onChange: (value: string) => void;
  onClear?: () => void;
};

export const CustomSelect = ({
  children,
  allowClear = true,
  placeholder,
  onChange,
  onClear,
  defaultValue,
  ...props
}: CustomSelectProps) => {
  return (
    <Select
      {...props}
      allowClear={allowClear}
      showSearch={true}
      optionFilterProp={'children'}
      onChange={onChange}
      onClear={onClear && onClear}
      placeholder={placeholder}
      style={{ width: '100%' }}
      defaultValue={defaultValue || undefined}
      suffixIcon={
        <ChevronDown size={16} className={'feather-icon feather-icon-select'} />
      }
    >
      {children}
    </Select>
  );
};

export default CustomSelect;
