import React, { PropsWithChildren, useContext } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  NavLink,
  RouteComponentProps,
} from 'react-router-dom';
import { Button, Layout, Menu } from 'antd';
import {
  Activity,
  Briefcase,
  Folder,
  Info,
  LifeBuoy,
  Lock,
  Send,
  User,
} from 'react-feather';
import './style.scss';
import DashboardPage from '@app/view/pages/Dashboard/DashboardPage';
import AccountPage from '@app/view/pages/Account/AccountPage';
import InvoicePage from '@app/view/pages/Invoice/InvoicePage';
import ProjectsPage from '@app/view/pages/Project/ProjectsPage';
import ProjectPage from '@app/view/pages/Project/ProjectView/ProjectPage';
import LoginPage from '@app/view/pages/Auth/LoginPage';
import SelectAccountPage from '@app/view/pages/Account/SelectAccountPage';
import TermsPage from '@app/view/pages/Terms/TermsPage';
import ClientsPage from '@app/view/pages/Client/ClientsPage';
import SupportPage from '@app/view/pages/Support/SupportPage';
import NewProjectPage from '@app/view/pages/Project/NewProject/NewProjectPage';
import ForgotPassword from '@app/view/pages/Auth/ForgotPasswordPage';
import Registration from '@app/view/pages/Auth/RegistrationPage';
import NewClientPage from '@app/view/pages/Client/NewClient/NewClientPage';
import PrivacyPage from '@app/view/pages/Terms/PrivacyPage';
import ClientDetailPage from '@app/view/pages/Client/ClientDetailPage';
import { Row, Col } from 'antd';
import { UserContext } from '@app/contexts/UserContext';
import NotFoundPage from '@app/view/pages/Auth/NotFoundPage';
import { useTheme } from '@app/styles/theme';
import Reports from '@app/view/pages/Reports';
import PasswordResetPage from '@app/view/pages/Auth/PasswordResetPage';
const { Header, Content, Sider } = Layout;

function MainNavigation() {
  const { logoutUser, darkMode, user } = useContext(UserContext);
  const theme = useTheme();

  const renderAuthRoutes = () => (
    <Switch>
      <Route
        strict
        exact
        path="/login"
        component={(
          props:
            | PropsWithChildren<RouteComponentProps<any>>
            | PropsWithChildren<any>,
        ) => <LoginPage {...props} />}
      />
      <Route
        strict
        exact
        path="/select-account"
        component={(
          props:
            | PropsWithChildren<RouteComponentProps<any>>
            | PropsWithChildren<any>,
        ) => <SelectAccountPage {...props} />}
      />
      <Route
        strict
        exact
        path="/forgot-password"
        component={(
          props:
            | PropsWithChildren<RouteComponentProps<any>>
            | PropsWithChildren<any>,
        ) => <ForgotPassword {...props} />}
      />
      <Route
        strict
        exact
        path="/registration"
        component={(
          props:
            | PropsWithChildren<RouteComponentProps<any>>
            | PropsWithChildren<any>,
        ) => <Registration {...props} />}
      />
      <Route
        strict
        exact
        path="/password-reset"
        component={(
          props:
            | PropsWithChildren<RouteComponentProps<any>>
            | PropsWithChildren<any>,
        ) => <PasswordResetPage {...props} />}
      />
      <Route
        path="/*"
        component={(
          props:
            | PropsWithChildren<RouteComponentProps<any>>
            | PropsWithChildren<any>,
        ) => <LoginPage {...props} />}
      />
    </Switch>
  );

  const renderHeader = () => (
    <Header
      className="header"
      style={{
        position: 'fixed',
        zIndex: 1000,
        width: '100%',
      }}
    >
      <Row justify={'space-between'}>
        <Col
          xxl={18}
          xl={18}
          lg={18}
          md={12}
          sm={12}
          xs={12}
          className={'header-col-start'}
        >
          <NavLink exact to="/" style={{ display: 'inherit' }}>
            <img
              alt="Logo"
              className={'logo'}
              src="/assets/images/logo-2.svg"
            />
          </NavLink>
        </Col>

        <Col
          xxl={6}
          xl={6}
          lg={6}
          md={12}
          sm={12}
          xs={12}
          className={'header-col-end'}
        >
          <Button
            onClick={() => {
              logoutUser();
            }}
            type={'ghost'}
          >
            Log Out
          </Button>
        </Col>
      </Row>
    </Header>
  );

  const renderSidebar = () => (
    <Sider width={160} breakpoint={'md'} collapsedWidth={'0'}>
      <Menu
        className={'sider-menu'}
        mode={'vertical'}
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
      >
        <>
          <NavLink
            key="1"
            exact
            to="/"
            activeClassName={'menu-link-active'}
            className={'menu-link'}
          >
            <Activity className={'menu-link-icon'} size={13} />
            Dashboard
          </NavLink>
          <NavLink
            key="2"
            to="/projects"
            activeClassName={'menu-link-active'}
            className={'menu-link'}
          >
            <Folder className={'menu-link-icon'} size={13} />
            Projects
          </NavLink>
          <NavLink
            key="3"
            to="/clients"
            activeClassName={'menu-link-active'}
            className={'menu-link'}
          >
            <Briefcase className={'menu-link-icon'} size={13} />
            Clients
          </NavLink>
          {user.isAuthorized('invoice') && (
            <NavLink
              key="4"
              to="/invoices"
              activeClassName={'menu-link-active'}
              className={'menu-link'}
            >
              <Send className={'menu-link-icon'} size={13} />
              Invoices
            </NavLink>
          )}
          {user.isAuthorized('reports') && (
            <NavLink
              key="5"
              to="/reports"
              activeClassName={'menu-link-active'}
              className={'menu-link'}
            >
              <Send className={'menu-link-icon'} size={13} />
              Reports
            </NavLink>
          )}
          <NavLink
            key="6"
            to="/account"
            activeClassName={'menu-link-active'}
            className={'menu-link'}
          >
            <User className={'menu-link-icon'} size={13} />
            Account
          </NavLink>
          <NavLink
            key="7"
            to="/support"
            activeClassName={'menu-link-active'}
            className={'menu-link'}
          >
            <LifeBuoy className={'menu-link-icon'} size={13} />
            Support
          </NavLink>

          <div className={'sider-footer-row'}>
            <NavLink
              key="8"
              to="/terms"
              activeClassName={'menu-link-active'}
              className={'menu-link'}
            >
              <Info className={'menu-link-icon'} size={13} />
              Terms
            </NavLink>
            <NavLink
              key="9"
              to="/privacy"
              activeClassName={'menu-link-active'}
              className={'menu-link'}
            >
              <Lock className={'menu-link-icon'} size={13} />
              Privacy
            </NavLink>
          </div>
        </>
      </Menu>
    </Sider>
  );

  const renderMainRoutes = () => (
    <>
      {renderHeader()}

      <UserContext.Consumer>
        {({ didSelectAccount }) => (didSelectAccount ? renderSidebar() : <></>)}
      </UserContext.Consumer>

      <Layout className={'layout'}>
        <Content className={'primary-content'}>
          <Switch>
            <Route
              strict
              exact
              path="/"
              component={(
                props:
                  | PropsWithChildren<RouteComponentProps<any>>
                  | PropsWithChildren<any>,
              ) => <DashboardPage {...props} />}
            />
            <Route
              strict
              exact
              path="/account"
              component={(
                props:
                  | PropsWithChildren<RouteComponentProps<any>>
                  | PropsWithChildren<any>,
              ) => <AccountPage {...props} />}
            />
            <Route
              strict
              exact
              path="/support"
              component={(
                props:
                  | PropsWithChildren<RouteComponentProps<any>>
                  | PropsWithChildren<any>,
              ) => <SupportPage {...props} />}
            />
            {user.isAuthorized('invoice') && (
              <Route
                strict
                exact
                path="/invoices"
                component={(
                  props:
                    | PropsWithChildren<RouteComponentProps<any>>
                    | PropsWithChildren<any>,
                ) => <InvoicePage {...props} />}
              />
            )}
            {user.isAuthorized('reports') && (
              <Route
                strict
                exact
                path={'/reports'}
                component={(
                  props:
                    | PropsWithChildren<RouteComponentProps<any>>
                    | PropsWithChildren<any>,
                ) => <Reports {...props} />}
              />
            )}
            <Route
              strict
              exact
              path="/projects"
              component={(
                props:
                  | PropsWithChildren<RouteComponentProps<any>>
                  | PropsWithChildren<any>,
              ) => <ProjectsPage {...props} />}
            />
            <Route
              strict
              exact
              path="/projects/new"
              component={(
                props:
                  | PropsWithChildren<RouteComponentProps<any>>
                  | PropsWithChildren<any>,
              ) => <NewProjectPage {...props} />}
            />
            <Route
              strict
              exact
              path="/projects/:id"
              component={(
                props:
                  | PropsWithChildren<RouteComponentProps<any>>
                  | PropsWithChildren<any>,
              ) => <ProjectPage {...props} />}
            />
            <Route
              strict
              exact
              path="/clients/new"
              component={(
                props:
                  | PropsWithChildren<RouteComponentProps<any>>
                  | PropsWithChildren<any>,
              ) => <NewClientPage {...props} />}
            />
            <Route
              strict
              exact
              path="/clients/:id"
              component={(
                props:
                  | PropsWithChildren<RouteComponentProps<any>>
                  | PropsWithChildren<any>,
              ) => <NewClientPage {...props} />}
            />
            <Route
              strict
              exact
              path="/clients/:id"
              component={(
                props:
                  | PropsWithChildren<RouteComponentProps<any>>
                  | PropsWithChildren<any>,
              ) => <ClientDetailPage {...props} />}
            />
            <Route
              strict
              exact
              path="/clients"
              component={(
                props:
                  | PropsWithChildren<RouteComponentProps<any>>
                  | PropsWithChildren<any>,
              ) => <ClientsPage {...props} />}
            />
            <Route
              strict
              exact
              path="/terms"
              component={(
                props:
                  | PropsWithChildren<RouteComponentProps<any>>
                  | PropsWithChildren<any>,
              ) => <TermsPage {...props} />}
            />
            <Route
              strict
              exact
              path="/privacy"
              component={(
                props:
                  | PropsWithChildren<RouteComponentProps<any>>
                  | PropsWithChildren<any>,
              ) => <PrivacyPage {...props} />}
            />
            <Route
              strict
              path="/*"
              component={(
                props:
                  | PropsWithChildren<RouteComponentProps<any>>
                  | PropsWithChildren<any>,
              ) => <NotFoundPage {...props} />}
            />
          </Switch>
        </Content>
      </Layout>
    </>
  );

  return (
    <Router>
      <Layout
        style={{
          backgroundColor: darkMode
            ? theme.dark.backgroundColor
            : theme.light.backgroundColor,
        }}
      >
        <UserContext.Consumer>
          {({ didSelectAccount }) =>
            didSelectAccount ? renderMainRoutes() : renderAuthRoutes()
          }
        </UserContext.Consumer>
      </Layout>
    </Router>
  );
}

export default MainNavigation;
