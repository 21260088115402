import { Expose, Type } from 'class-transformer';

export class Contactable {
  @Expose() id: number | undefined;
  @Expose() contactableId?: number | null | undefined;
  @Expose() locationId?: number | null | undefined;

  @Expose() contactableType: string | undefined;
  @Expose() type?: string | null | undefined;
  @Expose() department?: string | null | undefined;
  @Expose() firstName?: string | null | undefined;
  @Expose() lastName?: string | null | undefined;
  @Expose() title?: string | null | undefined;
  @Expose() phoneHome?: string | null | undefined;
  @Expose() phoneOffice?: string | null | undefined;
  @Expose() phoneMobile?: string | null | undefined;
  @Expose() email?: string | null | undefined;
  @Expose() meta?: string | null | undefined;

  @Type(() => Date)
  createdAt?: Date | undefined;
  @Type(() => Date)
  updatedAt?: Date | undefined;
}
