import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Row, Select, message } from 'antd';
import { Link } from 'react-router-dom';
import { ChevronDown, Plus, X } from 'react-feather';
import Wrapper from '@app/view/components/Views/Wrapper';
import { APIAssembly, Methods } from '@app/data/API';
import { IDataResult } from '@app/model/IDataResult';
import { plainToClass } from 'class-transformer';
import { UserContext } from '@app/contexts/UserContext';
import { Project } from '@app/model/Project';
import { Client } from '@app/model/Client';
import { removeDuplicates } from '@app/helpers/Data/removeDuplicates';
import { IErrorResult } from '@app/model/IErrorResult';
import ProjectTable from '@app/view/components/Tables/ProjectTable';
const { Option, OptGroup } = Select;

function ProjectsPage() {
  const [projects, setProjects] = useState<Project[] | undefined>([]);
  const [filteredProjects, setFilteredProjects] = useState<
    Project[] | undefined
  >(undefined);
  const [showArchive, setShowArchive] = useState<boolean>(false);
  const [clients, setClients] = useState<Client[]>([]);
  const { user, api: API } = useContext(UserContext);

  const userData = user.getUser();

  useEffect(() => {
    getProjects();
  }, []);

  /**
   * Get all Projects from the Account and extract Clients for filtering.
   */
  const getProjects = () => {
    API.get(`api/v2/account/${userData.account?.id}/projects`)
      .then((result: IDataResult) => {
        const _projects: any = plainToClass(Project, result.data);

        let _clients: Client[] = [];
        _projects.map((project: Project) => {
          project.client && _clients.push(project.client);
        });
        _clients = removeDuplicates(_clients);

        setProjects(_projects);
        setClients(_clients);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  /**
   * Archive a Project and remove it from view.
   */
  const archiveProject = (project: Project) => {
    const assembly: APIAssembly = {
      endpoint: `api/v2/project/${project?.id}/archive`,
      method: Methods.PUT,
    };

    API.query(assembly.endpoint, assembly.method, assembly.body)
      .then(() => {
        const _projects = projects?.filter((projects) => projects !== project);
        setProjects(_projects);
        message.success(`${project.name} was archived successfully`).then();
      })
      .catch((error: IErrorResult) => {
        console.error(error);
      });
  };

  /**
   * OnSelect of Option, begin filtering down the table results by Client.
   *
   * @param value The Option's value – the client's index
   */
  const onSelectClient = (value: any) => {
    const client = clients[value];

    const _filteredProjects = projects?.filter(
      (projects) => projects.client?.id === client.id,
    );
    setFilteredProjects(_filteredProjects);
  };

  const primaryButton = () => (
    <Link to="/projects/new">
      <Button
        type={'primary'}
        icon={
          <Plus
            size={14}
            className={'feather-icon feather-icon-button-prefix'}
          />
        }
      >
        New Project
      </Button>
    </Link>
  );

  return (
    <Wrapper primaryButton={primaryButton()} title={'Projects'}>
      <Row gutter={15} justify={'space-between'} style={{ marginBottom: 15 }}>
        <Col span={6}>
          <Select
            allowClear
            showSearch
            style={{ minWidth: 200 }}
            optionFilterProp={`children`}
            placeholder={'Filter by client...'}
            onSelect={(value: any) => {
              onSelectClient(value);
            }}
            filterOption={(input, option) =>
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onClear={() => {
              setFilteredProjects(undefined);
            }}
            clearIcon={
              <X size={16} className={'feather-icon feather-icon-select'} />
            }
            suffixIcon={
              <ChevronDown
                size={16}
                className={'feather-icon feather-icon-select'}
              />
            }
          >
            <OptGroup label={`Clients`}>
              {clients.map((client: Client, index: number) => {
                return (
                  <Option value={index} key={index}>
                    {client.name}
                  </Option>
                );
              })}
            </OptGroup>
          </Select>
        </Col>

        <Col span={6} className={'flex-end'}>
          <Button
            type={'ghost'}
            onClick={() => {
              setShowArchive(!showArchive);
            }}
          >
            {showArchive ? `View Active` : `View Archive`}
          </Button>
        </Col>
      </Row>

      <Row gutter={15}>
        <Col span={24}>
          <ProjectTable
            filteredProjects={filteredProjects}
            projects={projects}
            onArchive={(project: Project) => {
              archiveProject(project);
            }}
          />
        </Col>
      </Row>
    </Wrapper>
  );
}

export default ProjectsPage;
