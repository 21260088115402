import React from 'react';

type MailToProps = {
  email?: string | null | undefined;
  subject?: string | null | undefined;
  body?: string | null | undefined;
  children: React.ReactNode;
};

export const MailTo = ({
  email,
  subject,
  body,
  children,
  ...props
}: MailToProps) => {
  let params = subject || body ? '?' : '';
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

  return <a href={`mailto:${email}${params}`}>{children}</a>;
};

export default MailTo;
