import { Card, Typography } from 'antd';
import React from 'react';
import { Tracking } from '@app/model/Tracking';
import { DaysType } from '@app/view/pages/Dashboard/DashboardPage';
import './style.scss';
import IndividualTrackingCard from '@app/view/components/Cards/IndividualTrackingCard';

const { Text } = Typography;

type TrackingProps = {
  tracking: Tracking[];
  day: DaysType;
  refresh: (update: boolean) => void;
  setTracker: (tracker: Tracking) => void;
  editVisible: () => void;
};
export default function TrackingCard({
  tracking,
  day,
  refresh,
  setTracker,
  editVisible,
}: TrackingProps) {
  /**
   * Filters the tracking for the correct day
   *
   * @param date
   */
  const displayTracking = (date: string): Tracking[] => {
    return tracking.filter((e) => {
      return e.trackedOn === date;
    });
  };

  return (
    <>
      {displayTracking(day.dayFormat).map((track, index) => {
        return (
          <IndividualTrackingCard
            track={track}
            refresh={refresh}
            editVisible={editVisible}
            setTracker={setTracker}
            key={`tracker-${index}`}
          />
        );
      })}

      {!displayTracking(day.dayFormat).length && (
        <Card style={{ textAlign: 'center' }}>
          <Text>No time data available for this day.</Text>
        </Card>
      )}
    </>
  );
}
