import React from 'react';
import { Button, Modal } from 'antd';
import './style.scss';
import { X } from 'react-feather';
import { useTheme } from '@app/styles/theme';

interface CustomModalProps extends React.HTMLAttributes<HTMLElement> {
  children: React.ReactNode;
  hasTopPadding?: boolean;
  customOKButton?: React.ReactNode | null;
  customCancelButton?: React.ReactNode | null;
  onOk?: () => void;
  okText?: string;
  okButtonProps?: any;
  onCancel?: () => void;
  hasOkButton?: boolean | null;
  hasCancelButton?: boolean | null;
  visible: boolean;
}

const CustomModal: React.FC<CustomModalProps> = ({
  children,
  hasTopPadding = false,
  customOKButton,
  customCancelButton,
  onOk,
  okText,
  okButtonProps,
  onCancel,
  hasOkButton = true,
  hasCancelButton = true,
  visible,
  ...restProps
}) => {
  const theme = useTheme();
  const className = hasTopPadding ? 'modal modal-pt-0' : 'modal';

  const cancelButton = () => (
    <Button onClick={onCancel} type={'ghost'}>
      Cancel
    </Button>
  );

  const okButton = () => (
    <Button onClick={onOk} type={'ghost'}>
      Cancel
    </Button>
  );

  return (
    <Modal
      {...restProps}
      centered
      className={className}
      bodyStyle={{
        height: '400px',
        overflow: 'scroll',
        color: 'white',
      }}
      destroyOnClose={true}
      onOk={() => {
        onOk && onOk();
      }}
      okText={okText ? okText : 'Save'}
      onCancel={() => {
        onCancel && onCancel();
      }}
      okButtonProps={
        okButtonProps
          ? okButtonProps
          : { style: { display: hasOkButton ? 'inherit' : 'none' } }
      }
      cancelButtonProps={{
        style: { display: hasCancelButton ? 'inherit' : 'none' },
      }}
      footer={[
        customCancelButton ? customCancelButton : cancelButton(),
        customOKButton ? customOKButton : okButton(),
      ]}
      transitionName={''}
      open={visible}
      width={800}
      closeIcon={
        <div
          onClick={() => {
            onCancel && onCancel();
          }}
        >
          <X
            className={'feather-icon'}
            color={theme.light.subtext}
            size={20}
            style={{ top: 5, zIndex: 99999 }}
          />
        </div>
      }
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
