import React, { useContext } from 'react';
import { Menu, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { UserContext } from '@app/contexts/UserContext';
import { Account } from '@app/model/Account';

type AccountItemProps = {
  account?: Account;
};

export const AccountItem = ({ account }: AccountItemProps) => {
  const { selectAccount, user } = useContext(UserContext);

  const onClick = (account: any) => {
    user.setCookie(user.getAccountKey(), account.id);
    selectAccount(account);
  };

  if (!account) {
    return null;
  }

  return (
    <Link to={'/'}>
      <div className="account-item" onClick={() => onClick(account)}>
        <Menu.Item style={{ margin: '.5rem' }} key="0">
          <Avatar
            style={{ marginRight: '.5rem' }}
            size="small"
            icon={<UserOutlined />}
          />
          {account?.name}
        </Menu.Item>
      </div>
    </Link>
  );
};

export default AccountItem;
