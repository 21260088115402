import React, { useContext, useEffect, useState } from 'react';
import {
  Col,
  Row,
  Typography,
  Tabs,
  Divider,
  Button,
  notification,
} from 'antd';
import Wrapper from '@app/view/components/Views/Wrapper';
import { Plus } from 'react-feather';
import NewEntryModal from '@app/view/pages/Dashboard/NewEntryModal';
import { UserContext } from '@app/contexts/UserContext';
import { IDataResult } from '@app/model/IDataResult';
import {
  startOfWeek,
  format,
  addDays,
  getWeek,
  endOfWeek,
  subWeeks,
  addWeeks,
} from 'date-fns';
import { plainToClass } from 'class-transformer';
import { Tracking } from '@app/model/Tracking';
import TrackingCard from '@app/view/components/Cards/TrackingCard';
import { Tab } from 'rc-tabs/lib/interface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { getTodaysDate } from '@app/helpers/Date/getDateObject';
import EditEntryModal from '@app/view/pages/Dashboard/EditEntryModal';
import './style.scss';

const { Title, Text } = Typography;

export type DaysType = {
  dayNumber: number;
  dayFormat: string;
  shortDate: string;
  hours: number;
  timezoned: string;
};

type MainDate = {
  index: number;
  days: DaysType[];
};
const MainDate = ({ index, days }: MainDate) => {
  const day = days[index] || { dayFormat: format(new Date(), 'yyyy-MM-dd') };
  const dateArray = day.dayFormat.match(/([\d]{4})-([\d]{2})-([\d]{2})/);

  if (!dateArray || typeof dateArray[3] === 'undefined') return <></>;

  return (
    <Title level={3} className={'dashboard-text-color'}>
      {format(
        new Date(
          Number(dateArray[1]),
          Number(dateArray[2]) - 1,
          Number(dateArray[3]),
        ),
        'eeee',
      )}{' '}
      <span style={{ fontWeight: 400 }}>
        {format(
          new Date(
            Number(dateArray[1]),
            Number(dateArray[2]) - 1,
            Number(dateArray[3]),
          ),
          'MMMM',
        )}{' '}
        {format(
          new Date(
            Number(dateArray[1]),
            Number(dateArray[2]) - 1,
            Number(dateArray[3]),
          ),
          'd',
        )}
        ,{' '}
        {format(
          new Date(
            Number(dateArray[1]),
            Number(dateArray[2]) - 1,
            Number(dateArray[3]),
          ),
          'yyyy',
        )}
      </span>
    </Title>
  );
};

export const DashboardPage = ({ ...props }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const [editVisible, setEditVisible] = useState<boolean>(false);
  const [editTracker, setEditTracker] = useState<Tracking>();

  const [tracking, setTracking] = useState<Tracking[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState(0);
  const [hoursForWeek, setHoursForWeek] = useState<string>('0.00');
  const [date, setDate] = useState<Date>(new Date());
  const [days, setDays] = useState<DaysType[]>([]);
  const [currentWeek, setCurrentWeek] = useState<number>(0);
  const [viewingWeek, setViewingWeek] = useState<number>(0);
  const [api, contextHolder] = notification.useNotification();

  const { user, api: API } = useContext(UserContext);

  const userData = user.getUser();

  // Mount callback
  useEffect(() => {
    // The current date
    setDate(getTodaysDate(userData));
    setCurrentWeek(getWeek(getTodaysDate(userData)));
    setViewingWeek(getWeek(getTodaysDate(userData)));

    // Get the start of the week.
    const startDate = format(
      startOfWeek(getTodaysDate(userData)),
      'yyyy-MM-dd',
    );

    // Get all data for this current week
    getWeeksData(startDate);

    // Current day to show
    setSelectedDay(getTodaysDate(userData).getDay());
  }, []);

  // Refresh the view with the weekly data
  useEffect(() => {
    if (refresh) {
      const startDate = format(
        startOfWeek(
          new Date(date.getFullYear(), date.getMonth(), date.getDate()),
        ),
        'yyyy-MM-dd',
      );

      getWeeksData(startDate);
      setRefresh(false);
    }
  }, [refresh]);

  const getWeeksData = (startDate: string) => {
    API.get(`api/v2/tracking/week?date=${startDate}`).then(
      (result: IDataResult) => {
        const tracking: Tracking[] | any = plainToClass(Tracking, result.data);
        setTracking(tracking);
        setDays(buildDays(startDate, tracking));
      },
    );
  };

  /**
   * Calculates the hours for the week
   */
  useEffect((): void => {
    const sum = days
      .map((e) => {
        return e.hours;
      })
      .reduce((a, b) => a + b, 0);
    const weekHours = String(Math.round(sum * 100 + Number.EPSILON) / 100);
    setHoursForWeek(weekHours === '0' ? '0.00' : weekHours);
  }, [days]);

  /**
   * Build the data to display the day tab
   *
   * @param startWeek
   * @param time
   */
  const buildDays = (startWeek: string, time: Tracking[]): DaysType[] => {
    const buildDays = [];
    const dateArray = startWeek.match(/([\d]{4})-([\d]{2})-([\d]{2})/);

    if (!dateArray || typeof dateArray[3] === 'undefined') return [];

    // Loop seven days to organize data for the displayed week.
    for (let i = 0; i < 7; i++) {
      // Get the date of the week
      const currentDay = addDays(
        new Date(
          Number(dateArray[1]),
          Number(dateArray[2]) - 1,
          Number(dateArray[3]),
        ),
        i,
      );

      // Format the current date for further filtering
      const dayFormat = format(currentDay, 'yyyy-MM-dd');

      // Calculate the hours for this day
      const sum = time
        .filter((e) => {
          return e.trackedOn === dayFormat;
        })
        .map((e) => {
          return Number(e.hours);
        })
        .reduce((a, b) => a + b, 0);

      buildDays.push({
        dayNumber: i,
        dayFormat: dayFormat,
        shortDate: format(currentDay, 'E'),
        timezoned: format(currentDay, 'yyyy-MM-dd ppp'),
        hours: Math.round(sum * 100 + Number.EPSILON) / 100,
      });
    }

    return buildDays;
  };

  /**
   * Updates the task data and the selected day on tab changes
   *
   * @param key
   */
  const tabChange = (key: string): void => {
    // Get the current day number
    const updatedKey = key.match(/([\d]{1})/);

    // Make sure it found the key
    if (!updatedKey || typeof updatedKey[1] === 'undefined') return;

    // Update the selected day
    setSelectedDay(Number(updatedKey[1]));
    setDate(getTodaysDate(userData, days[Number(updatedKey[1])].timezoned));
  };

  /**
   * Handles updating the viewed week
   *
   * @param direction
   */
  const weekChange = (direction: 'forward' | 'backward' | 'current'): void => {
    let newDate;
    if (direction === 'backward') {
      newDate = subWeeks(date, 1);
    } else if (direction === 'current') {
      newDate = getTodaysDate(userData);
    } else {
      newDate = addWeeks(date, 1);
    }

    const startWeek = format(startOfWeek(newDate), 'yyyy-MM-dd');
    getWeeksData(startWeek);
    setDate(newDate);
    setViewingWeek(getWeek(newDate));
  };

  /**
   * Builds the data that should be displayed in each tab
   *
   * returns Tab[]
   */
  const buildTabItems = (): Tab[] => {
    const data: Tab[] = [];

    days.map((e, i) => {
      data.push({
        key: `tabList-${i}`,
        label: tabTitle(e),
        children: (
          <TrackingCard
            tracking={tracking}
            day={e}
            refresh={setRefresh}
            setTracker={setEditTracker}
            editVisible={() => setEditVisible(true)}
          />
        ),
      });
    });

    return data;
  };

  /**
   * Build the tabs for the days.
   *
   * @param day
   */
  const tabTitle = (day: DaysType) => {
    return (
      <Col className={'flex-column px-0'} style={{ minWidth: 50 }}>
        <Text className={'font-bold dashboard-text-color'}>
          {day.shortDate}
        </Text>
        <Text className={'font-small dashboard-text-color'}>
          {day.hours === 0 ? '0.00' : day.hours}
        </Text>
      </Col>
    );
  };

  return (
    <>
      {contextHolder}
      <Wrapper title={'Dashboard'}>
        <Row align={'middle'} style={{ marginBottom: '10px' }}>
          <Col>
            <Button
              onClick={() => weekChange('backward')}
              style={{ marginRight: '5px' }}
              className={'week-btn'}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
            {viewingWeek != currentWeek && (
              <Button
                onClick={() => weekChange('forward')}
                className={'week-btn'}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            )}
          </Col>
          <Col style={{ marginLeft: '10px', marginRight: '10px' }}>
            <Text className={'dashboard-text-color'}>
              {format(startOfWeek(date), 'MMMM do, yyyy')}
              {' - '}
              {format(endOfWeek(date), 'MMMM do, yyyy')}
            </Text>
            {viewingWeek != currentWeek && (
              <Button
                className={'week-btn'}
                style={{ marginLeft: '20px' }}
                onClick={() => weekChange('current')}
              >
                View Current Week
              </Button>
            )}
          </Col>
        </Row>
        <Row justify={'space-between'} align={'middle'}>
          <Col>
            <MainDate index={selectedDay} days={days} />
          </Col>
          <Col>
            <Button
              onClick={() => {
                setVisible(true);
              }}
              type={'primary'}
              icon={
                <Plus
                  size={14}
                  className={'feather-icon feather-icon-button-prefix'}
                />
              }
            >
              New Entry
            </Button>
          </Col>
        </Row>

        <Tabs
          tabPosition={'top'}
          tabBarStyle={{ color: 'white' }}
          onChange={tabChange}
          activeKey={'tabList-' + selectedDay}
          items={buildTabItems()}
        />

        <Divider />

        <Row align={'top'} justify={'space-between'}>
          <Col className={'flex-column'}>
            <Title level={4} className={'dashboard-text-color'}>
              Weeks Total
            </Title>
          </Col>
          <Col>
            <Title
              level={4}
              style={{ marginBottom: 0 }}
              className={'dashboard-text-color'}
            >
              {hoursForWeek}
              <Text
                className={'font-small dashboard-text-color'}
                style={{ marginLeft: 5 }}
              >
                Hours
              </Text>
            </Title>
          </Col>
        </Row>

        <NewEntryModal
          onCancel={() => {
            setVisible(false);
          }}
          date={date}
          visible={visible}
          refresh={setRefresh}
          api={api}
        />

        <EditEntryModal
          visible={editVisible}
          tracker={editTracker}
          onCancel={() => {
            setEditVisible(false);
          }}
          refresh={setRefresh}
        />
      </Wrapper>
    </>
  );
};

export default DashboardPage;
