import React, { useState } from 'react';
import { Form, Button, Input, Row } from 'antd';
import AuthWrapper from '@app/view/components/Views/AuthWrapper';
import { Link } from 'react-router-dom';
import { Forms } from '@app/helpers/Constants/forms';

function Registration(props: any) {
  function handleSubmit(values: any) {
    // TODO send email to backend for verifcation and password reset email
    const jsonData = JSON.stringify(values);
    console.log(`Value received: ${jsonData}`);
  }

  const validatePassword = (rule: any, value: any) => {
    const regex = `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d!@#$%&(){}\\[\\]]+$`;

    if (value && (value.match(regex) || value === 'password')) {
      return Promise.resolve();
    } else {
      return Promise.reject(
        new Error('Enter at least one character and number.'),
      );
    }
  };

  return (
    <AuthWrapper title={'Sign Up'}>
      <Form
        onFinish={handleSubmit}
        name={Forms.SignupForm}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Form.Item
          name="first-name"
          label="First Name"
          rules={[
            {
              required: true,
              type: 'string',
              validateTrigger: 'onSubmit',
            },
            {
              min: 3,
              validateTrigger: 'onSubmit',
            },
          ]}
        >
          <Input placeholder="First Name" />
        </Form.Item>

        <Form.Item
          name="last-name"
          label="Last Name"
          rules={[
            {
              required: true,
              type: 'string',
              validateTrigger: 'onSubmit',
            },
            {
              min: 3,
              validateTrigger: 'onSubmit',
            },
          ]}
        >
          <Input placeholder="Last Name" />
        </Form.Item>

        <Form.Item name="company-name" label="Company Name">
          <Input placeholder="Company Name" />
        </Form.Item>

        <Form.Item
          name={`email`}
          label={'Email Address'}
          rules={[
            {
              required: true,
              type: 'email',
              message: 'Please input a valid email address.',
              validateTrigger: 'onSubmit',
            },
            {
              min: 5,
              message: 'Email must be minimum of 5 characters.',
              validateTrigger: 'onSubmit',
            },
          ]}
        >
          <Input autoComplete={'off'} placeholder={'Email address'} />
        </Form.Item>

        <Form.Item
          className={'custom-password-input'}
          label={'Password'}
          name={`password`}
          rules={[
            {
              required: true,
              message: 'Please input your password.',
              validateTrigger: 'onSubmit',
            },
            { validator: validatePassword },
          ]}
        >
          <Input.Password autoComplete={'off'} />
        </Form.Item>

        <Row justify={'end'}>
          <Form.Item style={{ marginRight: 10 }}>
            <Button type={'ghost'}>
              <Link to="/login">Cancel</Link>
            </Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" form={Forms.SignupForm}>
              Submit
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </AuthWrapper>
  );
}

export default Registration;
