import { Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { beforeUpload } from '@app/helpers/Images/beforeUpload';
import { getBase64 } from '@app/helpers/Images/getBase64';

type ClientAvatarProps = {
  onUpload: (URL: string) => void;
};

export const ClientAvatar = ({ onUpload }: ClientAvatarProps) => {
  const [imageURL, setImageURL] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setIsLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, (URL: any) => {
        setImageURL(URL);
        onUpload(URL);
      });
    }
  };

  const uploadButton = (
    <div>{isLoading ? <LoadingOutlined /> : <PlusOutlined />}</div>
  );

  return (
    <Upload
      name={'avatar'}
      listType={'picture-card'}
      className={'avatar-uploader'}
      showUploadList={false}
      action={'https://www.mocky.io/v2/5cc8019d300000980a055e76'}
      beforeUpload={beforeUpload}
      onChange={handleChange}
    >
      {imageURL ? (
        <img src={imageURL} alt={'avatar'} style={{ width: '100%' }} />
      ) : (
        uploadButton
      )}
    </Upload>
  );
};

export default ClientAvatar;
