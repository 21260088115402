import { format, formatDistance } from 'date-fns';
import { Client } from './Client';
import { Expose, Type } from 'class-transformer';
import { Account } from './Account';
import { Task } from './Task';
import { Expense } from './Expense';
import { Tags } from '@app/model/Tags';
import { User } from '@app/model/User';

class ProjectMeta {
  color?: string | null;
}
export class Project {
  @Expose() id: number;
  @Expose() key: string;
  @Expose() accountId?: number | null;
  @Expose() clientId?: number | null;

  @Expose() approvedByUserId?: number | null;
  @Expose() archivedByUserId?: number | null;
  @Expose() completedByUserId?: number | null;
  @Expose() createdByUserId?: number | null;
  @Expose() deletedByUserId?: number | null;
  @Expose() disabledByUserId?: number | null;
  @Expose() enabledByUserId?: number | null;
  @Expose() featuredByUserId?: number | null;
  @Expose() lockedByUserId?: number | null;
  @Expose() publishedByUserId?: number | null;
  @Expose() startedByUserId?: number | null;
  @Expose() suspendedByUserId?: number | null;

  @Expose() name: string;
  @Expose() rate?: number | null;
  @Expose() rateFrequency?: string | null;
  @Expose() billableType?: string;
  @Expose() code?: string;
  @Expose() description?: string;
  @Expose() serviceAgreement?: string;
  @Expose() requireTimeApprovals?: boolean | null;
  @Expose() invoiceFrequency?: string | null;
  @Type(() => ProjectMeta)
  @Expose()
  meta?: ProjectMeta | null;

  @Expose() isApproved?: boolean;
  @Expose() isBillable?: boolean;
  @Expose() isArchived?: boolean;
  @Expose() isBudgeted?: boolean;
  @Expose() isCompleted?: boolean;
  @Expose() isDeleted?: boolean;
  @Expose() isDisabled?: boolean;
  @Expose() isEnabled?: boolean;
  @Expose() isEnded?: boolean;
  @Expose() isFeatured?: boolean;
  @Expose() isLocked?: boolean;
  @Expose() isPublished?: boolean;
  @Expose() isStarted?: boolean;
  @Expose() isSuspended?: boolean;
  @Expose() isTrashed?: boolean;
  @Expose() isUnarchived?: boolean;
  @Expose() isUnpublished?: boolean;
  @Expose() trashedByUserId?: boolean;
  @Expose() unarchivedByUserId?: boolean;
  @Expose() unpublishedByUserId?: boolean;

  children?: any | null;

  @Type(() => Date)
  createdAt: Date;
  @Type(() => Date)
  updatedAt?: Date;
  @Type(() => Date)
  startAt?: Date;
  @Type(() => Date)
  endAt?: Date;
  @Type(() => Date)
  approvedAt?: Date | null;
  @Type(() => Date)
  invoicedAt?: Date | null;
  @Type(() => Date)
  completedAt?: Date | null;
  @Type(() => Date)
  archivedAt?: Date | null;
  @Type(() => Date)
  deletedAt?: Date | null;
  @Type(() => Date)
  disabledAt?: Date | null;
  @Type(() => Date)
  enabledAt?: Date | null;
  @Type(() => Date)
  featuredAt?: Date | null;
  @Type(() => Date)
  lastActivityAt?: Date | null;
  @Type(() => Date)
  lockedAt?: Date | null;
  @Type(() => Date)
  publishedAt?: Date | null;
  @Type(() => Date)
  startedAt?: Date | null;
  @Type(() => Date)
  endedAt?: Date | null;
  @Type(() => Date)
  suspendedAt?: Date | null;
  @Type(() => Date)
  trashedAt?: Date | null;
  @Type(() => Date)
  unarchivedAt?: Date | null;
  @Type(() => Date)
  unpublishedAt?: Date | null;

  @Type(() => Account)
  account?: Account | null;
  @Type(() => Client)
  client?: Client | null;
  @Type(() => Expense)
  expenses?: Expense[] | null;
  @Type(() => Task)
  tasks?: Task[];
  @Type(() => Tags)
  tags: Tags[];
  @Type(() => User)
  users: User[];

  getCreatedAt = () => {
    return format(new Date(this.createdAt), 'Pp');
  };

  getCreatedAtDistance = () => {
    return formatDistance(new Date(this.createdAt), new Date(), {
      addSuffix: true,
    });
  };

  getUpdatedAt? = () => {
    return format(new Date(this.updatedAt!), 'Pp');
  };
}
