import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Button, notification } from 'antd';
import TabSnippet from '@app/view/components/Text/TabSnippet';
import { Plus } from 'react-feather';
import { IContact } from '@app/model/IContact';
import ContactCard from '@app/view/components/Cards/ContactCard';
import { Client } from '@app/model/Client';
import { IDataResult } from '@app/model/IDataResult';
import { IErrorResult } from '@app/model/IErrorResult';
import { UserContext } from '@app/contexts/UserContext';

type NewClientContactsTabProps = {
  clientID: number | null | undefined;
  client: Client | null;
  setClient: (client: Client) => void;
};

export const NewClientContactsTab = ({
  clientID,
  client,
  setClient,
}: NewClientContactsTabProps) => {
  const [contacts, setContacts] = useState<IContact[]>([{ name: 'contact' }]);
  const [api, contextHolder] = notification.useNotification();
  const { api: API } = useContext(UserContext);

  useEffect(() => {
    if (client && client?.meta?.contact) {
      const contacts: IContact[] = [];
      client.meta.contact.forEach((e) => {
        contacts.push(e as IContact);
      });
      setContacts(contacts);
    }
  }, [client]);

  const handleAddContact = () => {
    const _contacts = contacts.concat([
      {
        name: 'contact',
      },
    ]);
    setContacts(_contacts);
  };

  const handleRemoveContact = (index: number) => {
    const contactsData = client?.meta?.contact;
    if (contactsData) {
      contactsData.splice(index, 1);
    }

    API.query(`api/v2/client/${clientID}`, 'PUT', {
      contact: contactsData,
    })
      .then((results: IDataResult) => {
        const contacts: IContact[] = [];
        results.data.meta.contact.forEach((e: IContact) => {
          contacts.push(e);
        });
        setContacts(contacts);

        api.success({
          message: 'Success',
          description: 'The contact was removed',
        });
      })
      .catch((e: IErrorResult) => {
        api.error({
          message: 'Error',
          description: 'The contact could not be removed',
        });
        console.error(e.message);
      });
  };

  return (
    <>
      {contextHolder}
      <TabSnippet
        title={'Contacts'}
        subtitle={
          'Add one or many Points of Contact (POCs) that you communicate with.'
        }
        style={{ marginBottom: 20 }}
      />

      {contacts.map((contact: IContact, index: number) => {
        return (
          <ContactCard
            clientID={clientID}
            client={client}
            index={index}
            key={index}
            setClient={setClient}
            onRemove={handleRemoveContact}
          />
        );
      })}

      <Row justify={'space-between'}>
        <Col>
          <Button
            onClick={handleAddContact}
            type={'ghost'}
            icon={
              <Plus
                size={14}
                className={'feather-icon feather-icon-button-prefix'}
              />
            }
          >
            New Contact
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default NewClientContactsTab;
