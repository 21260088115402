import React, { useContext, useState } from 'react';
import { Form, Button, Input, Row, notification } from 'antd';
import AuthWrapper from '@app/view/components/Views/AuthWrapper';
import { Link } from 'react-router-dom';
import { Forms } from '@app/helpers/Constants/forms';
import { UserContext } from '@app/contexts/UserContext';
import { Redirect } from 'react-router';

function PasswordReset() {
  const [redirect, setRedirect] = useState<boolean>(false);
  const { api: API } = useContext(UserContext);
  const [api, contextHolder] = notification.useNotification();

  async function handleSubmit(values: any) {
    const search = new URLSearchParams(window.location.search);
    const data = Object.assign({}, values);
    search.forEach((v, i) => {
      data[i] = v;
    });

    try {
      await API.query('api/v2/reset-password', 'POST', data);
      api.success({
        message: 'Success',
        description: `You're password has been updated. Redirecting to login page.`,
      });

      setTimeout(() => {
        setRedirect(true);
      }, 3000);
    } catch (e) {
      api.error({
        message: 'Error',
        description: (e as Error).message,
      });
    }
  }

  const validatePassword = (rule: any, value: any) => {
    const regex = `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d!@#$%&(){}\\[\\]]+$`;

    if (value && (value.match(regex) || value === 'password')) {
      return Promise.resolve();
    } else {
      return Promise.reject(
        new Error(
          'Enter at least one character, number, or these symbols. !@#$%&(){}[]',
        ),
      );
    }
  };

  if (redirect) {
    return <Redirect to={'/login'} />;
  }

  return (
    <>
      {contextHolder}

      <AuthWrapper title={'Password Reset'}>
        <Form
          onFinish={handleSubmit}
          name={Forms.SignupForm}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item
            className={'custom-password-input'}
            label={'Password'}
            labelCol={{ span: 24 }}
            name={`password`}
            rules={[
              {
                required: true,
                message: 'Please input your password.',
                validateTrigger: 'onSubmit',
              },
              {
                min: 8,
                message: 'Password must be at least 8 characters long.',
                validateTrigger: 'onSubmit',
              },
              {
                validator: validatePassword,
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Input.Password autoComplete={'off'} />
          </Form.Item>
          <Form.Item
            className={'custom-password-input'}
            label={'Password Confirm'}
            labelCol={{ span: 24 }}
            name={`password_confirm`}
            rules={[
              {
                required: true,
                message: 'Please confirm your password.',
                validateTrigger: 'onSubmit',
              },
              ({ getFieldValue }) => ({
                validateTrigger: 'onSubmit',
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match!',
                    ),
                  );
                },
              }),
            ]}
          >
            <Input.Password autoComplete={'off'} />
          </Form.Item>

          <Row justify={'start'} style={{ marginTop: 35 }}>
            <Form.Item style={{ marginRight: 10 }}>
              <Button type={'ghost'}>
                <Link to="/login">Cancel</Link>
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" form={Forms.SignupForm}>
                Submit
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </AuthWrapper>
    </>
  );
}

export default PasswordReset;
