import React, { useContext, useState } from 'react';
import { Divider, Button, Modal, Form, Input, notification } from 'antd';
import { UserContext } from '@app/contexts/UserContext';

function Security() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const { api: API } = useContext(UserContext);

  const validatePassword = (rule: any, value: any, callback: any) => {
    const regex = `^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)[a-zA-Z\\d!@#$%&(){}\\[\\]]+$`;

    if (value && (value.match(regex) || value === 'password')) {
      return Promise.resolve();
    } else {
      return Promise.reject(
        new Error('Enter at least one character and number.'),
      );
    }
  };

  function showModal() {
    setIsModalVisible(true);
  }

  function handleOk() {
    form
      .validateFields()
      .then((values) => {
        API.query('api/v2/user/update-password', 'POST', values).then(
          (response) => {
            if (response.message === 'success') {
              api.success({
                message: 'Success',
                description: 'Your password has been updated',
                placement: 'topRight',
              });
              setIsModalVisible(false);
            } else {
              api.error({
                message: 'Error',
                description: response.message,
                placement: 'topRight',
              });
            }
          },
        );
      })
      .catch();
  }

  function handleCancel() {
    setIsModalVisible(false);
  }

  const onFinish = (values: any) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div>
      {contextHolder}
      <h2>Security Settings</h2>
      <Divider></Divider>
      <p style={{ marginTop: '2rem' }}>Change your password</p>
      <Button onClick={showModal}>Change Password</Button>
      <Modal
        title="Change Password"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          name="change-password"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <Form.Item
            label="Old Password"
            name="old_password"
            rules={[{ required: true }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="New Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password.',
                validateTrigger: 'onSubmit',
              },
              { validator: validatePassword },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="password_confirm"
            rules={[
              {
                required: true,
                message: 'Please input your password.',
                validateTrigger: 'onSubmit',
              },
              { validator: validatePassword },
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default Security;
