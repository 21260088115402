import { Expose, Type } from 'class-transformer';
import { Client } from './Client';
import { Project } from './Project';
import { Expense } from './Expense';
import { Taskable } from './Taskable';

export class Task {
  @Expose() id: number;
  @Expose() accountId: number;
  @Expose() channelId: number;
  @Expose() createdByUserId: number;
  @Expose() key: string;

  @Expose() name: string;
  @Expose() description?: string | null;
  @Expose() isBillable?: number | null;
  @Expose() meta?: string | null;

  @Type(() => Date)
  createdAt?: Date;
  @Type(() => Date)
  updatedAt?: Date;

  @Type(() => Client)
  client?: Client | null;
  @Type(() => Project)
  project?: Project | null;
  @Type(() => Expense)
  expenses?: Expense[] | null;
  @Type(() => Taskable)
  taskable?: Taskable | null;
}

export const QuantityType = {
  time: 'time',
  product: 'product',
  service: 'service',
  deliverable: 'deliverable',
};
