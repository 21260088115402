import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Button, notification } from 'antd';
import TabSnippet from '../../../../components/Text/TabSnippet';
import { ILocation } from '@app/model/ILocation';
import { Plus } from 'react-feather';
import LocationCard from '../../../../components/Cards/LocationCard';
import { Client } from '@app/model/Client';
import { IDataResult } from '@app/model/IDataResult';
import { IErrorResult } from '@app/model/IErrorResult';
import { UserContext } from '@app/contexts/UserContext';

type NewClientLocationsTabProps = {
  clientID: number | null | undefined;
  client: Client | null;
  setClient: (client: Client) => void;
};

export const NewClientLocationsTab = ({
  clientID,
  client,
  setClient,
}: NewClientLocationsTabProps) => {
  const [locations, setLocations] = useState<ILocation[]>([
    { name: 'location' },
  ]);
  const [api, contextHolder] = notification.useNotification();
  const { api: API } = useContext(UserContext);

  useEffect(() => {
    if (client && client?.meta?.location) {
      const locations: ILocation[] = [];
      client.meta.location.forEach((e) => {
        locations.push(e as ILocation);
      });
      setLocations(locations);
    }
  }, [client]);
  const handleAddLocation = () => {
    const _locations = locations.concat([
      {
        name: 'location',
      },
    ]);
    setLocations(_locations);
  };

  const handleRemoveLocation = (index: number) => {
    const locationData = client?.meta?.location;
    if (locationData) {
      locationData.splice(index, 1);
    }

    API.query(`api/v2/client/${clientID}`, 'PUT', {
      location: locationData,
    })
      .then((results: IDataResult) => {
        const locations: ILocation[] = [];
        results.data.meta.location.forEach((e: ILocation) => {
          locations.push(e);
        });
        setLocations(locations);

        api.success({
          message: 'Success',
          description: 'The location was removed',
        });
      })
      .catch((e: IErrorResult) => {
        api.error({
          message: 'Error',
          description: 'The location could not be removed',
        });
        console.error(e.message);
      });
  };

  return (
    <>
      {contextHolder}
      <TabSnippet
        title={'Locations'}
        subtitle={
          'Add one or many locations where your client operates their business.'
        }
        style={{ marginBottom: 20 }}
      />

      {locations.map((location: ILocation, index: number) => {
        return (
          <LocationCard
            clientID={clientID}
            client={client}
            index={index}
            key={index}
            setClient={setClient}
            onRemove={handleRemoveLocation}
          />
        );
      })}

      <Row justify={'space-between'}>
        <Col>
          <Button
            onClick={handleAddLocation}
            type={'ghost'}
            icon={
              <Plus
                size={14}
                className={'feather-icon feather-icon-button-prefix'}
              />
            }
          >
            New Location
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default NewClientLocationsTab;
