import React from 'react';
import './style.scss';
import { Tabs } from 'antd';
import AccountInfo from '@app/view/components/Account/AccountInfo';
import Projects from '@app/view/components/Account/Projects';
import Security from '@app/view/components/Account/Security';
import Notifications from '@app/view/components/Account/Notifications';
import Wrapper from '@app/view/components/Views/Wrapper';
import useWindowDimensions from '@app/helpers/Hooks/useWindowDimensions';
const { TabPane } = Tabs;

function AccountPage() {
  const { width } = useWindowDimensions();

  return (
    <Wrapper title={'Account'}>
      <Tabs
        tabPosition={width < 1024 ? 'top' : 'left'}
        style={{ minHeight: '50vh' }}
        defaultActiveKey={'1'}
      >
        <TabPane tab={'Projects'} key={'1'}>
          <Projects />
        </TabPane>
        <TabPane tab={'Account'} key={'2'}>
          <AccountInfo />
        </TabPane>
        <TabPane tab={'Security'} key={'3'}>
          <Security />
        </TabPane>
        <TabPane tab={'Notifications'} key={'4'}>
          <Notifications />
        </TabPane>
      </Tabs>
    </Wrapper>
  );
}

export default AccountPage;
