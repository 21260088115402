import React from 'react';
import { Tabs } from 'antd';
import Wrapper from '@app/view/components/Views/Wrapper';
import useWindowDimensions from '@app/helpers/Hooks/useWindowDimensions';
import NewProjectDetailsTab from '../NewProjectDetailsTab';

function NewProjectPage() {
  const { width } = useWindowDimensions();

  return (
    <Wrapper
      title={`New Project`}
      subtitle={'Create a project to add budgets, tasks, roles, and documents.'}
    >
      <Tabs
        tabPosition={width < 1024 ? 'top' : 'left'}
        style={{ minHeight: '50vh' }}
        defaultActiveKey={'1'}
        items={[
          {
            key: '1',
            label: 'Details',
            children: <NewProjectDetailsTab />,
          },
          {
            key: '2',
            label: 'Budget',
            disabled: true,
            children: <></>,
          },
          {
            key: '3',
            label: 'Tasks',
            disabled: true,
            children: <></>,
          },
          {
            key: '4',
            label: 'Users',
            disabled: true,
            children: <></>,
          },
          {
            key: '5',
            label: 'Documents',
            disabled: true,
            children: <></>,
          },
        ]}
      />
    </Wrapper>
  );
}

export default NewProjectPage;
