import React, { useContext, useState } from 'react';
import {
  Row,
  Col,
  Form,
  Button,
  Spin,
  Input,
  message,
  Switch,
  Select,
  InputNumber,
} from 'antd';
import CustomModal from '../CustomModal';
import { Forms } from '@app/helpers/Constants/forms';
import { APIAssembly, Methods } from '@app/data/API';
import { IDataResult } from '@app/model/IDataResult';
import { IErrorResult } from '@app/model/IErrorResult';
import { Project } from '@app/model/Project';
import { ChevronDown } from 'react-feather';
import { Task } from '@app/model/Task';
import { plainToClass } from 'class-transformer';
import { UserContext } from '@app/contexts/UserContext';
const { TextArea } = Input;
const { Option } = Select;

interface NewProjectTaskModalProps extends React.HTMLAttributes<HTMLElement> {
  project: Project | null | undefined;
  onComplete?: (task: Task) => void;
  onOk?: () => void;
  onCancel?: () => void;
  visible: boolean;
}

const NewProjectTaskModal: React.FC<NewProjectTaskModalProps> = ({
  project,
  onComplete,
  onOk,
  onCancel,
  visible,
  ...restProps
}) => {
  const [isBillable, setIsBillable] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { api: API } = useContext(UserContext);
  const fieldName = 'task';
  const fieldKey = 0;

  const onFinish = (values: any) => {
    if (typeof values.task === `undefined` || !project?.id) {
      onFinishFailed();
      return;
    }

    const assembly: APIAssembly = {
      endpoint: `api/v2/project/${project?.id}/tasks`,
      method: Methods.POST,
      body: values.task,
    };

    setIsLoading(true);
    API.query(assembly.endpoint, assembly.method, assembly.body)
      .then((result: IDataResult) => {
        setIsLoading(false);
        onReset();
        message.success(`Task created successfully`).then();

        const _task: any = plainToClass(Task, result.data);
        onComplete && onComplete(_task);
      })
      .catch((error: IErrorResult) => {
        console.error(error);
      });
  };

  const onFinishFailed = () => {
    setIsLoading(false);
    message.error('Unable to save').then();
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <CustomModal
      {...restProps}
      onCancel={() => {
        onCancel && onCancel();
      }}
      customOKButton={
        <Form.Item style={{ marginBottom: 0 }}>
          <Button
            form={Forms.NewProjectTaskForm}
            type={'primary'}
            key={'submit'}
            htmlType={'submit'}
          >
            Create Task
          </Button>
        </Form.Item>
      }
      hasOkButton={true}
      okText={'Done'}
      visible={visible}
      title={`New Project-Level Task`}
    >
      <Form
        autoComplete={'on'}
        autoCapitalize={'off'}
        form={form}
        layout={'vertical'}
        id={Forms.NewProjectTaskForm}
        name={Forms.NewProjectTaskForm}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={true}
        style={{ paddingBottom: 0 }}
        wrapperCol={{ span: 24 }}
      >
        <Spin spinning={isLoading}>
          <Row gutter={15} align={'bottom'}>
            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                required
                label={'Task Name'}
                name={[fieldName, 'name']}
                fieldKey={[fieldKey, 'name']}
                key={fieldKey}
                rules={[
                  {
                    required: true,
                    type: 'string',
                    message: 'Please enter task name.',
                    validateTrigger: 'onSubmit',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label={''}
                name={[fieldName, 'isBillable']}
                fieldKey={[fieldKey, 'isBillable']}
                key={fieldKey}
                valuePropName={'checked'}
              >
                <Row align={'middle'}>
                  <Switch
                    defaultChecked
                    onChange={(checked: boolean) => {
                      setIsBillable(checked);
                    }}
                    style={{ marginRight: 10 }}
                  />
                  {isBillable ? `Task is Billable` : `Task is Non-Billable`}
                </Row>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={15}>
            <Col span={24}>
              <Form.Item
                label={'Description'}
                name={[fieldName, 'description']}
                fieldKey={[fieldKey, 'description']}
                key={fieldKey}
              >
                <TextArea
                  rows={1}
                  placeholder={'Enter a task description...'}
                  style={{ width: '100%', maxHeight: 80 }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={15}>
            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label={'Rate'}
                name={[fieldName, 'rate']}
                fieldKey={[fieldKey, 'rate']}
                key={fieldKey}
                rules={[
                  {
                    type: 'number',
                    message: 'Please enter a number.',
                    validateTrigger: 'onSubmit',
                  },
                ]}
              >
                <InputNumber
                  min={`0.01`}
                  step={`0.01`}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
            <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                label={'Rate Frequency'}
                name={[fieldName, 'rateFrequency']}
                fieldKey={[fieldKey, 'rateFrequency']}
                key={fieldKey}
              >
                <Select
                  showSearch
                  optionFilterProp={`children`}
                  // onSelect={(value: any) => {onSelectFrequency(record, value)}}
                  filterOption={(input, option) =>
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    option?.children
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  suffixIcon={
                    <ChevronDown
                      size={16}
                      className={'feather-icon feather-icon-select'}
                    />
                  }
                >
                  <Option value={'Hourly'} key={1}>
                    Hourly
                  </Option>
                  <Option value={'Daily'} key={2}>
                    Daily
                  </Option>
                  <Option value={'Weekly'} key={3}>
                    Weekly
                  </Option>
                  <Option value={'Monthly'} key={4}>
                    Monthly
                  </Option>
                  <Option value={'Yearly'} key={5}>
                    Yearly
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Spin>
      </Form>
    </CustomModal>
  );
};

export default NewProjectTaskModal;
