import { Type } from 'class-transformer';
import { Task } from './Task';
import { Client } from './Client';
import { Project } from './Project';

export class Tracking {
  id: number;

  accountId: string;

  hours: string;

  trackedOn: string;

  timerStarted: string;

  isBillable: string;

  invoiced: boolean;

  invoicedAt: string;

  invoiceId: string;

  createdByUserId: string;

  updatedByUserId: string;

  @Type(() => Client)
  client: Client;

  @Type(() => Task)
  task: Task;

  @Type(() => Project)
  project: Project;
}
