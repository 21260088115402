import React from 'react';
import { User } from '../model/User';
import { Account } from '../model/Account';
import UserManager from '@app/helpers/Auth/UserManager';
import { API } from '@app/data/API';

type UserContextType = {
  user: UserManager;
  api: API;
  didSelectAccount: boolean;
  loginUser: (user: User) => Promise<void>;
  logoutUser: () => void;
  selectAccount: (account: Account) => void;
  darkMode: boolean;
};

const userManagement = new UserManager();
const UserContext = React.createContext<UserContextType>({
  didSelectAccount: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  loginUser(user: User): Promise<void> {
    return Promise.resolve(undefined);
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logoutUser(): void {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
  selectAccount(account: Account): void {},
  user: userManagement,
  darkMode: false,
  api: new API(userManagement),
});

export { UserContext };
