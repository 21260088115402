import React, { useContext, useEffect, useState } from 'react';
import { message, Spin, Col, Row, Select, Button } from 'antd';
import TabSnippet from '@app/view/components/Text/TabSnippet';
import { IDataResult } from '@app/model/IDataResult';
import { plainToClass } from 'class-transformer';
import { Task } from '@app/model/Task';
import { Project } from '@app/model/Project';
import { ChevronDown, Plus } from 'react-feather';
import { SelectValue } from 'antd/es/select';
import NewProjectTaskModal from '@app/view/components/Modals/NewProjectTaskModal';
import ProjectTaskTable from '@app/view/components/Tables/ProjectTaskTable';
import { UserContext } from '@app/contexts/UserContext';

type ProjectTasksTabProps = {
  project?: Project;
};

export const ProjectTasksTab = ({ project }: ProjectTasksTabProps) => {
  const [value, setValue] = useState<SelectValue>([]);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [selectedTasks, setSelectedTasks] = useState<Task[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { api: API } = useContext(UserContext);

  useEffect(() => {
    getProjectTasks();
    setSelectedTasks(project?.tasks ? project?.tasks : []);
  }, []);

  /**
   * Get Tasks at all levels from inherited flag (Account, Client, Project).
   */
  const getProjectTasks = () => {
    API.get(`api/v2/project/${project?.id}/tasks?inherit=1`)
      .then((result: IDataResult) => {
        const _tasks: any = plainToClass(Task, result.data);
        setTasks(_tasks);
        setIsLoading(false);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  /**
   * OnSelect of Option, add task to selectedTasks and remove from user datasource.
   *
   * @param value The Option's value, in this case the task's index in the tasks array.
   */
  const onSelectTask = (value: any) => {
    setValue([]);
    const task = tasks[value];
    const found = selectedTasks.some(
      (_selectedTask) => _selectedTask.id === task.id,
    );

    if (!found) {
      const _selectedTasks = [...selectedTasks];
      _selectedTasks.push(task);
      setSelectedTasks(_selectedTasks);
    } else {
      message.error('Can not add a duplicate task value.');
    }
  };

  /**
   * OnClick of trash button will remove a selectedTask and add the Task back to datasource.
   *
   * @param record A Task record provided by row.
   */
  const removeSelectedTask = async (record: Task) => {
    return await API.query(
      `api/v2/project/${project?.id}/tasks/${record.id}`,
      'DELETE',
    );
  };

  /**
   * Add a new Task to the state's array of Tasks.
   *
   * @param task A Task record provided by row.
   */
  const addNewTask = (task: Task) => {
    const _tasks = [...tasks];
    _tasks.push(task);
    _tasks.sort(function (a, b) {
      return a.name?.localeCompare(b.name);
    });
    setTasks(_tasks);
  };

  return (
    <>
      <TabSnippet
        title={'Tasks'}
        subtitle={
          'Select which services and deliverables will be worked on in this project.'
        }
        style={{ marginBottom: 20 }}
      />
      <Spin spinning={isLoading}>
        <Row gutter={15} style={{ marginBottom: 20 }}>
          <Col span={12}>
            <Select
              showSearch
              style={{ minWidth: '100%' }}
              optionFilterProp={`children`}
              placeholder={'Choose a task...'}
              value={value}
              onSelect={onSelectTask}
              filterOption={(input, option) =>
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              suffixIcon={
                <ChevronDown
                  size={16}
                  className={'feather-icon feather-icon-select'}
                />
              }
              options={tasks.map((task: Task, index: number) => {
                return { value: index, label: task.name };
              })}
            />
          </Col>

          <Col span={8}>
            <Button
              onClick={() => {
                setIsModalVisible(true);
              }}
              type={'ghost'}
              icon={
                <Plus
                  size={14}
                  className={'feather-icon feather-icon-button-prefix'}
                />
              }
            >
              Project-Level Task
            </Button>
          </Col>
        </Row>

        <Row gutter={15}>
          <Col span={24}>
            <ProjectTaskTable
              tasks={selectedTasks}
              project={project}
              onRemove={removeSelectedTask}
              setTasks={setSelectedTasks}
            />
          </Col>
        </Row>

        <NewProjectTaskModal
          project={project}
          onComplete={(task: Task) => {
            addNewTask(task);
            setIsModalVisible(false);
          }}
          onCancel={() => {
            setIsModalVisible(false);
          }}
          visible={isModalVisible}
        />
      </Spin>
    </>
  );
};

export default ProjectTasksTab;
