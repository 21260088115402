import { useEffect, useState } from 'react';

/* https://stackoverflow.com/questions/43817118/how-to-get-the-width-of-a-react-element */

export const useContainerDimensions = (ref: any) => {
  const getDimensions = () => ({
    width: ref.current.offsetWidth,
    height: ref.current.offsetHeight,
  });

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions());
    };

    setTimeout(
      function () {
        if (ref.current) {
          setDimensions(getDimensions());
        }
      }.bind(this),
      1,
    );

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref]);

  return dimensions;
};
