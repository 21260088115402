import React, { useContext, useEffect, useState } from 'react';
import Wrapper from '../../../../components/Views/Wrapper';
import { Tabs } from 'antd';
import useWindowDimensions from '../../../../../helpers/Hooks/useWindowDimensions';
import NewClientOverviewTab from '../NewClientOverviewTab';
import NewClientLocationsTab from '../NewClientLocationsTab';
import NewClientContactsTab from '../NewClientContactsTab';
import { useParams } from 'react-router-dom';
import { Client } from '@app/model/Client';
import { IDataResult } from '@app/model/IDataResult';
import { UserContext } from '@app/contexts/UserContext';
const { TabPane } = Tabs;

export type NewClientFormValues = {
  name: string;
  logo?: string | undefined;
  since?: string | undefined;
  currency?: string | undefined;
  description?: string | undefined;
};

type ClientParams = {
  id?: string;
};

function NewClientPage() {
  const [clientID, setClientID] = useState<number | null | undefined>(null);
  const [client, setClient] = useState<Client | null>(null);
  const { width } = useWindowDimensions();
  const { api: API } = useContext(UserContext);
  const params = useParams<ClientParams>();

  useEffect(() => {
    if (typeof params.id !== 'undefined') {
      setClientID(Number(params.id));

      API.get(`api/v2/client/${params.id}`).then((result: IDataResult) => {
        setClient(result.data);
      });
    }
  }, []);

  const onSave = (clientID: number | null | undefined) => {
    clientID && setClientID(clientID);
  };

  return (
    <Wrapper
      title={clientID ? 'Edit Client' : 'New Client'}
      subtitle={'Create a client to add projects, locations, and contacts.'}
    >
      <Tabs
        tabPosition={width < 1024 ? 'top' : 'left'}
        defaultActiveKey={'1'}
        style={{ marginTop: 10 }}
      >
        <TabPane tab={'Overview'} key={'1'}>
          <NewClientOverviewTab
            onSave={onSave}
            client={client}
            setClient={setClient}
          />
        </TabPane>
        <TabPane disabled={!clientID} tab={'Locations'} key={'2'}>
          <NewClientLocationsTab
            clientID={clientID}
            client={client}
            setClient={setClient}
          />
        </TabPane>
        <TabPane disabled={!clientID} tab={'Contacts'} key={'3'}>
          <NewClientContactsTab
            clientID={clientID}
            client={client}
            setClient={setClient}
          />
        </TabPane>
      </Tabs>
    </Wrapper>
  );
}

export default NewClientPage;
