import { Expose, Type } from 'class-transformer';
import { Account } from './Account';
import { Project } from './Project';
import { ILocation } from '@app/model/ILocation';

export class ClientLocation {
  locationName?: string | null;
  addressLineOne?: string | null;
  addressLineTwo?: string | null;
  city?: string | null;
  state?: string | null;
  zipCode?: string | null;
  country?: string | null;
  timezone?: string | null;
  preferredCurrency?: string | null;
  isPrimary?: boolean | null;
}

export class ClientContact {
  name: string | number;
  firstName?: string | null;
  lastName?: string | null;
  emailAddress?: string | null;
  phoneNumber?: string | null;
  officeNumber?: string | null;
  title?: string | null;
  notes?: string | null;
  tags?: string[] | null;
  isPrimary?: boolean | null;

  client?: Client | null;
  location?: ILocation | null;
}
class ClientMeta {
  since?: string | null;

  @Type(() => ClientLocation)
  location?: ClientLocation[] | null;

  @Type(() => ClientContact)
  contact?: ClientContact[] | null;
}

export class Client {
  @Expose() id: number | null;
  @Expose() key: string;
  @Expose() accountId?: number | null;
  @Expose() locationId?: number | null;
  @Expose() channelId?: number | null;

  @Expose() name: string;
  @Expose() description?: string | null;
  @Expose() isBudgeted?: number | null;

  isArchived?: string | null;

  @Type(() => ClientMeta)
  meta?: ClientMeta | null;

  @Expose() children?: Project[] | null;

  @Type(() => Date)
  createdAt?: Date;
  @Type(() => Date)
  updatedAt?: Date;

  @Type(() => Account)
  account?: Account | null;
  @Type(() => Project)
  projects?: Project[] | null;
}
