import './style.scss';
import React from 'react';
import { Col, Typography } from 'antd';
const { Text } = Typography;

type TitleCellProps = {
  title: string;
  subtitle?: string | undefined;
  alignItems?: string | undefined;
};

export const TitleCell = ({
  title,
  subtitle,
  alignItems = 'flex-start',
}: TitleCellProps) => {
  return (
    <Col className={'column'} style={{ alignItems }}>
      <Text className={'title'}>{title}</Text>
      {subtitle && (
        <Text className={'subtitle'} type="secondary">
          {subtitle}
        </Text>
      )}
    </Col>
  );
};

export default TitleCell;
