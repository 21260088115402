import React, { useContext, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  message,
  Button,
  DatePicker,
  Card,
  Spin,
  notification,
  DatePickerProps,
} from 'antd';
import ClientAvatar from '@app/view/components/Images/ClientAvatar';
import { Link } from 'react-router-dom';
import { IDataResult } from '@app/model/IDataResult';
import { Forms } from '@app/helpers/Constants/forms';
import TabSnippet from '@app/view/components/Text/TabSnippet';
import { APIAssembly, Methods } from '@app/data/API';
import { UserContext } from '@app/contexts/UserContext';
import { IErrorResult } from '@app/model/IErrorResult';
import { Client } from '@app/model/Client';
import dayjs from 'dayjs';
const { TextArea } = Input;
import { useHistory } from 'react-router-dom';

type NewClientOverviewTabProps = {
  onSave: (clientID: number | null | undefined) => void;
  client: Client | null;
  setClient: (client: Client) => void;
};

export const NewClientOverviewTab = ({
  onSave,
  client,
  setClient,
}: NewClientOverviewTabProps) => {
  const [clientID, setClientID] = useState<number | null | undefined>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { user, api: API } = useContext(UserContext);
  const [api, contextHolder] = notification.useNotification();
  const history = useHistory();

  const userData = user.getUser();

  const customFormat: DatePickerProps['format'] = (value) =>
    value.format('YYYY-MM-DD');

  // Needed to re-render the component after the client has been pulled
  useEffect(() => {
    if (client) {
      const clientData = {
        client: {
          name: client?.name,
          description: client?.description,
          since: client?.meta?.since ? dayjs(client?.meta?.since) : '',
        },
      };
      form.setFieldsValue(clientData);

      setClientID(client.id);
    }
  }, [client]);

  const onFinish = (values: any) => {
    setIsLoading(true);

    if (typeof values.client === `undefined`) {
      onFinishFailed();
      return;
    }

    const accountId = userData.account?.id;
    const assembly: APIAssembly = {
      endpoint:
        clientID !== null
          ? `api/v2/client/${clientID}`
          : `api/v2/account/${accountId}/client`,
      method: clientID !== null ? Methods.PUT : Methods.POST,
      body: values.client,
    };

    API.query(assembly.endpoint, assembly.method, assembly.body)
      .then((result: IDataResult) => {
        if (!clientID) {
          setClientID(result.data.id);
        }
        setClient(result.data);
        onSave(result.data.id);
        setIsLoading(false);
        api.success({
          message: 'Success',
          description: `Client ${
            clientID !== null ? `updated` : `saved`
          } successfully`,
        });

        if (history.location.pathname.indexOf('new')) {
          history.push(`/clients/${result.data.id}`);
        }
      })
      .catch((error: IErrorResult) => {
        api.error({
          message: 'Error',
          description: 'Could not create new client.',
        });
        console.error(error);
      });
  };

  const onFinishFailed = () => {
    setIsLoading(false);
    message.error('Unable to save').then();
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <>
      {contextHolder}
      <Form
        autoComplete={'on'}
        autoCapitalize={'off'}
        form={form}
        layout={'vertical'}
        id={Forms.ClientOverviewForm}
        name={Forms.ClientOverviewForm}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={true}
        wrapperCol={{ span: 24 }}
      >
        <TabSnippet
          title={'Overview'}
          subtitle={
            "Add your client's logo, name, and other optional information."
          }
          style={{ marginBottom: 20 }}
        />
        <Spin spinning={isLoading}>
          <Card>
            <Row gutter={15} justify={'start'} align={'top'}>
              <Col xl={2} lg={3} md={24} sm={24} xs={24}>
                <Form.Item
                  label={'Logo'}
                  name={['client', 'logo']}
                  key={1}
                  style={{ marginBottom: 10 }}
                >
                  <ClientAvatar
                    onUpload={(URL: string) => {
                      form.setFieldsValue({
                        clientLogo: URL,
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={15} justify={'start'} align={'top'}>
              <Col xl={8} lg={7} md={24} sm={24} xs={24}>
                <Form.Item
                  required
                  label={'Client Name'}
                  name={['client', 'name']}
                  key={1}
                  rules={[{ required: true }, { type: 'string', min: 2 }]}
                >
                  <Input placeholder={"Company or Individual's Name"} />
                </Form.Item>
              </Col>

              <Col xl={4} lg={4} md={24} sm={24} xs={24}>
                <Form.Item
                  label={'Client Since'}
                  name={['client', 'since']}
                  key={2}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    placeholder={''}
                    format={customFormat}
                  />
                </Form.Item>
              </Col>

              <Col xl={12} lg={10} md={24} sm={24} xs={24}>
                <Form.Item
                  label={'Notes or Description'}
                  name={['client', 'description']}
                  key={3}
                >
                  <TextArea
                    rows={1}
                    maxLength={1024}
                    style={{ width: '100%', maxHeight: 80 }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row justify={'end'} gutter={10} style={{ marginTop: 15 }}>
              <Col>
                <Form.Item style={{ marginBottom: 0 }}>
                  <Link to="/clients">
                    <Button
                      form={Forms.ClientOverviewForm}
                      onClick={onReset}
                      type={'ghost'}
                    >
                      {clientID !== null ? 'Back' : 'Cancel'}
                    </Button>
                  </Link>
                </Form.Item>
              </Col>

              <Col>
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button
                    form={Forms.ClientOverviewForm}
                    type={'primary'}
                    key={'submit'}
                    htmlType={'submit'}
                  >
                    {clientID !== null ? 'Update' : 'Save'}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Spin>
      </Form>
    </>
  );
};

export default NewClientOverviewTab;
