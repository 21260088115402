import { Button, Card, Col, Row, Typography, message } from 'antd';
import { Edit } from 'react-feather';
import React, { useRef, useEffect, useState, useContext } from 'react';
import { Tracking } from '@app/model/Tracking';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleStop, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { IErrorResult } from '@app/model/IErrorResult';
import { differenceInMinutes } from 'date-fns';
import { getTodaysDate } from '@app/helpers/Date/getDateObject';
import { UserContext } from '@app/contexts/UserContext';

const { Text } = Typography;

type TrackingProps = {
  track: Tracking;
  refresh: (update: boolean) => void;
  setTracker: (tracker: Tracking) => void;
  editVisible: () => void;
};
export default function IndividualTrackingCard({
  track,
  refresh,
  setTracker,
  editVisible,
}: TrackingProps) {
  const trackingRef = useRef<number | null>(null);
  const [trackTimer, setTrackTimer] = useState<string>('0.00');

  const { user, api: API } = useContext(UserContext);
  const userData = user.getUser();

  /**
   * Toggles the timers on and off
   *
   * @param id
   */
  const startStopTimer = async (id: number): Promise<void> => {
    try {
      const data = await API.query(`api/v2/tracking/${id}/toggle`, 'POST');
      if (trackingRef.current) {
        window.clearInterval(trackingRef.current);
        trackingRef.current = null;
      }
      refresh(true);
    } catch (e) {
      message.error((e as IErrorResult).message);
    }
  };

  // Handles watching the timer
  useEffect(() => {
    if (track.timerStarted) {
      setTrackTimer(calculateTime(track));

      trackingRef.current = window.setInterval(() => {
        setTrackTimer(calculateTime(track));
      }, 1000);
    }
  }, [track]);

  /**
   * Calculates the amount of time tracked since starting the timer
   *
   * @param track
   * @returns string The amount of time tracked
   */
  const calculateTime = (track: Tracking): string => {
    const difference = differenceInMinutes(
      new Date(),
      getTodaysDate(userData, track.timerStarted + ' UTC'),
    );

    const sum = Number(track.hours) + difference / 60;
    return String(Math.round(sum * 100 + Number.EPSILON) / 100);
  };

  /**
   * Set tracking data and display the edit modal
   *
   * @param tracker
   */
  const triggerEditing = (tracker: Tracking) => {
    setTracker(tracker);
    editVisible();
  };

  const fontColor = track.timerStarted ? 'dark' : '';

  return (
    <Card
      className={track.timerStarted ? 'active-card' : 'card-light'}
      style={{ marginBottom: 10 }}
    >
      <Row align={'middle'} justify={'space-between'}>
        <Col className={'flex-column'}>
          <Text className={fontColor} style={{ fontWeight: 'bold' }}>
            {track.project.name} ({track.client.name})
            {!track.timerStarted && (
              <Edit
                size={12}
                style={{ marginLeft: 5, cursor: 'pointer' }}
                onClick={() => triggerEditing(track)}
              />
            )}
          </Text>
          <Text className={'font-small ' + fontColor}>{track.task.name}</Text>
        </Col>
        <Col className={'time-btn-alignment'}>
          {!track.timerStarted && (
            <>
              <Text>{track.hours}</Text>
              <Button
                className={'control-btn play'}
                onClick={async () => await startStopTimer(track.id)}
              >
                <FontAwesomeIcon icon={faPlayCircle} size={'2x'} />
              </Button>
            </>
          )}

          {track.timerStarted && (
            <>
              <Text>{trackTimer}</Text>
              <Button
                className={'control-btn stop'}
                onClick={async () => await startStopTimer(track.id)}
              >
                <FontAwesomeIcon icon={faCircleStop} size={'2x'} />
              </Button>
            </>
          )}
        </Col>
      </Row>
    </Card>
  );
}
