import React from 'react';
import { Row, Col, Card, Typography } from 'antd';
import { ChevronRight } from 'react-feather';
import { Link } from 'react-router-dom';
import { Client } from '../../../../model/Client';
const { Text, Title } = Typography;

type ClientCardProps = {
  client: Client;
};

export const ClientCard = ({ client }: ClientCardProps) => {
  return (
    <Link to={`/clients/${client.id}`}>
      <Card size={'small'} style={{ marginBottom: 10 }}>
        <Row justify={'space-between'} align={'middle'}>
          <Col>
            <Title level={5} style={{ marginBottom: 0, fontWeight: 500 }}>
              {client.name}
            </Title>
            <Text type={'secondary'}>Client value: $50,000.00</Text>
          </Col>
          <Col>
            <ChevronRight
              size={18}
              className={'feather-icon color-secondary'}
              style={{ top: 4 }}
            />
          </Col>
        </Row>
      </Card>
    </Link>
  );
};

export default ClientCard;
