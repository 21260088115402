import './style.scss';
import React, { useContext, useRef } from 'react';
import { Col, Row } from 'antd';
import { Typography } from 'antd';
import { useContainerDimensions } from '@app/helpers/Hooks/useContainerDimensions';
import { useTheme } from '@app/styles/theme';
import { UserContext } from '@app/contexts/UserContext';
const { Title, Text } = Typography;

interface WrapperProps {
  children: React.ReactNode;
  primaryButton?: React.ReactNode | undefined;
  secondaryButton?: React.ReactNode | undefined;
  title?: string | null | undefined;
  subtitle?: string | null | undefined;
}

const Wrapper: React.FC<WrapperProps> = ({
  children,
  primaryButton,
  secondaryButton,
  title,
  subtitle,
  ...restProps
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { width } = useContainerDimensions(ref);
  const { darkMode } = useContext(UserContext);
  const theme = useTheme();

  const renderHeaderBar = () => {
    if (!title || width === 0) {
      return null;
    }

    return (
      <Row className={'header-row'}>
        <Row
          gutter={8}
          align={'middle'}
          style={{ marginLeft: 0, marginRight: 0 }}
        >
          <Title
            level={3}
            style={{
              fontWeight: 400,
              marginTop: 0,
              color: darkMode ? theme.dark.text : theme.light.text,
            }}
            className={'header-title'}
          >
            {title}
          </Title>
          {subtitle && (
            <Text type={'secondary'} className={'header-subtitle'}>
              {subtitle}
            </Text>
          )}
        </Row>

        {(primaryButton || secondaryButton) && (
          <Row gutter={15} align={'top'} justify={'end'}>
            <Col>{secondaryButton && secondaryButton}</Col>
            <Col>{primaryButton && primaryButton}</Col>
          </Row>
        )}
      </Row>
    );
  };

  return (
    <div {...restProps} style={{ minHeight: '100%', paddingBottom: '35px' }}>
      <Row gutter={15} className={'wrapper-row'}>
        <Col xxl={2} xl={2} lg={1} md={0} sm={0} xs={0} />
        <Col xxl={20} xl={20} lg={22} md={24} sm={24} xs={24} ref={ref}>
          {renderHeaderBar()}
          <div className={'children'}>{children}</div>
        </Col>
        <Col xxl={2} xl={2} lg={1} md={0} sm={0} xs={0} />
      </Row>
    </div>
  );
};

export default Wrapper;
