import { Expose, Type } from 'class-transformer';
import { Location } from './Location';
import { Subscription } from './Subscription';
import { AccountUser } from './AccountUser';
import { Role } from '@app/model/Role';

export class Account {
  @Expose() id: number;
  @Expose() locationId?: number | null;
  @Expose() subscriptionId?: number | null;
  @Expose() updatedByUserId?: number | null;
  @Expose() disabledByUserId?: number | null;
  @Expose() deletedByUserId?: number | null;
  @Expose() createdByUserId?: number | null;
  @Expose() archivedByUserId?: number | null;

  @Expose() name: string;
  @Expose() slug: string;
  @Expose() description?: string | null;
  @Expose() meta?: string | null;

  @Type(() => Date)
  lastActivityAt?: Date;
  @Type(() => Date)
  createdAt?: Date;
  @Type(() => Date)
  updatedAt?: Date;
  @Type(() => Date)
  disabledAt?: Date;
  @Type(() => Date)
  deletedAt?: Date;
  @Type(() => Date)
  archivedAt?: Date;

  @Type(() => Location)
  location?: Location | null;
  @Type(() => AccountUser)
  accountUser?: AccountUser | null;
  @Type(() => Role)
  role: Role;
  @Type(() => Subscription)
  subscription?: Subscription | null;
}
