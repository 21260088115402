import { Expose, Type } from 'class-transformer';
import { Authentication } from './Authentication';
import { Account } from './Account';
import { AccountUser } from './AccountUser';
import { Role } from './Role';

class UserMeta {
  weekStart: string;
  timeFormat: string;
}
export class User {
  id: number;
  roleId: number;

  firstName: string;
  lastName: string;
  displayName: string;
  slug: string;
  email: string;
  role: string;
  @Type(() => UserMeta)
  meta?: UserMeta;
  timezone: string;

  @Expose() rate?: number | string | null;

  @Type(() => Date)
  createdAt?: Date;
  @Type(() => Date)
  updatedAt?: Date;
  @Type(() => Account)
  account: Account;
  @Type(() => Role)
  accountRole?: Role[];
  @Type(() => AccountUser)
  accountUser?: AccountUser | null;
  @Type(() => Authentication)
  authentication?: Authentication;
}
