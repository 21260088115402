import React, { useContext, useEffect, useState } from 'react';
import './styles.scss';
import {
  Table,
  Typography,
  Row,
  Tooltip,
  Select,
  InputNumber,
  notification,
  Form,
  Popconfirm,
} from 'antd';
import TitleCell from '../../Cells/GlobalTableCells/TitleCell';
import { Task } from '@app/model/Task';
import { currencyFormatter } from '@app/helpers/Expense/currencyFormatter';
import { Trash, Save, Edit2, ChevronDown, XCircle } from 'react-feather';
import { EditableCellProps, ProjectTaskTableProps, Item } from './types';
import { IDataResult } from '@app/model/IDataResult';
import { IErrorResult } from '@app/model/IErrorResult';
import { upperFirst } from 'lodash';
import { UserContext } from '@app/contexts/UserContext';
const { Text } = Typography;

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  title,
  inputType,
  record,
  keyIndex,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === 'number' ? (
      <InputNumber
        placeholder={currencyFormatter(record?.taskable?.rate)}
        formatter={(value) => currencyFormatter(value as number)}
      />
    ) : (
      <Select
        showSearch
        style={{ minWidth: 300 }}
        optionFilterProp={`children`}
        placeholder={'Assign a frequency...'}
        filterOption={(input, option) =>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        suffixIcon={
          <ChevronDown
            size={16}
            className={'feather-icon feather-icon-select'}
          />
        }
        options={['Hourly', 'Daily', 'Weekly', 'Monthly', 'Yearly'].map((e) => {
          return { value: e.toLowerCase(), label: e };
        })}
      />
    );

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={keyIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const ProjectTaskTable = ({
  tasks,
  onRemove,
  project,
  setTasks,
}: ProjectTaskTableProps) => {
  const [api, contextHolder] = notification.useNotification();
  const { api: API } = useContext(UserContext);
  const [editingKey, setEditingKey] = useState<number>(0);
  const [form] = Form.useForm();

  useEffect(() => {
    return () => {
      setEditingKey(0);
    };
  }, []);

  useEffect((): void => {
    if (tasks[tasks.length - 1] instanceof Task) {
      setEditingKey(tasks[tasks.length - 1].id);
    }
  }, [tasks]);

  const isEditing = (record: Task) => {
    return record.id === editingKey;
  };

  const edit = (record: Partial<Task> & { key: React.Key }) => {
    form.setFieldsValue({ rate: '', rateFrequency: '', ...record.taskable });
    setEditingKey(record.id as number);
  };

  const cancel = (key: number) => {
    const index = tasks.findIndex((item) => key === item.id);
    if (
      tasks[index] instanceof Task &&
      confirm('Without saving this task will be removed. Are you sure?')
    ) {
      const updateTasks = [...tasks];
      updateTasks.pop();
      setTasks(updateTasks);
    }
    setEditingKey(0);
  };

  const save = async (key: number) => {
    const row = (await form.validateFields()) as Item;
    const index = tasks.findIndex((item) => key === item.id);
    const record = tasks[index];

    API.query(`api/v2/project/${project?.id}/tasks`, 'PUT', {
      task: record.id,
      rate_frequency: row.rateFrequency,
      rate: row.rate,
    })
      .then((result: IDataResult) => {
        api.success({
          message: 'Success',
          description: 'The task has been updated',
        });
        setTasks(result.data as Task[]);
        setEditingKey(0);
      })
      .catch((error: IErrorResult) => {
        api.error({
          message: 'Error',
          description: 'The task could not be update',
        });
        console.error(error.message);
      });
  };

  const removeTask = async (task: Task) => {
    onRemove(task).then(() => {
      const _selectedTasks = tasks.filter(
        (selectedTask) => selectedTask.id !== task.id,
      );
      setTasks(_selectedTasks);
    });
  };

  const columns = [
    {
      title: <TitleCell title={'Task'} />,
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: <TitleCell title={'Rate'} />,
      dataIndex: ['taskable', 'rate'],
      key: 'rate',
      width: 200,
      editable: true,
      render: (_: any, record: Task) => {
        return <Text>{currencyFormatter(record.taskable?.rate)}</Text>;
      },
    },
    {
      title: <TitleCell title={'Frequency'} />,
      dataIndex: ['taskable', 'rateFrequency'],
      key: 'rateFrequency',
      width: 300,
      editable: true,
      render: (_: any, record: Task) => {
        return (
          <Text>{upperFirst(record.taskable?.rateFrequency as string)}</Text>
        );
      },
    },
    {
      title: <TitleCell title={'Actions'} alignItems={'flex-end'} />,
      dataIndex: 'action',
      key: 'action',
      render: (_: any, record: Task) => {
        const editable = isEditing(record);
        return editable ? (
          <Row justify={'end'}>
            <Save
              onClick={async () => {
                await save(record.id);
              }}
              className={'feather-icon feather-icon-danger'}
              size={14}
              style={{ marginRight: '10px' }}
            />
            <XCircle
              onClick={() => {
                cancel(record.id);
              }}
              className={'feather-icon feather-icon-danger'}
              size={14}
            />
          </Row>
        ) : (
          <Row justify={'end'}>
            <Tooltip title={`Edit ${record.name}.`}>
              <Edit2
                onClick={() => {
                  if (editingKey === 0) {
                    edit(record);
                  }
                }}
                style={{ marginRight: '10px' }}
                className={'feather-icon feather-icon-danger'}
                size={14}
              />
            </Tooltip>
            <Tooltip title={`Remove ${record.name} from this project.`}>
              <Popconfirm
                title={'Delete task'}
                onConfirm={async () => await removeTask(record)}
              >
                <Trash
                  className={'feather-icon feather-icon-danger'}
                  size={14}
                />
              </Popconfirm>
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: Task) => ({
        record,
        inputType: col.key === 'rate' ? 'number' : 'select',
        dataIndex: col.dataIndex,
        title: col.title,
        keyIndex: col.key,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <>
      {contextHolder}
      <Form form={form}>
        <Table<Task>
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          columns={mergedColumns}
          dataSource={tasks}
          pagination={false}
          size={'middle'}
          rowClassName={'task-row-spacing'}
        />
      </Form>
    </>
  );
};

export default ProjectTaskTable;
