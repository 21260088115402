import { Divider, Checkbox, Row, Col } from 'antd';
import { Check } from 'react-feather';

function Notifications(props: any) {
  return (
    <div>
      <h2>Notification Settings</h2>
      <Divider></Divider>
      <div>
        <h2 style={{ marginBottom: '1.5rem' }}>Email Notifications</h2>
        <Row>
          <Checkbox className="notification-checkbox">
            Send me news and updates about HourHand
          </Checkbox>
        </Row>

        <Row>
          <Checkbox className="notification-checkbox">
            Send me weekly time entry updates and reminders
          </Checkbox>
        </Row>

        <Row>
          <Checkbox className="notification-checkbox">
            Inform me when a project exceedes 100% of its Budget
          </Checkbox>
        </Row>

        <Row>
          <Checkbox className="notification-checkbox">
            Tell me about long-running (8+ hours) timers
          </Checkbox>
        </Row>
      </div>
    </div>
  );
}

export default Notifications;
