import React, { useContext } from 'react';
import {
  Form,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Typography,
  Divider,
  notification,
} from 'antd';
import { NavLink, useHistory } from 'react-router-dom';
import { UserContext } from '@app/contexts/UserContext';
import { User } from '@app/model/User';
import { plainToClass } from 'class-transformer';
import { Forms } from '@app/helpers/Constants/forms';
import AuthWrapper from '@app/view/components/Views/AuthWrapper';
import { IDataResult } from '@app/model/IDataResult';
const { Text } = Typography;

export interface ICredentials {
  email: string;
  password: string;
}

function Login() {
  const history = useHistory();
  const { loginUser, api: API, darkMode } = useContext(UserContext);
  const [api, contextHolder] = notification.useNotification();

  const handleSubmit = async (values: any) => {
    const credentials: ICredentials = values;
    API.login(credentials)
      .then((result: IDataResult) => {
        if (result.data) {
          const user: User = plainToClass(User, result.data);
          loginUser(user);
          history.push('/select-account');
        }
      })
      .catch((e) => {
        api.error({
          message: 'Error',
          description: e.message,
        });
      });
  };

  const renderFooter = () => (
    <NavLink exact to="/forgot-password">
      <Button block type={`link`} style={{ marginTop: 20 }}>
        Forgot password?
      </Button>
    </NavLink>
  );

  return (
    <>
      {contextHolder}

      <AuthWrapper footer={renderFooter()}>
        <Row justify={'center'}>
          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              alt="Logo"
              src={
                darkMode
                  ? '/assets/images/logo-2.svg'
                  : '/assets/images/logo-1.svg'
              }
              style={{
                width: 200,
                marginBottom: 40,
              }}
            />
          </Col>
          <Col span={24}>
            <Form
              style={{ paddingBottom: 0 }}
              name={Forms.LoginForm}
              initialValues={{
                remember: true,
              }}
              onFinish={handleSubmit}
            >
              <Form.Item
                name={`email`}
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'Please input a valid email address.',
                    validateTrigger: 'onSubmit',
                  },
                  {
                    min: 5,
                    message: 'Email must be minimum of 5 characters.',
                    validateTrigger: 'onSubmit',
                  },
                ]}
              >
                <Input placeholder={'Email address'} />
              </Form.Item>
              <Form.Item
                className={'custom-password-input'}
                name={`password`}
                rules={[
                  {
                    required: true,
                    message: 'Please input your password.',
                    validateTrigger: 'onSubmit',
                  },
                ]}
              >
                <Input.Password placeholder={'Password'} />
              </Form.Item>
              <Form.Item>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  block
                  type={`primary`}
                  htmlType={`submit`}
                  form={Forms.LoginForm}
                >
                  Log in
                </Button>
              </Form.Item>
              {/*<Row align={'middle'} justify={'center'}>
              <Col span={10}>
                <Divider />
              </Col>
              <Col
                span={4}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Text
                  type={'secondary'}
                  style={{ textAlign: 'center', fontSize: 12 }}
                >
                  Or
                </Text>
              </Col>
              <Col span={10}>
                <Divider />
              </Col>
            </Row>
            <Form.Item style={{ marginBottom: 0 }}>
              <NavLink exact to="/registration">
                <Button block type={`ghost`}>
                  Sign up
                </Button>
              </NavLink>
            </Form.Item>*/}
            </Form>
          </Col>
        </Row>
      </AuthWrapper>
    </>
  );
}

export default Login;
