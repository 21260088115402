import React, { useContext, useEffect, useState } from 'react';
import { Tabs } from 'antd';
import Wrapper from '@app/view/components/Views/Wrapper';
import useWindowDimensions from '@app/helpers/Hooks/useWindowDimensions';
import ProjectDetailsTab from '../ProjectDetailsTab';
import ProjectDocumentsTab from '../ProjectDocumentsTab';
import ProjectUsersTab from '../ProjectUsersTab';
import ProjectTasksTab from '../ProjectTasksTab';
import ProjectBudgetTab from '../ProjectBudgetTab';
import { Project } from '@app/model/Project';
import { useParams } from 'react-router-dom';
import { IDataResult } from '@app/model/IDataResult';
import { UserContext } from '@app/contexts/UserContext';

type ParamsType = {
  id?: string;
};

function ProjectPage() {
  const [project, setProject] = useState<Project>();
  const { width } = useWindowDimensions();
  const params = useParams<ParamsType>();
  const { api: API } = useContext(UserContext);

  const onSave = (project: Project | null | undefined) => {
    project && setProject(project);
  };

  useEffect(() => {
    if (typeof params.id !== 'undefined') {
      API.get(`api/v2/project/${params.id}`).then((result: IDataResult) => {
        setProject(result.data);
      });
    }
  }, []);

  return (
    <Wrapper
      title={`Edit  Project`}
      subtitle={
        project
          ? `${project.name} ${
              typeof project.client !== 'undefined'
                ? `• ${project.client?.name}`
                : ``
            }`
          : `Create a project to add budgets, tasks, roles, and documents.`
      }
    >
      <Tabs
        tabPosition={width < 1024 ? 'top' : 'left'}
        style={{ minHeight: '50vh' }}
        defaultActiveKey={'1'}
        items={[
          {
            key: '1',
            label: 'Details',
            children: (
              <ProjectDetailsTab
                onSave={onSave}
                project={project}
                setProject={setProject}
              />
            ),
          },
          {
            key: '2',
            label: 'Budget',
            disabled: !project?.id,
            children: (
              <ProjectBudgetTab project={project} setProject={setProject} />
            ),
          },
          {
            key: '3',
            label: 'Tasks',
            disabled: !project?.id,
            children: <ProjectTasksTab project={project} />,
          },
          {
            key: '4',
            label: 'Users',
            disabled: !project?.id,
            children: <ProjectUsersTab project={project} />,
          },
          {
            key: '5',
            label: 'Documents',
            disabled: !project?.id,
            children: <ProjectDocumentsTab project={project} />,
          },
        ]}
      />
    </Wrapper>
  );
}

export default ProjectPage;
