import { User } from '@app/model/User';
import { Authorization, Views } from '@app/helpers/Auth/types';
import { addDays } from 'date-fns';

// Todo: Add notation
export default class UserManager {
  private user: User;

  private isLoggedIn: boolean;

  private userToken: string;

  private accountId: string;

  private sessionKey = '_hh_ud';

  private accountKey = '_hh_acc_d';

  constructor() {
    this.getUserData();
    this.getAccountInformation();
  }

  private getUserData() {
    const user = this.getCookie(this.sessionKey);

    if (user) {
      this.userToken = user;

      const sessionData = sessionStorage.getItem(this.sessionKey);
      if (sessionData) {
        this.user = JSON.parse(sessionData);
      }
    } else {
      this.isLoggedIn = false;
    }
  }
  private getAccountInformation() {
    const accountID = this.getCookie(this.accountKey);
    if (accountID) {
      this.accountId = accountID;
    }
  }

  getUser(): User {
    return this.user;
  }

  getSessionToken(): string {
    return this.userToken;
  }

  getAccountId(): string {
    return this.accountId;
  }

  isUserLoggedIn(): boolean {
    return this.isLoggedIn;
  }

  hasUserToken(): boolean {
    return typeof this.userToken !== 'undefined';
  }

  hasAccountId(): boolean {
    return typeof this.accountId !== 'undefined';
  }
  updateUser(user: User): void {
    this.user = user;
    sessionStorage.setItem(this.sessionKey, JSON.stringify(user));
  }

  clearUser(): void {
    sessionStorage.removeItem(this.sessionKey);
  }

  loginUser(user: User) {
    this.clearUser();
    this.updateUser(user);

    if (
      typeof this.userToken === 'undefined' &&
      typeof user.authentication !== 'undefined'
    ) {
      this.userToken = user?.authentication.accessToken as string;
    }
    this.isLoggedIn = true;

    this.setCookie(this.sessionKey, this.userToken);
  }

  logoutUser() {
    this.clearUser();
    this.isLoggedIn = false;
  }

  isAuthorized(view: Views) {
    return Authorization[view].includes(this.user.account.role.name);
  }

  getCookie(type: string): string | null {
    const cookies = document.cookie
      .split(';')
      .map((e) => {
        const data = e.split('=').map((i) => i.trim());
        const payload: { [key: string]: string } = {};
        payload[data[0]] = data[1];

        return payload;
      })
      .reduce((obj, item) => {
        const key = Object.keys(item);
        obj[key[0]] = item[key[0]];
        return obj;
      });

    return cookies[type] || null;
  }

  setCookie(name: string, value: string): void {
    const date = addDays(new Date(), 399);
    document.cookie = `${name}=${value}; expires=${date}; path=/`;
  }

  getAccountKey(): string {
    return this.accountKey;
  }
}
