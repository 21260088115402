import { Expose, Type } from 'class-transformer';

export class Taskable {
  @Expose() id: number;
  @Expose() accountId: number;
  @Expose() createdByUserId: number;
  @Expose() taskId: number;
  @Expose() taskableId: number;
  @Expose() taskableType: string;
  @Expose() updatedByUserId: number;

  @Expose() isBillable: boolean;
  @Expose() rate: number | null;
  @Expose() rateFrequency: string | null;
  @Expose() meta?: string | null;

  @Type(() => Date)
  createdAt?: Date;
  @Type(() => Date)
  updatedAt?: Date;
}
