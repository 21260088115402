import {
  Divider,
  Input,
  Col,
  Row,
  Select,
  Button,
  Form,
  Spin,
  notification,
} from 'antd';
import AvatarUpload from './AvatarUpload';
import { useContext, useEffect, useState } from 'react';
import { UserContext } from '@app/contexts/UserContext';
import { APIAssembly, Methods } from '@app/data/API';
import { IDataResult } from '@app/model/IDataResult';
import { plainToClass } from 'class-transformer';
import { User } from '@app/model/User';
import { merge } from 'lodash';

interface AccountInterface {
  first_name: string;
  last_name: string;
  email: string;
  timezone: string;
  time_format: string;
  week_start: string;
}

function AccountInfo() {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();
  const { user, api: API } = useContext(UserContext);
  const [form] = Form.useForm();

  const userData = user.getUser();
  const userRole = userData.account?.role.name?.toLowerCase();

  useEffect((): void => {
    form.setFieldsValue({
      first_name: userData.firstName,
      last_name: userData.lastName,
      email: userData.email,
      timezone: userData.timezone,
      time_format: userData.meta?.timeFormat,
      week_start: userData?.meta?.weekStart,
    });
  }, []);

  function renderPermissionText(data: any) {
    switch (data) {
      case 'admin':
        return (
          <p>
            You are an Admin. You can access admin dashboards, add and remove
            users from projects, and access all project reports.
          </p>
        );
      case 'manager':
        return (
          <p>
            You are a Manager. You can add and remove users from projects that
            you have been assigned access to.
          </p>
        );
      case 'user':
        return (
          <p>
            You are a standard user. You can track time and expenses and report
            your own data. You do not by default have access to project report
            data
          </p>
        );
    }
  }

  function onFinish(values: AccountInterface) {
    setIsSaving(true);

    const assembly: APIAssembly = {
      endpoint: `api/v2/user/${userData.id}`,
      method: Methods.PUT,
      body: values,
    };

    API.query(assembly.endpoint, assembly.method, assembly.body)
      .then((results: IDataResult) => {
        setIsSaving(false);

        const result = plainToClass(User, results.data);

        const updatedUser = merge({}, userData, result);
        user.updateUser(updatedUser);

        api.success({
          message: 'Success',
          description: `User Updated`,
        });
      })
      .catch((error: any) => {
        setIsSaving(false);
        api.error({
          message: 'Error',
          description: `The user couldn't be updated`,
        });
        console.error(error);
      });
  }

  return (
    <>
      {contextHolder}

      <div>
        <Spin spinning={isSaving}>
          <Form
            name={'account'}
            onFinish={onFinish}
            form={form}
            scrollToFirstError={true}
          >
            <Row align={'middle'}>
              <Col span={12}>
                <h2 style={{ marginBottom: '0' }}>Basic Info</h2>
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                <Button type={'ghost'} htmlType={'submit'}>
                  Update Account
                </Button>
              </Col>
            </Row>
            <Divider></Divider>
            <Input.Group size="large" style={{ marginBottom: '3rem' }}>
              <Row gutter={8}>
                <Col span={5}>
                  <h4>First Name</h4>
                  <Form.Item
                    name={'first_name'}
                    key={1}
                    rules={[
                      {
                        type: 'string',
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <h4>Last Name</h4>
                  <Form.Item
                    name={'last_name'}
                    key={2}
                    rules={[
                      {
                        type: 'string',
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <h4>Email</h4>
                  <Form.Item
                    name={'email'}
                    key={3}
                    rules={[
                      {
                        type: 'string',
                        required: true,
                      },
                    ]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Input.Group>
            <h2>Permissions</h2>
            <Divider></Divider>
            {renderPermissionText(userRole)}
            <h2 style={{ marginTop: '3rem' }}>Time</h2>
            <Divider></Divider>

            <Row gutter={8}>
              <Col span={10}>
                <h4>Timezone</h4>
                <Form.Item
                  name={'timezone'}
                  key={4}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    showSearch
                    style={{ width: 350 }}
                    placeholder="Select a Timezone"
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <option value="Etc/GMT+12">
                      (GMT-12:00) International Date Line West
                    </option>
                    <option value="Pacific/Midway">
                      (GMT-11:00) Midway Island, Samoa
                    </option>
                    <option value="Pacific/Honolulu">(GMT-10:00) Hawaii</option>
                    <option value="US/Alaska">(GMT-09:00) Alaska</option>
                    <option value="America/Los_Angeles">
                      (GMT-08:00) Pacific Time (US & Canada)
                    </option>
                    <option value="America/Tijuana">
                      (GMT-08:00) Tijuana, Baja California
                    </option>
                    <option value="US/Arizona">(GMT-07:00) Arizona</option>
                    <option value="America/Chihuahua">
                      (GMT-07:00) Chihuahua, La Paz, Mazatlan
                    </option>
                    <option value="US/Mountain">
                      (GMT-07:00) Mountain Time (US & Canada)
                    </option>
                    <option value="America/Managua">
                      (GMT-06:00) Central America
                    </option>
                    <option value="US/Central">
                      (GMT-06:00) Central Time (US & Canada)
                    </option>
                    <option value="America/Mexico_City">
                      (GMT-06:00) Guadalajara, Mexico City, Monterrey
                    </option>
                    <option value="Canada/Saskatchewan">
                      (GMT-06:00) Saskatchewan
                    </option>
                    <option value="America/Bogota">
                      (GMT-05:00) Bogota, Lima, Quito, Rio Branco
                    </option>
                    <option value="US/Eastern">
                      (GMT-05:00) Eastern Time (US & Canada)
                    </option>
                    <option value="US/East-Indiana">
                      (GMT-05:00) Indiana (East)
                    </option>
                    <option value="Canada/Atlantic">
                      (GMT-04:00) Atlantic Time (Canada)
                    </option>
                    <option value="America/Caracas">
                      (GMT-04:00) Caracas, La Paz
                    </option>
                    <option value="America/Manaus">(GMT-04:00) Manaus</option>
                    <option value="America/Santiago">
                      (GMT-04:00) Santiago
                    </option>
                    <option value="Canada/Newfoundland">
                      (GMT-03:30) Newfoundland
                    </option>
                    <option value="America/Sao_Paulo">
                      (GMT-03:00) Brasilia
                    </option>
                    <option value="America/Argentina/Buenos_Aires">
                      (GMT-03:00) Buenos Aires, Georgetown
                    </option>
                    <option value="America/Godthab">
                      (GMT-03:00) Greenland
                    </option>
                    <option value="America/Montevideo">
                      (GMT-03:00) Montevideo
                    </option>
                    <option value="America/Noronha">
                      (GMT-02:00) Mid-Atlantic
                    </option>
                    <option value="Atlantic/Cape_Verde">
                      (GMT-01:00) Cape Verde Is.
                    </option>
                    <option value="Atlantic/Azores">(GMT-01:00) Azores</option>
                    <option value="Africa/Casablanca">
                      (GMT+00:00) Casablanca, Monrovia, Reykjavik
                    </option>
                    <option value="Etc/Greenwich">
                      (GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh,
                      Lisbon, London
                    </option>
                    <option value="Europe/Amsterdam">
                      (GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm,
                      Vienna
                    </option>
                    <option value="Europe/Belgrade">
                      (GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana,
                      Prague
                    </option>
                    <option value="Europe/Brussels">
                      (GMT+01:00) Brussels, Copenhagen, Madrid, Paris
                    </option>
                    <option value="Europe/Sarajevo">
                      (GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb
                    </option>
                    <option value="Africa/Lagos">
                      (GMT+01:00) West Central Africa
                    </option>
                    <option value="Asia/Amman">(GMT+02:00) Amman</option>
                    <option value="Europe/Athens">
                      (GMT+02:00) Athens, Bucharest, Istanbul
                    </option>
                    <option value="Asia/Beirut">(GMT+02:00) Beirut</option>
                    <option value="Africa/Cairo">(GMT+02:00) Cairo</option>
                    <option value="Africa/Harare">
                      (GMT+02:00) Harare, Pretoria
                    </option>
                    <option value="Europe/Helsinki">
                      (GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius
                    </option>
                    <option value="Asia/Jerusalem">
                      (GMT+02:00) Jerusalem
                    </option>
                    <option value="Europe/Minsk">(GMT+02:00) Minsk</option>
                    <option value="Africa/Windhoek">
                      (GMT+02:00) Windhoek
                    </option>
                    <option value="Asia/Kuwait">
                      (GMT+03:00) Kuwait, Riyadh, Baghdad
                    </option>
                    <option value="Europe/Moscow">
                      (GMT+03:00) Moscow, St. Petersburg, Volgograd
                    </option>
                    <option value="Africa/Nairobi">(GMT+03:00) Nairobi</option>
                    <option value="Asia/Tbilisi">(GMT+03:00) Tbilisi</option>
                    <option value="Asia/Tehran">(GMT+03:30) Tehran</option>
                    <option value="Asia/Muscat">
                      (GMT+04:00) Abu Dhabi, Muscat
                    </option>
                    <option value="Asia/Baku">(GMT+04:00) Baku</option>
                    <option value="Asia/Yerevan">(GMT+04:00) Yerevan</option>
                    <option value="Asia/Kabul">(GMT+04:30) Kabul</option>
                    <option value="Asia/Yekaterinburg">
                      (GMT+05:00) Yekaterinburg
                    </option>
                    <option value="Asia/Karachi">
                      (GMT+05:00) Islamabad, Karachi, Tashkent
                    </option>
                    <option value="Asia/Calcutta">
                      (GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi
                    </option>
                    <option value="Asia/Katmandu">(GMT+05:45) Kathmandu</option>
                    <option value="Asia/Almaty">
                      (GMT+06:00) Almaty, Novosibirsk
                    </option>
                    <option value="Asia/Dhaka">
                      (GMT+06:00) Astana, Dhaka
                    </option>
                    <option value="Asia/Rangoon">
                      (GMT+06:30) Yangon (Rangoon)
                    </option>
                    <option value="Asia/Bangkok">
                      (GMT+07:00) Bangkok, Hanoi, Jakarta
                    </option>
                    <option value="Asia/Krasnoyarsk">
                      (GMT+07:00) Krasnoyarsk
                    </option>
                    <option value="Asia/Hong_Kong">
                      (GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi
                    </option>
                    <option value="Asia/Kuala_Lumpur">
                      (GMT+08:00) Kuala Lumpur, Singapore
                    </option>
                    <option value="Asia/Irkutsk">
                      (GMT+08:00) Irkutsk, Ulaan Bataar
                    </option>
                    <option value="Australia/Perth">(GMT+08:00) Perth</option>
                    <option value="Asia/Taipei">(GMT+08:00) Taipei</option>
                    <option value="Asia/Tokyo">
                      (GMT+09:00) Osaka, Sapporo, Tokyo
                    </option>
                    <option value="Asia/Seoul">(GMT+09:00) Seoul</option>
                    <option value="Asia/Yakutsk">(GMT+09:00) Yakutsk</option>
                    <option value="Australia/Adelaide">
                      (GMT+09:30) Adelaide
                    </option>
                    <option value="Australia/Darwin">(GMT+09:30) Darwin</option>
                    <option value="Australia/Brisbane">
                      (GMT+10:00) Brisbane
                    </option>
                    <option value="Australia/Canberra">
                      (GMT+10:00) Canberra, Melbourne, Sydney
                    </option>
                    <option value="Australia/Hobart">(GMT+10:00) Hobart</option>
                    <option value="Pacific/Guam">
                      (GMT+10:00) Guam, Port Moresby
                    </option>
                    <option value="Asia/Vladivostok">
                      (GMT+10:00) Vladivostok
                    </option>
                    <option value="Asia/Magadan">
                      (GMT+11:00) Magadan, Solomon Is., New Caledonia
                    </option>
                    <option value="Pacific/Auckland">
                      (GMT+12:00) Auckland, Wellington
                    </option>
                    <option value="Pacific/Fiji">
                      (GMT+12:00) Fiji, Kamchatka, Marshall Is.
                    </option>
                    <option value="Pacific/Tongatapu">
                      (GMT+13:00) Nuku'alofa
                    </option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <h4>Time Format</h4>
                <Form.Item name={'time_format'} key={5}>
                  <Select
                    style={{ width: 200 }}
                    placeholder="Select a Time Format"
                  >
                    <option value="12">12-Hour</option>
                    <option value="24">24-Hour</option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={8}>
                <h4 style={{ marginTop: '2rem' }}>Week Start</h4>
                <Form.Item name={'week_start'} key={6}>
                  <Select style={{ width: 200 }} defaultValue="sunday">
                    <option value="sunday">Sunday</option>
                    <option value="monday">Monday</option>
                    <option value="tuesday">Tuesday</option>
                    <option value="wednesday">Wednesday</option>
                    <option value="thursday">Thursday</option>
                    <option value="friday">Friday</option>
                    <option value="saturday">Saturday</option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <h2 style={{ marginTop: '3rem' }}>Photo</h2>
            <Divider></Divider>
          </Form>
          <AvatarUpload />
        </Spin>
      </div>
    </>
  );
}

export default AccountInfo;
