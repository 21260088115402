import React from 'react';
import { Button, Col, Form, Row } from 'antd';

type FormFooterProps = {
  form: string;
  isSaved?: boolean | undefined;
  onReset?: () => void;
};

export const FormFooter = ({ form, isSaved, onReset }: FormFooterProps) => {
  return (
    <Row justify={'end'} gutter={10} style={{ marginTop: 15 }}>
      {!isSaved && (
        <Col>
          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              form={form}
              type={'ghost'}
              onClick={() => onReset && onReset()}
            >
              Clear
            </Button>
          </Form.Item>
        </Col>
      )}
      <Col>
        <Form.Item style={{ marginBottom: 0 }}>
          <Button
            form={form}
            type={'primary'}
            key={'submit'}
            htmlType={'submit'}
          >
            {isSaved ? 'Update' : 'Save'}
          </Button>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default FormFooter;
