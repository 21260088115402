import React, { useContext, useEffect, useState } from 'react';
import Wrapper from '@app/view/components/Views/Wrapper';
import { IDataResult } from '@app/model/IDataResult';
import { Client } from '@app/model/Client';
import AddButton from '@app/view/components/Buttons/AddButton';
import { plainToClass } from 'class-transformer';
import { Divider, Typography } from 'antd';
import ClientCard from '@app/view/components/Cards/ClientCard';
import { UserContext } from '@app/contexts/UserContext';
const { Title } = Typography;

function ClientsPage() {
  const [clients, setClients] = useState<Client[]>([]);
  const { user, api: API } = useContext(UserContext);

  const userData = user.getUser();

  useEffect(() => {
    getClients();
  }, []);

  const getClients = () => {
    API.get(`api/v2/account/${userData.account?.id}/clients`)
      .then((result: IDataResult) => {
        const _clients: any = plainToClass(Client, result.data);
        setClients(_clients);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  return (
    <Wrapper
      primaryButton={<AddButton link={'/clients/new'} title={'New Client'} />}
      title={'Clients'}
    >
      <Title level={4} style={{ marginBottom: 0, fontWeight: 500 }}>
        Active Clients
      </Title>

      <Divider style={{ marginTop: 5, marginBottom: 25 }} />

      {clients &&
        clients
          .filter((client: Client) => {
            return !client.isArchived;
          })
          .map((client: Client) => {
            return <ClientCard client={client} />;
          })}
    </Wrapper>
  );
}

export default ClientsPage;
