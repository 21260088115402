import { Expose, Type } from 'class-transformer';
import { User } from './User';
import { Account } from './Account';
import { Role } from './Role';
import { Contactable } from './Contactable';

export class AccountUser {
  @Expose() id: number;
  @Expose() userId?: number | null;
  @Expose() accountId?: number | null;
  @Expose() roleId?: number | null;
  @Expose() contactableId?: number | null;

  @Expose() isEnabled?: number | null;
  @Expose() rate?: number | null;
  @Expose() meta?: string | null;

  @Type(() => Date)
  createdAt?: Date;
  @Type(() => Date)
  updatedAt?: Date;
  @Type(() => Date)
  lastActivityAt?: Date;
  @Type(() => Date)
  disabledAt?: Date;

  @Type(() => Account)
  account?: Account | null;
  @Type(() => Contactable)
  contact?: Contactable | null;
  @Type(() => Role)
  role?: Role | null;
  @Type(() => User)
  user?: User | null;
}
