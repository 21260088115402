import { createTheming } from '@callstack/react-theme-provider';
const { ThemeProvider, useTheme } = createTheming({
  dark: {
    primary: 'rgb(10, 132, 255)',
    secondary: 'rgb(152,16,202)',
    warning: 'rgb(245,137,8)',
    danger: 'rgb(203,5,27)',
    backgroundColor: 'rgb(12, 16, 21)',
    backgroundAlpha: 'rgba(4, 4, 6, 0.5)',
    card: 'rgb(18, 18, 18)',
    text: 'rgb(229, 229, 231)',
    subtext: 'rgb(160, 160, 162)',
    separator: 'rgb(24, 24, 24)',
    border: 'rgb(36, 36, 36)',
    composer: 'rgb(100, 100, 100)',
    highlight: 'rgb(199, 198, 203)',
    notification: 'rgb(206,15,36)',
    transparent: 'rgba(0,0,0,0)',
    inverted: 'rgb(255,255,255)',
    invertedAlpha: 'rgba(255,255,255, 0.5)',
  },
  light: {
    primaryColor: `rgba(0, 105, 211, 1)`,
    successColor: `rgba(64, 182, 4, 1)`,
    warningColor: `rgba(239, 162, 8, 1)`,
    errorColor: `rgba(245, 34, 45, 1)`,

    headingColor: `rgba(0, 0, 0, 1)`,
    textColor: `rgba(0, 0, 0, 1)`,
    textColorSecondary: `rgba(112, 116, 126, 1)`,

    backgroundColor: `rgba(247, 247, 250, 1)`,
    borderColorBase: `rgba(220,220,224,1)`,

    transparent: 'rgba(0,0,0,0)',

    primary: '#0069D3',
    secondary: 'rgb(152,16,202)',
    success: '#00BF58',
    warning: 'rgb(245,137,8)',
    danger: 'rgb(203,5,27)',
    background: 'rgb(244, 244, 248)',
    backgroundAlpha: 'rgba(244, 244, 248, 0.5)',
    card: 'rgb(255, 255, 255)',
    text: 'rgb(0, 0, 0)',
    subtext: 'rgba(112,116,126,1)',
    disabled: 'rgb(102, 102, 102)',
    separator: 'rgb(210, 210, 210)',
    border: 'rgb(230, 230, 234)',
    composer: 'rgb(160, 160, 160)',
    highlight: 'rgb(199, 198, 203)',
    notification: 'rgb(206,15,36)',
    inverted: 'rgb(0,0,0)',
    invertedAlpha: 'rgba(0,0,0, 0.5)',
  },
});
export { ThemeProvider, useTheme };
