export enum Forms {
  LoginForm = 'LoginForm',
  SignupForm = 'SignupForm',
  NewProjectTaskForm = 'NewProjectTaskForm',
  NewUserInviteForm = 'NewUserInviteForm',
  ProjectDetailsForm = 'ProjectDetailsForm',
  ProjectDocumentsForm = 'ProjectDocumentsForm',
  ProjectBudgetForm = 'ProjectBudgetForm',
  ProjectTasksForm = 'ProjectTasksForm',
  ProjectRolesForm = 'ProjectRolesForm',
  ClientOverviewForm = 'ClientOverviewForm',
  CreateLocationForm = 'CreateLocationForm',
  CreateContactForm = 'CreateContactForm',
  ReportsForm = 'FormReport',
}
