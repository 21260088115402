import React, { useEffect, useState } from 'react';
import {
  Form,
  message,
  Spin,
  Col,
  Button,
  Row,
  Space,
  Upload,
  Divider,
} from 'antd';
import TabSnippet from '@app/view/components/Text/TabSnippet';
import { Forms } from '@app/helpers/Constants/forms';
import { validateMessages } from '@app/helpers/Validator/validateMessages';
import FormFooter from '@app/view/components/Form/FormFooter';
import { UploadCloud } from 'react-feather';
import { Project } from '@app/model/Project';

export enum NewProjectDocumentsTabFormValues {
  serviceAgreement = 'service_agreement',
}

type ProjectDocumentsTabProps = {
  project?: Project;
};

export const ProjectDocumentsTab = ({ project }: ProjectDocumentsTabProps) => {
  const [isSaved, setIsSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [form] = Form.useForm();
  const section = `project`;
  const values = NewProjectDocumentsTabFormValues;

  const onFinish = (values: any) => {
    setIsLoading(true);
  };

  const onFinishFailed = () => {
    setIsLoading(false);
    message.error('Unable to save').then();
  };

  const onReset = () => {
    form.resetFields();
  };

  useEffect(() => {
    setFileList([
      {
        uid: '-1',
        name: 'Proposal & Terms of Agreement.pdf',
        status: 'done',
        url: 'http://www.baidu.com/xxx.png',
      },
    ]);
  }, []);

  const handleUpload = (info: any) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-2);
    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });

    setFileList(fileList);
  };

  return (
    <Form
      autoComplete={'on'}
      autoCapitalize={'off'}
      form={form}
      layout={'vertical'}
      id={Forms.ProjectDocumentsForm}
      name={Forms.ProjectDocumentsForm}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      scrollToFirstError={true}
      validateMessages={validateMessages}
      wrapperCol={{ span: 24 }}
    >
      <TabSnippet
        title={'Details'}
        subtitle={'Create a project by assigning a client and name.'}
        style={{ marginBottom: 20 }}
      />
      <Spin spinning={isLoading}>
        <Row gutter={15}>
          <Col span={24}>
            <Form.Item
              className={'form-item-description'}
              label={'Linked Documents'}
              name={[section, values.serviceAgreement]}
              fieldKey={[section, values.serviceAgreement]}
              key={1}
            >
              <Space direction={'vertical'} size={10}>
                {/*<Text type={'secondary'}>*/}
                {/*    Link documents that establish the budget, rate, or terms of how the project should be completed.*/}
                {/*</Text>*/}
                <Upload
                  multiple
                  action={'https://www.mocky.io/v2/5cc8019d300000980a055e76'}
                  onChange={handleUpload}
                  fileList={fileList}
                >
                  <Button
                    icon={
                      <UploadCloud
                        size={14}
                        className={'feather-icon feather-icon-button-prefix'}
                      />
                    }
                  >
                    Upload
                  </Button>
                </Upload>
              </Space>
            </Form.Item>
          </Col>
        </Row>

        <Divider className={'divider-spacer'} />

        <FormFooter
          isSaved={isSaved}
          onReset={onReset}
          form={Forms.ProjectDocumentsForm}
        />
      </Spin>
    </Form>
  );
};

export default ProjectDocumentsTab;
