import React from 'react';
import { Table, Typography, Row, Tooltip, Select } from 'antd';
import { Project } from '@app/model/Project';
import TitleCell from '../../Cells/GlobalTableCells/TitleCell';
import { NavLink, useHistory } from 'react-router-dom';
const { Text } = Typography;
const { Option } = Select;

type ProjectTableProps = {
  filteredProjects: Project[] | undefined;
  projects: Project[] | undefined;
  onArchive: (project: Project) => void;
};

export const ProjectTable = ({
  filteredProjects,
  projects,
  onArchive,
}: ProjectTableProps) => {
  const history = useHistory();

  /**
   * OnSelect of Action on the table row, decide where to pass functionality.
   *
   * @param value The Option's value
   * @param record The Project that belongs to that row
   */
  const onSelectAction = (value: any, record: Project) => {
    if (value === 'Archive') {
      onArchive(record);
    }
  };

  const columns = [
    {
      title: <TitleCell title={'Client'} />,
      dataIndex: 'client.name',
      key: 'client.name',
      width: 200,
      sorter: (a: Project, b: Project) =>
        a.client && b.client && a.client.name!.localeCompare(b.client.name!),
      onCell: () => {
        return {
          style: {
            whiteSpace: 'nowrap',
            maxWidth: 200,
          },
        };
      },
      render(_: any, record: Project, index: number) {
        return {
          children: (
            <Text style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
              {record.client?.name}
            </Text>
          ),
        };
      },
    },
    {
      title: <TitleCell title={'Project'} />,
      dataIndex: 'name',
      key: 'name',
      width: 300,
      onCell: () => {
        return {
          style: {
            whiteSpace: 'nowrap',
            maxWidth: 300,
          },
        };
      },
      sorter: (a: Project, b: Project) => a.name?.localeCompare(b.name!),
      render(_: any, record: Project, index: number) {
        return {
          children: (
            <NavLink exact to={`/projects/${record.id}`}>
              <Row className={'row-align-center'}>
                <div
                  className={'project-color-swatch'}
                  style={{ background: 'cyan', marginRight: 8 }}
                />
                <Text style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                  {record.name}
                </Text>
              </Row>
            </NavLink>
          ),
        };
      },
    },
    {
      title: <TitleCell title={'Created At'} />,
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      sorter: (a: Project, b: Project) =>
        new Date(a.createdAt!).getDate() - new Date(b.createdAt!).getDate(),
      render(_: any, record: Project, index: number) {
        return {
          children: (
            <Tooltip title={record.getCreatedAt && record.getCreatedAt()}>
              <Text>
                {record.getCreatedAtDistance && record.getCreatedAtDistance()}
              </Text>
            </Tooltip>
          ),
        };
      },
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      render(_: any, record: Project, index: number) {
        return {
          children: (
            <Row justify={'end'}>
              <Select
                onClick={(event) => event.stopPropagation()}
                onSelect={(value: any) => onSelectAction(value, record)}
                placeholder={'Actions'}
              >
                <Option value={`Archive`} key={`1`}>
                  Archive
                </Option>
                <Option value={`Delete`} key={`2`}>
                  Delete
                </Option>
              </Select>
            </Row>
          ),
        };
      },
    },
  ];

  return (
    <Table<Project>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      columns={columns}
      dataSource={filteredProjects ? filteredProjects : projects}
      pagination={false}
      size={'middle'}
      onRow={(record, rowIndex) => {
        return {
          onClick: (event) => {
            event.stopPropagation();
            history.push(`/projects/${record.id}`);
          },
        };
      }}
    />
  );
};

export default ProjectTable;
