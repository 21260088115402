import React from 'react';
import Wrapper from '@app/view/components/Views/Wrapper';

function SupportPage() {
  return (
    <Wrapper title={'Support'}>
      <></>
    </Wrapper>
  );
}

export default SupportPage;
