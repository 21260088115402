import React, { useState, useEffect, useContext } from 'react';
import { Menu, Dropdown, Button } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { IDataResult } from '@app/model/IDataResult';
import { Account } from '@app/model/Account';
import AccountItem from '@app/view/components/Account/SelectAccount/AccountItem';
import { plainToClass } from 'class-transformer';
import AuthWrapper from '@app/view/components/Views/AuthWrapper';
import { UserContext } from '@app/contexts/UserContext';
import { useHistory } from 'react-router-dom';

function SelectAccountPage() {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const { user, api } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    if (!user.isUserLoggedIn()) {
      history.push('/login');
    }
    getAccounts();
  }, []);

  const getAccounts = () => {
    api
      .get('api/v2/accounts')
      .then((result: IDataResult) => {
        const _accounts: any = plainToClass(Account, result.data);
        setAccounts(_accounts);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const menu = () => (
    <Menu>
      {accounts.map((account: Account) => {
        return <AccountItem account={account} />;
      })}
    </Menu>
  );

  return (
    <AuthWrapper title={'Account'} subtitle={'Select an account to proceed'}>
      <Dropdown trigger={['click']} overlay={menu}>
        <Button style={{ width: '100%' }}>
          Choose Account...
          <DownOutlined />
        </Button>
      </Dropdown>
    </AuthWrapper>
  );
}

export default SelectAccountPage;
