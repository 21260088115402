import { Expose, Type } from 'class-transformer';
import { Location } from './Location';
import { Account } from './Account';

export class Subscription {
  @Expose() id: number | undefined;
  @Expose() accountId?: number | null | undefined;
  @Expose() locationId?: number | null | undefined;

  @Expose() name: string | undefined;
  @Expose() description?: string | null | undefined;
  @Expose() isBudgeted?: number | null | undefined;
  @Expose() meta?: string | null | undefined;

  @Type(() => Date)
  createdAt?: Date | undefined;
  @Type(() => Date)
  updatedAt?: Date | undefined;

  @Type(() => Account)
  account?: Account | null | undefined;
  @Type(() => Location)
  location?: Location | null | undefined;
}
