import React, { useContext, useState } from 'react';
import { Row, Col, Form, Button, Spin, Input, message } from 'antd';
import CustomModal from '../CustomModal';
import { Forms } from '@app/helpers/Constants/forms';
import { APIAssembly, Methods } from '@app/data/API';
import { IDataResult } from '@app/model/IDataResult';
import { IErrorResult } from '@app/model/IErrorResult';
import { UserContext } from '@app/contexts/UserContext';
import { Project } from '@app/model/Project';
const { TextArea } = Input;

interface NewUserInviteModalProps extends React.HTMLAttributes<HTMLElement> {
  project: Project | null | undefined;
  onOk?: () => void;
  onCancel?: () => void;
  visible: boolean;
}

const NewUserInviteModal: React.FC<NewUserInviteModalProps> = ({
  project,
  onOk,
  onCancel,
  visible,
  ...restProps
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isBulkUpload, setIsBulkUpload] = useState<boolean>(false);
  const [form] = Form.useForm();
  const fieldName = 'invite';
  const fieldKey = 0;
  const { user, api: API } = useContext(UserContext);

  const userData = user.getUser();

  const onFinish = (values: any) => {
    setIsLoading(true);

    if (typeof values.invite === `undefined`) {
      onFinishFailed();
      return;
    }
    const body = values.invite;
    body.project_id = project?.id;
    const accountId = userData.account?.id;
    const assembly: APIAssembly = {
      endpoint: `api/v2/account/${accountId}/user/invite`,
      method: Methods.POST,
      body: body,
    };

    API.query(assembly.endpoint, assembly.method, assembly.body)
      .then((result: IDataResult) => {
        setIsLoading(false);
        onReset();
        message.success(`User invited successfully`).then();
        console.log(result);
      })
      .catch((error: IErrorResult) => {
        console.error(error);
      });
  };

  const onFinishFailed = () => {
    setIsLoading(false);
    message.error('Unable to save').then();
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <CustomModal
      {...restProps}
      onOk={() => {
        onCancel && onCancel();
      }}
      onCancel={() => {
        onCancel && onCancel();
      }}
      hasOkButton={false}
      okText={'Done'}
      visible={visible}
      title={`Invite User${isBulkUpload ? 's' : ''}`}
    >
      <Form
        autoComplete={'on'}
        autoCapitalize={'off'}
        form={form}
        layout={'vertical'}
        id={Forms.NewUserInviteForm}
        name={Forms.NewUserInviteForm}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={true}
        style={{ paddingBottom: 0 }}
        wrapperCol={{ span: 24 }}
      >
        <Spin spinning={isLoading}>
          {isBulkUpload ? (
            <Row gutter={15}>
              <Col span={24}>
                <Form.Item
                  required
                  label={'Email Addresses'}
                  name={[fieldName, 'emails']}
                  fieldKey={[fieldKey, 'emails']}
                  key={fieldKey}
                >
                  <TextArea
                    rows={4}
                    placeholder={
                      'Enter email addresses separated by a comma, e.g. hello@gmail.com, goodbye@gmail.com...'
                    }
                    style={{ width: '100%', maxHeight: 80 }}
                  />
                </Form.Item>
              </Col>
            </Row>
          ) : (
            <Row gutter={15}>
              <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
                <Form.Item
                  required
                  label={'First Name'}
                  name={[fieldName, 'first_name']}
                  fieldKey={[fieldKey, 'first_name']}
                  key={fieldKey}
                  rules={[
                    {
                      required: true,
                      type: 'string',
                      message: 'Please enter a first name.',
                      validateTrigger: 'onSubmit',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
                <Form.Item
                  required
                  label={'Last Name'}
                  name={[fieldName, 'last_name']}
                  fieldKey={[fieldKey, 'last_name']}
                  key={fieldKey}
                  rules={[
                    {
                      required: true,
                      type: 'string',
                      message: 'Please enter a last name.',
                      validateTrigger: 'onSubmit',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Form.Item
                  required
                  label={'Email Address'}
                  name={[fieldName, 'email']}
                  fieldKey={[fieldKey, 'email']}
                  key={fieldKey}
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'Please input a valid email address.',
                      validateTrigger: 'onSubmit',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          )}

          <Row justify={'end'} style={{ marginTop: 15 }} gutter={15}>
            <Col
              style={{
                display: 'flex',
                alignSelf: 'center',
                alignItems: 'center',
              }}
            >
              <Button
                type={'link'}
                onClick={() => {
                  setIsBulkUpload(!isBulkUpload);
                }}
              >
                {isBulkUpload ? 'Single User Invite' : 'Bulk User Invitations'}
              </Button>
            </Col>
            <Col>
              <Form.Item style={{ marginBottom: 0 }}>
                <Button
                  form={Forms.NewUserInviteForm}
                  type={'primary'}
                  key={'submit'}
                  htmlType={'submit'}
                >
                  Invite
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Spin>
      </Form>
    </CustomModal>
  );
};

export default NewUserInviteModal;
