import 'reflect-metadata';
import React, { useEffect, useState } from 'react';
import MainNavigation from './view/components/Nav/MainNavigation';
import { ThemeProvider } from '@app/styles/theme';
import { validateMessages } from './helpers/Validator/validateMessages';
import { ConfigProvider, Row, theme, Typography } from 'antd';
import { UserContext } from './contexts/UserContext';
import { User } from './model/User';
import { Account } from './model/Account';
import { API } from './data/API';
import { IDataResult } from './model/IDataResult';
import UserManager from '@app/helpers/Auth/UserManager';
import { plainToClass } from 'class-transformer';
import { IErrorResult } from '@app/model/IErrorResult';
import Loading from '@app/view/components/Loading';

const { Text } = Typography;

const { defaultAlgorithm, darkAlgorithm } = theme;

function App() {
  const [user] = useState<UserManager>(new UserManager());
  const [api] = useState<API>(new API(user));
  const [didSelectAccount, setDidSelectAccount] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const matcher = window.matchMedia('(prefers-color-scheme: dark)');
  const [darkMode, setDarkMode] = useState<boolean>(matcher.matches);

  useEffect(() => {
    if (user.hasUserToken() && user.hasAccountId()) {
      api
        .get('api/v2/me/' + user.getAccountId())
        .then((e: IDataResult) => {
          const userData = plainToClass(User, e.data);
          user.loginUser(userData);

          typeof (userData as User).account !== 'undefined' &&
            setDidSelectAccount(true);

          setIsLoading(false);
        })
        .catch((e: IErrorResult) => {
          user.logoutUser();
          setDidSelectAccount(false);
          setIsLoading(false);
        });
    } else {
      user.logoutUser();
      setDidSelectAccount(false);
      setIsLoading(false);
    }

    matcher.onchange = (): void => setDarkMode(matcher.matches);
  }, []);

  const loginUser = async (data: User) => {
    user.loginUser(data);
  };

  const logoutUser = () => {
    user.logoutUser();
    setDidSelectAccount(false);
  };

  const selectAccount = async (account: Account) => {
    const _user = user.getUser();
    _user.account = account;
    getAccountRoles(account).then((result: IDataResult) => {
      _user.accountRole = result.data;
    });

    user.updateUser(_user);
    setDidSelectAccount(true);
  };

  const userContextValue = {
    user,
    api,
    didSelectAccount,
    loginUser,
    logoutUser,
    selectAccount,
    darkMode,
  };

  const getAccountRoles = async (account: Account) => {
    return api.get(`api/v2/account/${account.id}/roles`);
  };

  const renderEmpty = () => {
    return (
      <Row
        justify={'center'}
        align={'middle'}
        style={{ cursor: 'pointer', minHeight: 120 }}
      >
        <Text type={'secondary'}>No data to display</Text>
      </Row>
    );
  };

  return (
    <ConfigProvider
      renderEmpty={renderEmpty}
      form={{
        validateMessages,
      }}
      theme={{ algorithm: darkMode ? darkAlgorithm : defaultAlgorithm }}
    >
      <ThemeProvider>
        <UserContext.Provider value={userContextValue}>
          <div className="App">
            <Loading isLoading={isLoading}>
              <MainNavigation />
            </Loading>
          </div>
        </UserContext.Provider>
      </ThemeProvider>
    </ConfigProvider>
  );
}

export default App;
