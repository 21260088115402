import React, { useState } from 'react';
import {
  Form,
  message,
  InputNumber,
  Spin,
  Col,
  Row,
  Select,
  Input,
  Divider,
  Typography,
  Card,
  Checkbox,
  Tooltip,
} from 'antd';
import TabSnippet from '@app/view/components/Text/TabSnippet';
import { Forms } from '@app/helpers/Constants/forms';
import { validateMessages } from '@app/helpers/Validator/validateMessages';
import FormFooter from '@app/view/components/Form/FormFooter';
import { ChevronDown, Info, X } from 'react-feather';
import { currencyFormatter } from '@app/helpers/Expense/currencyFormatter';
import { Project } from '@app/model/Project';
const { Option } = Select;
const { Text, Title } = Typography;

type ProjectBudgetTabProps = {
  project?: Project;
  setProject: (project: Project) => void;
};
export const ProjectBudgetTab = ({
  project,
  setProject,
}: ProjectBudgetTabProps) => {
  enum Intake {
    Payment = 'Payment',
    Budget = 'Budget',
  }

  enum Arrangements {
    FixedFee = 'Fixed-Fee',
    NonBillable = 'Non-Billable',
    Retainer = 'Retainer',
    TimeAndMaterials = 'Time & Materials',
  }

  enum ProjectRates {
    NoRate = 'No Rate',
    ProjectRate = 'Project Rate',
    TaskRate = 'Per-Task Rate',
    UserRate = 'Per-User Rate',
  }

  enum ProjectIntervals {
    Day = 'Day',
    Week = 'Week',
    Month = 'Month',
    Year = 'Year',
  }

  const [paymentValue, setPaymentValue] = useState<any>(null);
  const [paymentFrequency, setPaymentFrequency] = useState<any>(null);
  const [paymentInterval, setPaymentInterval] =
    useState<ProjectIntervals | null>(ProjectIntervals.Month);
  const [paymentArrangement, setPaymentArrangement] =
    useState<Arrangements | null>(Arrangements.FixedFee);
  const [paymentRate, setPaymentRate] = useState<ProjectRates | null>(
    ProjectRates.ProjectRate,
  );

  const [budgetValue, setBudgetValue] = useState<any>(null);
  const [budgetFrequency, setBudgetFrequency] = useState<any>(null);
  const [budgetInterval, setBudgetInterval] = useState<ProjectIntervals | null>(
    ProjectIntervals.Month,
  );
  const [budgetArrangement, setBudgetArrangement] =
    useState<Arrangements | null>(Arrangements.FixedFee);
  const [budgetRate, setBudgetRate] = useState<ProjectRates | null>(
    ProjectRates.ProjectRate,
  );
  const [budgetPercentage, setBudgetPercentage] = useState<number>(0);

  const [collapsePaymentCard, setCollapsePaymentCard] =
    useState<boolean>(false);
  const [collapseBudgetCard, setCollapseBudgetCard] = useState<boolean>(true);
  const [isSaved, setIsSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const fieldKey = 0;
  const inputFormatter = /\B(?=(\d{3})+(?!\d))/g;
  const inputParser = /\$\s?|(,*)/g;

  const calculatePaymentTotal = (
    value: number,
    frequency: number,
    shouldFormatCurrency = true,
  ) => {
    let newValue = 0;

    if (value) {
      newValue = value;
      if (frequency) {
        newValue = value * frequency;
      }
    }

    return shouldFormatCurrency ? currencyFormatter(newValue) : newValue;
  };

  const getEstimatedRevenue = () => {
    let billable = 0;
    let budgeted = 0;

    if (paymentValue) {
      billable = paymentValue;
      if (paymentFrequency) {
        billable = paymentValue * paymentFrequency;
      }
    }
    if (budgetValue) {
      budgeted = budgetValue;
      if (budgetFrequency) {
        budgeted = budgetValue * budgetFrequency;
      }
    }

    return billable - budgeted;
  };

  const onSelectArrangement = (value: any, intakeType: Intake) => {
    if (intakeType === Intake.Budget) {
      setBudgetArrangement(value);
      setBudgetValue(0);
      setBudgetFrequency(null);
      form.setFieldsValue({
        budgetAmount: 0,
      });
    } else if (intakeType === Intake.Payment) {
      setPaymentArrangement(value);
      setPaymentValue(0);
      setPaymentFrequency(null);
      form.setFieldsValue({
        paymentAmount: 0,
      });
    }
  };

  const renderArrangementOptions = () => (
    <>
      <Option value={Arrangements.FixedFee} key={0}>
        {Arrangements.FixedFee}
      </Option>
      <Option value={Arrangements.NonBillable} key={1}>
        {Arrangements.NonBillable}
      </Option>
      <Option value={Arrangements.Retainer} key={2}>
        {Arrangements.Retainer}
      </Option>
      <Option value={Arrangements.TimeAndMaterials} key={3}>
        {Arrangements.TimeAndMaterials}
      </Option>
    </>
  );

  const renderIntervalOptions = () => (
    <>
      <Option value={ProjectIntervals.Day} key={0}>
        {`${ProjectIntervals.Day}s`}
      </Option>
      <Option value={ProjectIntervals.Week} key={1}>
        {`${ProjectIntervals.Week}s`}
      </Option>
      <Option value={ProjectIntervals.Month} key={2}>
        {`${ProjectIntervals.Month}s`}
      </Option>
      <Option value={ProjectIntervals.Year} key={3}>
        {`${ProjectIntervals.Year}s`}
      </Option>
    </>
  );

  const renderAmountFormItem = (intakeType: Intake) => (
    <Form.Item
      label={intakeType === Intake.Budget ? `Amount` : `Amount`}
      name={intakeType === Intake.Budget ? `budgetAmount` : `paymentAmount`}
      key={fieldKey}
    >
      <InputNumber
        defaultValue={0}
        min={0}
        max={100000000000}
        style={{ width: '100%' }}
        onChange={(value) => {
          intakeType === Intake.Budget
            ? setBudgetValue(value)
            : setPaymentValue(value);
        }}
        formatter={(value) => `$ ${value}`.replace(inputFormatter, ',')}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        parser={(value) => value.replace(inputParser, '')}
      />
    </Form.Item>
  );

  const renderArrangementFields = (intakeType: Intake) => {
    const renderTotalField = () => {
      if (
        (intakeType === Intake.Budget &&
          (budgetArrangement === Arrangements.NonBillable ||
            budgetArrangement === Arrangements.TimeAndMaterials)) ||
        (intakeType === Intake.Payment &&
          (paymentArrangement === Arrangements.NonBillable ||
            paymentArrangement === Arrangements.TimeAndMaterials))
      ) {
        return null;
      }

      return (
        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
          <Form.Item label={'Total'} name={``} key={fieldKey}>
            <Text>
              {intakeType === Intake.Budget
                ? calculatePaymentTotal(budgetValue, budgetFrequency)
                : calculatePaymentTotal(paymentValue, paymentFrequency)}
            </Text>
          </Form.Item>
        </Col>
      );
    };

    return (
      <Row gutter={15} style={{ marginTop: 15 }}>
        <Col xxl={6} xl={12} lg={12} md={12} sm={12} xs={12}>
          <Form.Item
            required
            label={`Arrangement`}
            name={
              intakeType === Intake.Budget
                ? `budgetArrangement`
                : `paymentArrangement`
            }
            key={fieldKey}
          >
            <Select
              showSearch
              style={{ width: '100%' }}
              defaultValue={Arrangements.FixedFee}
              optionFilterProp={`children`}
              placeholder={'Choose an arrangement...'}
              onSelect={(value) => {
                onSelectArrangement(value, intakeType);
              }}
              filterOption={(input, option) =>
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              suffixIcon={
                <ChevronDown
                  size={16}
                  className={'feather-icon feather-icon-select'}
                />
              }
            >
              {renderArrangementOptions()}
            </Select>
          </Form.Item>
        </Col>
        {renderTotalField()}
      </Row>
    );
  };

  const renderRetainerFields = (intakeType: Intake) => {
    return (
      <Row gutter={15}>
        <Col xxl={6} xl={12} lg={12} md={24} sm={24} xs={24}>
          {renderAmountFormItem(intakeType)}
        </Col>

        <Col xxl={10} xl={18} lg={18} md={24} sm={24} xs={24}>
          <Form.Item
            label={intakeType === Intake.Budget ? `Duration` : `Duration`}
            name={
              intakeType === Intake.Budget
                ? `budgetDuration`
                : `paymentDuration`
            }
            key={fieldKey}
          >
            <Input.Group compact>
              <InputNumber
                min={0}
                max={999}
                placeholder={'Enter a frequency (e.g. 12)...'}
                style={{ width: '50%' }}
                onChange={(value) => {
                  intakeType === Intake.Budget
                    ? setBudgetFrequency(value)
                    : setPaymentFrequency(value);
                }}
                formatter={(value) => `${value}`.replace(inputFormatter, ',')}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                parser={(value) => value.replace(inputParser, '')}
              />
              <Select
                allowClear
                showSearch
                defaultValue={ProjectIntervals.Month}
                style={{ width: '50%' }}
                optionFilterProp={`children`}
                placeholder={'Choose an interval...'}
                onChange={(value) => {
                  intakeType === Intake.Budget
                    ? setBudgetInterval(value)
                    : setPaymentInterval(value);
                }}
                filterOption={(input, option) =>
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                clearIcon={
                  <X size={16} className={'feather-icon feather-icon-select'} />
                }
                suffixIcon={
                  <ChevronDown
                    size={16}
                    className={'feather-icon feather-icon-select'}
                  />
                }
              >
                {renderIntervalOptions()}
              </Select>
            </Input.Group>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  const renderFixedFeeFields = (intakeType: Intake) => {
    return (
      <Row gutter={15}>
        <Col xxl={6} xl={12} lg={12} md={24} sm={24} xs={24}>
          {renderAmountFormItem(intakeType)}
        </Col>
      </Row>
    );
  };

  const renderTimeAndMaterialsFields = (intakeType: Intake) => {
    const renderProjectRateField = () => {
      if (
        (intakeType === Intake.Payment &&
          paymentRate === ProjectRates.ProjectRate) ||
        (intakeType === Intake.Budget &&
          budgetRate === ProjectRates.ProjectRate)
      ) {
        return (
          <Col xxl={6} xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item
              label={'Project/Blended Rate'}
              name={
                intakeType === Intake.Budget
                  ? `budgetProjectRate`
                  : `paymentProjectRate`
              }
              key={fieldKey}
            >
              <InputNumber
                defaultValue={0}
                min={0}
                max={100000000000}
                style={{ width: '100%' }}
                formatter={(value) => `$ ${value}`.replace(inputFormatter, ',')}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                parser={(value) => value.replace(inputParser, '')}
              />
            </Form.Item>
          </Col>
        );
      }
      return null;
    };

    const renderUserRateField = () => {
      if (
        (intakeType === Intake.Payment &&
          paymentRate === ProjectRates.UserRate) ||
        (intakeType === Intake.Budget && budgetRate === ProjectRates.UserRate)
      ) {
        return (
          <Col xxl={6} xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item label={' '} name={''} key={fieldKey}>
              <Text>Assign rates in the Users tab</Text>
            </Form.Item>
          </Col>
        );
      }
      return null;
    };

    const renderTaskRateField = () => {
      if (
        (intakeType === Intake.Payment &&
          paymentRate === ProjectRates.TaskRate) ||
        (intakeType === Intake.Budget && budgetRate === ProjectRates.TaskRate)
      ) {
        return (
          <Col xxl={6} xl={12} lg={12} md={24} sm={24} xs={24}>
            <Form.Item label={' '} name={''} key={fieldKey}>
              <Text>Assign rates in the Tasks tab</Text>
            </Form.Item>
          </Col>
        );
      }
      return null;
    };

    return (
      <Row gutter={15}>
        <Col xxl={6} xl={12} lg={12} md={24} sm={24} xs={24}>
          <Form.Item
            label={`Time & Material Rates`}
            name={intakeType === Intake.Budget ? `budgetRates` : `paymentRates`}
            key={fieldKey}
          >
            <Select
              allowClear
              showSearch
              defaultValue={ProjectRates.ProjectRate}
              style={{ width: '100%' }}
              optionFilterProp={`children`}
              placeholder={'Choose a rate...'}
              onChange={(value) => {
                intakeType === Intake.Budget
                  ? setBudgetRate(value)
                  : setPaymentRate(value);
              }}
              filterOption={(input, option) =>
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              clearIcon={
                <X size={16} className={'feather-icon feather-icon-select'} />
              }
              suffixIcon={
                <ChevronDown
                  size={16}
                  className={'feather-icon feather-icon-select'}
                />
              }
            >
              <>
                <Option value={ProjectRates.NoRate} key={0}>
                  {ProjectRates.NoRate}
                </Option>
                <Option value={ProjectRates.ProjectRate} key={1}>
                  {ProjectRates.ProjectRate}
                </Option>
                <Option value={ProjectRates.TaskRate} key={2}>
                  {ProjectRates.TaskRate}
                </Option>
                <Option value={ProjectRates.UserRate} key={3}>
                  {ProjectRates.UserRate}
                </Option>
              </>
            </Select>
          </Form.Item>
        </Col>
        {renderProjectRateField()}
        {renderUserRateField()}
        {renderTaskRateField()}
      </Row>
    );
  };

  const renderBudgetCheckboxOptions = () => {
    if (budgetArrangement === Arrangements.NonBillable) {
      return null;
    }

    const budget = calculatePaymentTotal(
      budgetValue,
      budgetFrequency,
      false,
    ) as number;

    const renderBudgetPercentage = () => (
      <Form.Item
        label={''}
        name={'budgetPercentage'}
        key={fieldKey}
        style={{ marginBottom: 0 }}
      >
        <InputNumber
          style={{
            maxWidth: 60,
            marginLeft: 8,
            marginRight: 8,
            position: 'relative',
            bottom: 4,
          }}
          size={'small'}
          defaultValue={0}
          min={0}
          max={100}
          onChange={(value) => {
            setBudgetPercentage(value as number);
          }}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          parser={(value) => value.replace(inputParser, '')}
        />
      </Form.Item>
    );

    return (
      <Row gutter={15}>
        {budgetInterval !== null &&
          budgetArrangement === Arrangements.Retainer && (
            <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
              <Form.Item
                label={''}
                name={'budgetResets'}
                key={fieldKey}
                valuePropName={'checked'}
                style={{ marginBottom: 0 }}
              >
                <Checkbox style={{ textTransform: 'unset' }}>
                  Budget resets every {budgetInterval.toLowerCase()}
                </Checkbox>
              </Form.Item>
            </Col>
          )}
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form.Item
            label={''}
            name={`includesNonBillable`}
            key={fieldKey}
            valuePropName={'checked'}
            style={{ marginBottom: 0 }}
          >
            <Checkbox>
              Budget includes billable and non-billable expenses
            </Checkbox>
          </Form.Item>
        </Col>
        <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
          <Form.Item
            label={''}
            name={`sendAlerts`}
            key={fieldKey}
            valuePropName={'checked'}
            style={{ marginBottom: 0 }}
          >
            <Checkbox>
              <Row style={{ width: '100%' }}>
                Send alerts if project exceeds {renderBudgetPercentage()}{' '}
                percent of budget (
                {currencyFormatter(budget * (budgetPercentage / 100))})
              </Row>
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  const renderTotalBillableRow = () => {
    let output = '';

    if (paymentArrangement === Arrangements.TimeAndMaterials) {
      output = `Variable Rate | ${paymentRate}`;
    } else if (paymentArrangement === Arrangements.NonBillable) {
      output = '—';
    } else {
      output = calculatePaymentTotal(
        paymentValue,
        paymentFrequency,
        true,
      ) as string;
    }

    return (
      <Row gutter={15} style={{ marginBottom: 6 }}>
        <Col xxl={5} xl={6} lg={12} md={12} sm={12} xs={12}>
          <Text style={{ fontWeight: 500 }}>Total Billable</Text>
        </Col>
        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
          <Text>{output}</Text>
        </Col>
      </Row>
    );
  };

  const renderTotalBudgetedRow = () => {
    let output: string = calculatePaymentTotal(
      budgetValue,
      budgetFrequency,
    ) as string;

    if (
      budgetArrangement === Arrangements.TimeAndMaterials ||
      budgetArrangement === Arrangements.NonBillable
    ) {
      output = '—';
    }

    return (
      <Row gutter={15} style={{ marginBottom: 6 }}>
        <Col xxl={5} xl={6} lg={12} md={12} sm={12} xs={12}>
          <Text style={{ fontWeight: 500 }}>Total Budgeted</Text>
        </Col>
        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
          <Text>{output}</Text>
        </Col>
      </Row>
    );
  };

  const renderEstimatedRevenueRow = () => {
    const revenue: number = getEstimatedRevenue();
    let output: string = currencyFormatter(revenue) as string;

    if (
      paymentArrangement === Arrangements.TimeAndMaterials ||
      budgetArrangement === Arrangements.NonBillable
    ) {
      output = '—';
    }

    return (
      <Row gutter={15}>
        <Col xxl={5} xl={6} lg={12} md={12} sm={12} xs={12}>
          <Text style={{ fontWeight: 500 }}>Estimated Revenue</Text>
        </Col>
        <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
          <Text
            className={
              Math.sign(revenue) === -1 && output !== '—' ? 'color-error' : ''
            }
          >
            {output}
          </Text>
        </Col>
      </Row>
    );
  };

  const onFinish = (values: any) => {
    console.log(values);
    setIsLoading(true);
  };

  const onFinishFailed = () => {
    setIsLoading(false);
    message.error('Unable to save').then();
  };

  const onReset = () => {
    form.resetFields();
    setPaymentValue(null);
    setPaymentFrequency(null);
    setBudgetValue(null);
    setBudgetFrequency(null);
    setBudgetInterval(null);
  };

  return (
    <Form
      autoComplete={'on'}
      autoCapitalize={'off'}
      form={form}
      layout={'vertical'}
      id={Forms.ProjectBudgetForm}
      name={Forms.ProjectBudgetForm}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      scrollToFirstError={true}
      validateMessages={validateMessages}
      wrapperCol={{ span: 24 }}
    >
      <TabSnippet
        title={'Budget'}
        subtitle={'Set up how much you will bill and what you will spend.'}
        style={{ marginBottom: 20 }}
      />
      <Spin spinning={isLoading}>
        <Card style={{ marginBottom: 20 }}>
          <Row justify={'space-between'}>
            <Col
              onClick={() => {
                setCollapsePaymentCard(!collapsePaymentCard);
              }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 0,
                cursor: 'pointer',
              }}
            >
              <Title level={5} style={{ fontWeight: 500, marginBottom: 0 }}>
                Payment Structure
                <Tooltip
                  title={
                    'The total amount that you plan to bill or invoice the client for this project.'
                  }
                >
                  <Info
                    size={14}
                    style={{
                      position: 'relative',
                      top: 2,
                      marginLeft: 7,
                    }}
                  />
                </Tooltip>
              </Title>
            </Col>

            <ChevronDown
              onClick={() => {
                setCollapsePaymentCard(!collapsePaymentCard);
              }}
              size={16}
              className={'feather-icon feather-icon-select'}
              style={{ transform: collapsePaymentCard ? 'rotate(180deg)' : '' }}
            />
          </Row>

          {!collapsePaymentCard && (
            <>
              {renderArrangementFields(Intake.Payment)}
              {paymentArrangement === Arrangements.Retainer &&
                renderRetainerFields(Intake.Payment)}
              {paymentArrangement === Arrangements.FixedFee &&
                renderFixedFeeFields(Intake.Payment)}
              {paymentArrangement === Arrangements.TimeAndMaterials &&
                renderTimeAndMaterialsFields(Intake.Payment)}
            </>
          )}
        </Card>

        <Card>
          <Row justify={'space-between'}>
            <Col
              onClick={() => {
                setCollapseBudgetCard(!collapseBudgetCard);
              }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: 0,
                cursor: 'pointer',
              }}
            >
              <Title level={5} style={{ fontWeight: 500, marginBottom: 0 }}>
                Budget Structure
                <Tooltip
                  title={
                    'The total amount that you plan to spend to complete this project.'
                  }
                >
                  <Info
                    size={14}
                    style={{
                      position: 'relative',
                      top: 2,
                      marginLeft: 7,
                    }}
                  />
                </Tooltip>
              </Title>
            </Col>

            <ChevronDown
              onClick={() => {
                setCollapseBudgetCard(!collapseBudgetCard);
              }}
              size={16}
              className={'feather-icon feather-icon-select'}
              style={{ transform: collapseBudgetCard ? 'rotate(180deg)' : '' }}
            />
          </Row>

          {!collapseBudgetCard && (
            <>
              {renderArrangementFields(Intake.Budget)}
              {budgetArrangement === Arrangements.Retainer &&
                renderRetainerFields(Intake.Budget)}
              {budgetArrangement === Arrangements.FixedFee &&
                renderFixedFeeFields(Intake.Budget)}
              {budgetArrangement === Arrangements.TimeAndMaterials &&
                renderTimeAndMaterialsFields(Intake.Budget)}
              {renderBudgetCheckboxOptions()}
            </>
          )}
        </Card>

        <Divider className={'divider-spacer'} />

        <Title level={4}>Totals</Title>

        <Card className={'card-light'} style={{ marginTop: 10 }}>
          {renderTotalBillableRow()}
          {renderTotalBudgetedRow()}
          {renderEstimatedRevenueRow()}
        </Card>

        <FormFooter
          isSaved={isSaved}
          onReset={onReset}
          form={Forms.ProjectBudgetForm}
        />
      </Spin>
    </Form>
  );
};

export default ProjectBudgetTab;
