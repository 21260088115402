import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, message, notification, Row, Spin } from 'antd';
import TabSnippet from '@app/view/components/Text/TabSnippet';
import { Forms } from '@app/helpers/Constants/forms';
import { validateMessages } from '@app/helpers/Validator/validateMessages';
import { APIAssembly, Methods } from '@app/data/API';
import { IDataResult } from '@app/model/IDataResult';
import { UserContext } from '@app/contexts/UserContext';
import { plainToClass } from 'class-transformer';
import { Select } from 'antd';
import { SelectValue } from 'antd/es/select';
import { ChevronDown, Plus } from 'react-feather';
import { User } from '@app/model/User';
import NewUserInviteModal from '@app/view/components/Modals/NewUserInviteModal';
import { Role } from '@app/model/Role';
import { Project } from '@app/model/Project';
import ProjectUserTable from '@app/view/components/Tables/ProjectUserTable';
const { Option } = Select;

type NewProjectUsersTabProps = {
  project: Project | undefined;
};

export const ProjectUsersTab = ({ project }: NewProjectUsersTabProps) => {
  const [value, setValue] = useState<SelectValue>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const { user, api: API } = useContext(UserContext);

  const userData = user.getUser();

  useEffect(() => {
    getUsers();
    getRoles();
    setSelectedUsers(project?.users ? project?.users : []);
  }, []);

  /**
   * Get Users with AccountUser nested object.
   */
  const getUsers = () => {
    API.get(`api/v2/account/${userData.account?.id}/users`)
      .then((result: IDataResult) => {
        const _users: any = plainToClass(User, result.data);
        setUsers(_users);
      })
      .catch((error: any) => {
        console.error(error);
      });
    API.get(`api/v2/project/${project?.id}/users`)
      .then((results: IDataResult) => {
        const _users: any = plainToClass(User, results.data);
        setSelectedUsers(_users);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  /**
   * Get Roles for assignment once User is selected.
   */
  const getRoles = () => {
    API.get(`api/v2/roles`)
      .then((result: IDataResult) => {
        const _roles: any = plainToClass(Role, result.data);
        setRoles(_roles);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  /**
   * Remove a user from a project entirely, or just from the interface if not saved yet.
   *
   * @param user A User object from selection.
   */
  const deleteUserFromProject = (user: User) => {
    if (user.roleId) {
      setIsLoading(true);
      const assembly: APIAssembly = {
        endpoint: `api/v2/project/${project?.id}/user/${user.id}`,
        method: Methods.DELETE,
      };

      API.query(assembly.endpoint, assembly.method, assembly.body)
        .then(() => {
          setIsLoading(false);
          removeSelectedUser(user);
          api.success({
            message: 'Success',
            description: `${user.firstName} was deleted from the project`,
          });
        })
        .catch(() => {
          message.error(`Could not complete request`).then();
        });
    } else {
      removeSelectedUser(user);
    }
  };

  /**
   * OnSelect of Option, add user to selectedUsers and remove from user datasource.
   *
   * @param value The Option's value, in this case the user's index.
   */
  const onSelectUser = (value: any) => {
    const user = users[value];
    if (!selectedUsers.includes(user)) {
      setValue([]);

      const _selectedUsers = [...selectedUsers];
      _selectedUsers.push(user);
      console.log(_selectedUsers);
      setSelectedUsers(_selectedUsers);
    }
  };

  /**
   * OnClick of trash button will remove a selectedUser and add a user back to datasource.
   *
   * @param record A User record provided by row.
   */
  const removeSelectedUser = (record: User) => {
    const _users = [...users];
    _users.push(record);
    _users.sort(function (a, b) {
      return a.firstName?.localeCompare(b.firstName);
    });
    setUsers(_users);

    const _selectedUsers = selectedUsers.filter(
      (selectedUser) => selectedUser !== record,
    );
    setSelectedUsers(_selectedUsers);
  };

  const onFinish = () => {
    setIsLoading(true);
  };

  const onFinishFailed = () => {
    setIsLoading(false);
    message.error('Unable to save').then();
  };

  return (
    <>
      {contextHolder}

      <Form
        autoComplete={'on'}
        autoCapitalize={'off'}
        form={form}
        layout={'vertical'}
        id={Forms.ProjectRolesForm}
        name={Forms.ProjectRolesForm}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={true}
        validateMessages={validateMessages}
        wrapperCol={{ span: 24 }}
      >
        <TabSnippet
          title={'Users'}
          subtitle={
            'Assign roles to users to provide read/write access to this project.'
          }
          style={{ marginBottom: 20 }}
        />
        <Spin spinning={isLoading}>
          <Row gutter={15} style={{ marginBottom: 20 }}>
            <Col span={12}>
              <Select
                showSearch
                style={{ minWidth: '100%' }}
                optionFilterProp={`children`}
                placeholder={'Assign a person...'}
                value={value}
                onSelect={onSelectUser}
                filterOption={(input, option) =>
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                suffixIcon={
                  <ChevronDown
                    size={16}
                    className={'feather-icon feather-icon-select'}
                  />
                }
              >
                {users.map((user: User, index: number) => {
                  return (
                    <Option value={index} key={index}>
                      {`${user.firstName} ${user.lastName}`}
                    </Option>
                  );
                })}
              </Select>
            </Col>

            <Col span={8}>
              <Button
                onClick={() => {
                  setIsModalVisible(true);
                }}
                type={'ghost'}
                icon={
                  <Plus
                    size={14}
                    className={'feather-icon feather-icon-button-prefix'}
                  />
                }
              >
                Invite User
              </Button>
            </Col>
          </Row>

          <Row gutter={15}>
            <Col span={24}>
              <ProjectUserTable
                users={selectedUsers}
                setUsers={setSelectedUsers}
                roles={roles}
                api={api}
                onDeleteUser={(user: User) => {
                  deleteUserFromProject(user);
                }}
                project={project}
              />
            </Col>
          </Row>

          <NewUserInviteModal
            project={project}
            onCancel={() => {
              setIsModalVisible(false);
            }}
            visible={isModalVisible}
          />
        </Spin>
      </Form>
    </>
  );
};

export default ProjectUsersTab;
