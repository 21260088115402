import { Expose, Type } from 'class-transformer';
import { Roles } from './Roles';

export class Role {
  @Expose() id: number;

  @Expose() rolableType: string;
  @Expose() name: Roles;
  @Expose() description?: string | null;
  @Expose() isEnabled?: number | null;
  @Expose() isBillable?: number | null;
  @Expose() chargeRate?: number | null;
  @Expose() meta?: string | null;

  @Type(() => Date)
  createdAt?: Date;
  @Type(() => Date)
  updatedAt?: Date;
}
