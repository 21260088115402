import React, { useContext } from 'react';
import { Form, Button, Input, Col, Row, notification } from 'antd';
import { Link } from 'react-router-dom';
import AuthWrapper from '../../../components/Views/AuthWrapper';
import { UserContext } from '@app/contexts/UserContext';

function ForgotPassword() {
  const { api: API } = useContext(UserContext);
  const [api, contextHolder] = notification.useNotification();

  async function handleSubmit(values: any) {
    try {
      await API.query(`api/v2/forgot-password`, 'POST', values);
      api.success({
        message: 'Success',
        description: `If your email address exists an email to reset your password will be sent.`,
      });
    } catch (e) {
      console.log(e);
      api.error({
        message: 'Error',
        description: `Unable to send reset password`,
      });
    }
  }

  return (
    <>
      {contextHolder}
      <AuthWrapper
        title={'Reset Password'}
        subtitle={
          'Please enter the email address that you use to sign in, and we will email you a link to reset your password.'
        }
      >
        <Row justify={'center'}>
          <Col span={24}>
            <Form name="forgot-password" onFinish={handleSubmit}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    type: 'email',
                    message: 'Please input a valid email address.',
                    validateTrigger: 'onSubmit',
                  },
                  {
                    min: 5,
                    message: 'Email must be minimum of 5 characters.',
                    validateTrigger: 'onSubmit',
                  },
                ]}
              >
                <Input placeholder="Email address" />
              </Form.Item>
              <Row justify={'end'}>
                <Form.Item style={{ marginRight: 10 }}>
                  <Button type={'ghost'}>
                    <Link to="/login">Cancel</Link>
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Row>
            </Form>
          </Col>
        </Row>
      </AuthWrapper>
    </>
  );
}

export default ForgotPassword;
