import './style.scss';
import React from 'react';
import { Card, Col, Row, Typography } from 'antd';
const { Text, Title } = Typography;

interface AuthWrapperProps {
  children: React.ReactNode;
  title?: string | undefined;
  subtitle?: string | undefined;
  footer?: React.ReactNode | undefined;
}

const AuthWrapper: React.FC<AuthWrapperProps> = ({
  children,
  title,
  subtitle,
  footer,
}) => {
  return (
    <Row justify={'center'} align={'middle'} style={{ height: '100vh' }}>
      <Col
        style={{
          position: 'relative',
          bottom: 100,
        }}
      >
        <Card className={'auth-card'}>
          {(title || subtitle) && (
            <Row className={'auth-card-header'}>
              <Col>
                <Title
                  level={3}
                  style={{
                    marginBottom: 5,
                  }}
                >
                  {title}
                </Title>
                <Text>{subtitle}</Text>
              </Col>
            </Row>
          )}
          {children}
        </Card>

        {footer && (
          <Row className={'footer'} justify={'end'}>
            {footer}
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default AuthWrapper;
