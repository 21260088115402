import React, { useContext, useEffect, useState } from 'react';
import './styles.scss';
import {
  Table,
  Row,
  Tooltip,
  Select,
  Button,
  InputNumber,
  Form,
  Typography,
  Popconfirm,
  message,
} from 'antd';
import { User } from '@app/model/User';
import MailTo from '../../Text/MailTo';
import { currencyFormatter } from '@app/helpers/Expense/currencyFormatter';
import { ChevronDown, Edit2, Save, Trash, XCircle } from 'react-feather';
import { Role } from '@app/model/Role';
import TitleCell from '../../Cells/GlobalTableCells/TitleCell';
import {
  Item,
  ProjectUserTableProps,
  EditableCellProps,
} from '@app/view/components/Tables/ProjectUserTable/types';
import { APIAssembly, Methods } from '@app/data/API';
import { IDataResult } from '@app/model/IDataResult';
import { UserContext } from '@app/contexts/UserContext';
const { Text } = Typography;

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  inputType,
  record,
  keyIndex,
  children,
  roles,
  ...restProps
}) => {
  const inputNode =
    inputType === 'number' ? (
      <InputNumber
        placeholder={currencyFormatter(record?.taskable?.rate)}
        formatter={(value) => currencyFormatter(value as number)}
      />
    ) : (
      <Select
        showSearch
        style={{ minWidth: 200 }}
        optionFilterProp={`children`}
        placeholder={'Assign a role...'}
        filterOption={(input, option) =>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        suffixIcon={
          <ChevronDown
            size={16}
            className={'feather-icon feather-icon-select'}
          />
        }
        options={roles?.map((role: Role) => {
          return { label: role.name, value: role.id };
        })}
      />
    );

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={keyIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message:
                keyIndex === 'rate'
                  ? 'Please input a rate'
                  : 'Please select a role.',
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const ProjectUserTable = ({
  users,
  roles,
  project,
  onDeleteUser,
  setUsers,
  api,
}: ProjectUserTableProps) => {
  const [editingKey, setEditingKey] = useState<number>(0);
  const [currentUsers, setCurrentUser] = useState<User[]>([]);
  const [form] = Form.useForm();
  const { api: API } = useContext(UserContext);

  useEffect(() => {
    return () => {
      setEditingKey(0);
    };
  }, []);

  useEffect((): void => {
    if (
      users.length !== 0 &&
      currentUsers.length !== 0 &&
      users.length !== currentUsers.length &&
      currentUsers.length < users.length
    ) {
      setEditingKey(users[users.length - 1].id);
    }

    setCurrentUser(users);
  }, [users]);

  const isEditing = (record: User) => {
    return record.id === editingKey;
  };

  const edit = (record: Partial<User>) => {
    form.setFieldsValue({ rate: record.rate, roleId: record.roleId });
    setEditingKey(record.id as number);
  };

  const cancel = (key: number) => {
    const index = users.findIndex((item) => key === item.id);
    if (
      !users[index].roleId &&
      confirm('Without saving this task will be removed. Are you sure?')
    ) {
      const updateTasks = [...users];
      updateTasks.pop();
      setUsers(updateTasks);
    }
    setEditingKey(0);
  };

  const save = async (key: number) => {
    const row = (await form.validateFields()) as Item;
    const index = users.findIndex((item) => key === item.id);
    const record = users[index];

    const assembly: APIAssembly = {
      endpoint: `api/v2/project/${project?.id}/user/${record.id}/role/${row.roleId}`,
      method: Methods.POST,
      body: { rate: row.rate },
    };

    API.query(assembly.endpoint, assembly.method, assembly.body)
      .then((result: IDataResult) => {
        /* @todo get rate from ProjectUser */
        api.success({
          message: 'Success',
          description: `${record.firstName} was added to the project`,
        });
        const updatedUser = [...users];
        updatedUser[index].rate = row.rate;
        updatedUser[index].roleId = row.roleId as unknown as number;
        updatedUser[index].role = result.data.roles[0].name;
        setEditingKey(0);

        setUsers(updatedUser);
      })
      .catch((error: any) => {
        message.error(`Could not complete request ${error}`).then();
      });
  };

  const columns = [
    {
      title: <TitleCell title={'First Name'} />,
      dataIndex: 'firstName',
      key: 'firstName',
      width: 150,
    },
    {
      title: <TitleCell title={'Last Name'} />,
      dataIndex: 'lastName',
      key: 'lastName',
      width: 150,
    },
    {
      title: <TitleCell title={'Email Address'} />,
      dataIndex: 'email',
      key: 'email',
      width: 250,
      render(_: any, record: User) {
        return {
          children: (
            <Button
              // onClick={() => {}}
              type={'link'}
              style={{ fontWeight: 300 }}
            >
              <MailTo email={record.email} subject={'HourHand'} body={''}>
                {record.email}
              </MailTo>
            </Button>
          ),
        };
      },
    },
    {
      title: <TitleCell title={'Rate on Project'} />,
      dataIndex: 'rate',
      key: 'rate',
      width: 200,
      editable: true,
      render(_: any, record: User) {
        return <Text>{currencyFormatter(record?.rate as number)}</Text>;
      },
    },
    {
      title: <TitleCell title={'Role'} />,
      dataIndex: 'role',
      editable: true,
      key: 'roleId',
      width: 200,
    },
    {
      title: <TitleCell title={'Actions'} alignItems={'flex-end'} />,
      dataIndex: 'action',
      key: 'action',
      width: 100,
      render: (_: any, record: User) => {
        const editable = isEditing(record);
        return editable ? (
          <Row justify={'end'}>
            <Save
              onClick={async () => {
                await save(record.id);
              }}
              className={'feather-icon feather-icon-danger'}
              size={14}
              style={{ marginRight: '10px' }}
            />
            <XCircle
              onClick={() => {
                cancel(record.id);
              }}
              className={'feather-icon feather-icon-danger'}
              size={14}
            />
          </Row>
        ) : (
          <Row justify={'end'}>
            <Tooltip title={`Edit ${record.firstName}.`}>
              <Edit2
                onClick={() => {
                  if (editingKey === 0) {
                    edit(record);
                  }
                }}
                style={{ marginRight: '10px' }}
                className={'feather-icon feather-icon-danger'}
                size={14}
              />
            </Tooltip>
            <Tooltip title={`Remove ${record.firstName} from this project.`}>
              <Popconfirm
                title={'Delete user from project'}
                onConfirm={async () => onDeleteUser(record)}
              >
                <Trash
                  className={'feather-icon feather-icon-danger'}
                  size={14}
                />
              </Popconfirm>
            </Tooltip>
          </Row>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    console.log(col.key);
    return {
      ...col,
      onCell: (record: User) => ({
        record,
        inputType: col.key === 'rate' ? 'number' : 'select',
        dataIndex: col.dataIndex,
        title: col.title,
        keyIndex: col.key,
        editing: isEditing(record),
        roles: roles,
      }),
    };
  });

  return (
    <Form form={form}>
      <Table<User>
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        columns={mergedColumns}
        dataSource={users}
        pagination={false}
        size={'middle'}
        rowClassName={'user-row-spacing'}
      />
    </Form>
  );
};

export default ProjectUserTable;
