import React from 'react';
import { Col, Row, Typography } from 'antd';
const { Text, Title } = Typography;

type TabSnippetProps = {
  title: string;
  subtitle?: string | null | undefined;
  style?: any | null | undefined;
};

export const TabSnippet = ({
  title,
  subtitle,
  style,
  ...props
}: TabSnippetProps) => {
  return (
    <Row {...props} style={style}>
      <Col span={24}>
        <Title level={4} style={{ marginBottom: 0, fontWeight: 500 }}>
          {title}
        </Title>
        {subtitle && <Text type={'secondary'}>{subtitle}</Text>}
      </Col>
    </Row>
  );
};

export default TabSnippet;
