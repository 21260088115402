import React, { useContext, useEffect, useState } from 'react';
import Wrapper from '@app/view/components/Views/Wrapper';
import {
  Button,
  Card,
  Col,
  Form,
  message,
  Row,
  Select,
  Table,
  Typography,
  DatePicker,
} from 'antd';
import { TimeOptions } from '@app/view/pages/Reports/options';
import { Client } from '@app/model/Client';
import { IDataResult } from '@app/model/IDataResult';
import { UserContext } from '@app/contexts/UserContext';
import { Forms } from '@app/helpers/Constants/forms';
import { TableData, RangeValue } from '@app/view/pages/Reports/types';
import { ColumnsType } from 'antd/lib/table';
import { RangePickerProps } from 'antd/lib/date-picker';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

function Reports() {
  const [clients, setClients] = useState<Client[]>([]);
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [custom, setCustom] = useState<boolean>(false);
  const [dates, setDates] = useState<RangeValue>(null);
  const [form] = Form.useForm();
  const { user, api: API } = useContext(UserContext);
  const { Text } = Typography;

  const userData = user.getUser();

  useEffect(() => {
    API.get(`api/v2/account/${userData.account?.id}/clients`)
      .then((result: IDataResult) => {
        setClients(result.data);
      })
      .catch(() => {
        message.error('There was an issue retrieving clients');
      });
  }, []);

  const onTimeFrameChange = (value: string) => {
    setCustom(value === 'custom');
  };
  const onSubmit = (values: any) => {
    const params = new URLSearchParams();
    for (const data in values) {
      if (typeof values[data] !== 'undefined' && data !== 'custom_range') {
        params.set(data, values[data]);
      } else if (
        data == 'custom_range' &&
        typeof values[data] !== 'undefined'
      ) {
        params.set('start', values[data][0].format('YYYY-MM-DD'));
        params.set('end', values[data][1].format('YYYY-MM-DD'));
      }
    }
    API.get(`api/v2/reports/${userData.account.id}?${params.toString()}`).then(
      (results: IDataResult) => {
        setSubmitted(true);

        const data = [];
        let i = 0;
        for (const date in results.data) {
          data.push({
            key: i,
            client: date.replace(/([0-9]{4})([0-9]{2})([0-9]{2})/, `$1-$2-$3`),
          } as TableData);
          results.data[date].forEach((e: TableData) => {
            data.push(Object.assign({}, e, { key: ++i }));
          });
        }

        setTableData(data);
      },
    );
  };

  const columns: ColumnsType<TableData> = [
    {
      title: 'Client',
      dataIndex: 'client',
      key: 'client',
    },
    {
      title: 'Project',
      dataIndex: 'project',
      key: 'project',
    },
    {
      title: 'Task',
      dataIndex: 'task',
      key: 'task',
    },
    {
      title: 'Hours',
      dataIndex: 'hours',
      key: 'hours',
    },
    {
      title: 'Tracked By',
      dataIndex: 'user',
      key: 'user',
    },
  ];

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    if (!dates || !dates[0]) {
      return current && current > dayjs().endOf('day');
    }

    return (
      current.diff(dates[0], 'days') <= 0 || current > dayjs().endOf('day')
    );
  };

  const onOpenChange = (open: boolean) => {
    setDates(null);
  };

  return (
    <Wrapper title={'Reports'}>
      <Row gutter={15} justify={'space-between'} style={{ marginBottom: 15 }}>
        <Col span={24}>
          <Form
            id={Forms.ReportsForm}
            name={Forms.ReportsForm}
            layout={'vertical'}
            form={form}
            onFinish={onSubmit}
          >
            <Row gutter={15}>
              <Col span={12}>
                <Form.Item label={'Timeframe'} name={'range'}>
                  <Select options={TimeOptions} onChange={onTimeFrameChange} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={'Client'} name={'client'}>
                  <Select
                    options={clients.map((e) => {
                      return { label: e.name, value: e.id };
                    })}
                  />
                </Form.Item>
              </Col>
            </Row>
            {custom && (
              <Row gutter={15}>
                <Col span={12}>
                  <Form.Item
                    label={'Date Range'}
                    name={'custom_range'}
                    rules={[{ required: custom }]}
                  >
                    <RangePicker
                      disabledDate={disabledDate}
                      onCalendarChange={(val) => setDates(val)}
                      onOpenChange={onOpenChange}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <Button
                  form={Forms.ReportsForm}
                  type={'primary'}
                  key={'submit'}
                  htmlType={'submit'}
                >
                  {'Generate Report'}
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      {tableData.length !== 0 && submitted && (
        <Row gutter={15} justify={'space-between'}>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={tableData}
              expandable={{
                expandedRowRender: (record) => (
                  <p>
                    <strong>Task Description:</strong> {record.description}
                  </p>
                ),
                rowExpandable: (record) =>
                  typeof record.description !== 'undefined' &&
                  record.description !== null,
              }}
              pagination={false}
              footer={(data) => {
                let value = 0;
                data.forEach((e) => {
                  value += e.hours || 0;
                });
                return (
                  <>
                    <Text style={{ fontWeight: 'bold' }}>Total Hours: </Text>
                    <Text style={{ fontWeight: 'bold' }}>
                      {Math.round(value)}
                    </Text>
                  </>
                );
              }}
            />
          </Col>
        </Row>
      )}

      {tableData.length === 0 && submitted && (
        <Card style={{ textAlign: 'center' }}>
          <Text>No data value for the selected filters</Text>
        </Card>
      )}
    </Wrapper>
  );
}

export default Reports;
