import React, { useContext, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Card,
  Button,
  message,
  Checkbox,
  Spin,
  notification,
} from 'antd';
import { Forms } from '@app/helpers/Constants/forms';
import { Client, ClientContact } from '@app/model/Client';
import { IErrorResult } from '@app/model/IErrorResult';
import { IDataResult } from '@app/model/IDataResult';
import { UserContext } from '@app/contexts/UserContext';
const { TextArea } = Input;

type ContactCardProps = {
  clientID: number | null | undefined;
  index: number;
  onRemove: (index: number) => void;
  client: Client | null;
  setClient: (client: Client) => void;
};

type Payload = {
  contact: ClientContact[] | [];
};

export const ContactCard = ({
  index,
  onRemove,
  client,
  clientID,
  setClient,
}: ContactCardProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();
  const { api: API } = useContext(UserContext);
  const [form] = Form.useForm();
  const fieldName = 'contact';
  const fieldKey = index;

  useEffect(() => {
    if (client) {
      const contactData = {
        contact: {
          name:
            client?.meta?.contact && client?.meta?.contact[index]
              ? client?.meta?.contact[index]?.name
              : '',
          firstName:
            client?.meta?.contact && client?.meta?.contact[index]
              ? client?.meta?.contact[index].firstName
              : '',
          lastName:
            client?.meta?.contact && client?.meta?.contact[index]
              ? client?.meta?.contact[index].lastName
              : '',
          emailAddress:
            client?.meta?.contact && client?.meta?.contact[index]
              ? client?.meta?.contact[index].emailAddress
              : '',
          phoneNumber:
            client?.meta?.contact && client?.meta?.contact[index]
              ? client?.meta?.contact[index].phoneNumber
              : '',
          officeNumber:
            client?.meta?.contact && client?.meta?.contact[index]
              ? client?.meta?.contact[index].officeNumber
              : '',
          title:
            client?.meta?.contact && client?.meta?.contact[index]
              ? client?.meta?.contact[index].title
              : '',
          notes:
            client?.meta?.contact && client?.meta?.contact[index]
              ? client?.meta?.contact[index].notes
              : '',
          isPrimary:
            client?.meta?.contact && client?.meta?.contact[index]
              ? client?.meta?.contact[index].isPrimary
              : '',
        },
      };
      form.setFieldsValue(contactData);
    }
  }, [client]);

  const onFinish = (values: any) => {
    setIsSaved(true);

    const payload: Payload = { contact: [] };
    if (client?.meta?.contact) {
      payload.contact = client.meta.contact;
    }
    payload.contact[index] = values.contact as ClientContact;

    API.query(`api/v2/client/${clientID}`, 'PUT', payload)
      .then((result: IDataResult) => {
        api.success({
          message: 'Success',
          description: 'The clients contact data has been saved',
        });
        setClient(result.data);
        setIsLoading(false);
      })
      .catch((error: IErrorResult) => {
        api.error({
          message: 'Error',
          description: 'Could not save client location data',
        });
        console.error(error.message);
      });
  };

  const onFinishFailed = () => {
    message.error('Unable to save').then();
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <>
      {contextHolder}
      <Form
        autoComplete={'on'}
        autoCapitalize={'off'}
        form={form}
        layout={'vertical'}
        id={Forms.CreateContactForm + index}
        name={Forms.CreateContactForm + index}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={true}
        style={{ paddingBottom: 0 }}
        wrapperCol={{ span: 24 }}
      >
        <Spin spinning={isLoading}>
          <Card style={{ marginBottom: 20 }} key={index}>
            <Row gutter={15}>
              <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
                <Form.Item
                  required
                  label={'First Name'}
                  name={[fieldName, 'firstName']}
                  key={fieldKey}
                  rules={[{ required: true }, { type: 'string', min: 2 }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
                <Form.Item
                  required
                  label={'Last Name'}
                  name={[fieldName, 'lastName']}
                  key={fieldKey}
                  rules={[{ required: true }, { type: 'string', min: 2 }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Form.Item
                  label={'Title'}
                  name={[fieldName, 'title']}
                  key={fieldKey}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={15}>
              <Col xxl={8} xl={8} lg={8} md={24} sm={24} xs={24}>
                <Form.Item
                  label={'Email Address'}
                  name={[fieldName, 'emailAddress']}
                  key={fieldKey}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
                <Form.Item
                  label={'Phone Number'}
                  name={[fieldName, 'phoneNumber']}
                  key={fieldKey}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col xxl={8} xl={8} lg={8} md={12} sm={12} xs={12}>
                <Form.Item
                  label={'Office Number'}
                  name={[fieldName, 'officeNumber']}
                  key={fieldKey}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={15}>
              <Col span={24}>
                <Form.Item
                  label={'Notes'}
                  name={[fieldName, 'notes']}
                  key={fieldKey}
                  style={{
                    marginBottom: 0,
                  }}
                >
                  <TextArea
                    rows={1}
                    placeholder={
                      'Best time to contact, preferred name/pronouns, etc.'
                    }
                    style={{ width: '100%', maxHeight: 80 }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row justify={'space-between'} style={{ marginTop: 15 }}>
              <Col xxl={6} xl={6} lg={12} md={12} sm={12} xs={12}>
                <Form.Item
                  label={''}
                  name={[fieldName, 'isPrimary']}
                  key={fieldKey}
                  valuePropName={'checked'}
                >
                  <Checkbox>Primary Point of Contact</Checkbox>
                </Form.Item>
              </Col>
              <Row justify={'end'} gutter={10}>
                {(!isSaved ||
                  (client?.meta?.contact && client?.meta?.contact[index])) &&
                  !form.getFieldValue(['contact', 'isPrimary']) && (
                    <Col>
                      <Form.Item style={{ marginBottom: 0 }}>
                        <Button
                          form={Forms.CreateLocationForm + index}
                          type={'ghost'}
                          onClick={() => {
                            {
                              client?.meta?.contact &&
                              client?.meta?.contact[index]
                                ? onRemove(index)
                                : onReset();
                            }
                          }}
                        >
                          {client?.meta?.contact &&
                          client?.meta?.contact[index] &&
                          !form.getFieldValue(['contact', 'isPrimary'])
                            ? 'Remove'
                            : 'Clear'}
                        </Button>
                      </Form.Item>
                    </Col>
                  )}
                <Col>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Button
                      form={Forms.CreateContactForm + index}
                      type={'primary'}
                      key={'submit'}
                      htmlType={'submit'}
                    >
                      {client?.meta?.contact && client?.meta?.contact[index]
                        ? 'Update'
                        : 'Save'}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Row>
          </Card>
        </Spin>
      </Form>
    </>
  );
};

export default ContactCard;
