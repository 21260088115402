import { List, Typography, Divider } from 'antd';

const data = [
  'Development',
  'Business Development',
  'Project Management',
  'Other Stuff',
];

function Projects(props: any) {
  return (
    <div>
      <h2 style={{ marginBottom: '2rem' }}>Your Projects</h2>

      <List
        header={<div style={{ fontWeight: 'bolder' }}>Marinus</div>}
        style={{ marginBottom: '2rem' }}
        bordered
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <Typography.Text mark></Typography.Text> {item}
          </List.Item>
        )}
      />

      <List
        header={<div style={{ fontWeight: 'bolder' }}>Deka</div>}
        style={{ marginBottom: '2rem' }}
        bordered
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <Typography.Text mark></Typography.Text> {item}
          </List.Item>
        )}
      />
    </div>
  );
}

export default Projects;
