import React, { useContext } from 'react';
import './style.scss';
import { Image, Typography } from 'antd';
import { UserContext } from '@app/contexts/UserContext';

type LoadingProps = {
  children: React.ReactNode;
  isLoading: boolean;
};

const Loading = ({ isLoading, children }: LoadingProps) => {
  const { darkMode } = useContext(UserContext);
  const { Text } = Typography;

  return (
    <>
      <div
        className={
          'loading' +
          (!isLoading ? ' loadingComplete' : '') +
          (darkMode ? ' darkMode' : '')
        }
      >
        <div>
          <Image
            alt={'HourHand Icon'}
            src={
              darkMode
                ? '/assets/images/icon-2.svg'
                : '/assets/images/icon-1.svg'
            }
            preview={false}
          />
          <Text>Loading....</Text>
        </div>
      </div>
      {children}
    </>
  );
};

export default Loading;
