import { TwitterPicker } from 'react-color';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Typography,
  Spin,
  notification,
} from 'antd';
import { ChevronDown, Plus } from 'react-feather';
import { DatePicker, Space } from 'antd';
import TabSnippet from '@app/view/components/Text/TabSnippet';
import { Forms } from '@app/helpers/Constants/forms';
import { validateMessages } from '@app/helpers/Validator/validateMessages';
import { IDataResult } from '@app/model/IDataResult';
import { Client } from '@app/model/Client';
import { APIAssembly, Methods } from '@app/data/API';
import { UserContext } from '@app/contexts/UserContext';
import FormFooter from '@app/view/components/Form/FormFooter';
import { useHistory } from 'react-router-dom';
import { Tags } from '@app/model/Tags';
const { Option } = Select;
const { Text } = Typography;
const { TextArea } = Input;

export enum NewProjectDetailsTabFormValues {
  clientId = 'clientId',
  name = 'name',
  color = 'color',
  description = 'description',
  code = 'code',
  requireTimeApprovals = 'require_time_approvals',
  invoiceFrequency = 'invoice_frequency',
  startedAt = 'started_at',
  endedAt = 'ended_at',
  tags = 'tags',
}

export const NewProjectDetailsTab = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState<Client[]>([]);
  const [showClientInput, setShowClientInput] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [tags, setTags] = useState<Tags[]>([]);
  const [color, setColor] = useState('');
  const values = NewProjectDetailsTabFormValues;
  const [form] = Form.useForm();
  const { user, api: API } = useContext(UserContext);
  const [api, contextHolder] = notification.useNotification();
  const history = useHistory();
  const section = `project`;

  const userData = user.getUser();

  useEffect(() => {
    getData();
  }, []);

  /**
   * Get Clients.
   */
  const getData = () => {
    API.get(`api/v2/account/${userData.account?.id}/clients`)
      .then((result: IDataResult) => {
        setClients(result.data);
      })
      .catch((error: any) => {
        api.error({
          message: 'Error',
          description: `Error getting clients ${error}`,
        });
      });

    API.get(`api/v2/${userData.account?.id}/tags`)
      .then((result: IDataResult) => {
        setTags(result.data);
      })
      .catch((error: any) => {
        api.error({
          message: 'Error',
          description: `Error getting tags ${error}`,
        });
      });
  };

  /**
   * Create or Update Project.
   */
  const onFinish = (values: any) => {
    setIsLoading(true);

    if (typeof values.project === `undefined`) {
      onFinishFailed();
      return;
    }

    const clientId = values.project.clientId;
    const assembly: APIAssembly = {
      endpoint: `api/v2/client/${clientId}/project`,
      method: Methods.POST,
      body: values.project,
    };

    API.query(assembly.endpoint, assembly.method, assembly.body)
      .then((result: IDataResult) => {
        setIsLoading(false);
        api.success({
          message: 'Success',
          description: `Project saved successfully`,
        });

        history.push(`/projects/${result.data.id}`);
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  const onFinishFailed = () => {
    setIsLoading(false);
    api.error({
      message: 'Error',
      description: `Unable to save`,
    });
  };

  const onReset = () => {
    form.resetFields();
  };

  const onChangeColor = (color: any) => {
    color.hex && setColor(color.hex);
    color.hex &&
      form.setFieldsValue({
        project: {
          color: color.hex,
        },
      });
    setShowColorPicker(false);
  };

  const disabledEndDates = (value: any) => {
    return form && value < form.getFieldValue('startedAt');
  };

  if (!clients) {
    return null;
  }

  return (
    <>
      {contextHolder}
      <Form
        autoComplete={'on'}
        autoCapitalize={'off'}
        form={form}
        layout={'vertical'}
        id={Forms.ProjectDetailsForm}
        name={Forms.ProjectDetailsForm}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        scrollToFirstError={true}
        validateMessages={validateMessages}
        wrapperCol={{ span: 24 }}
      >
        <TabSnippet
          title={'Details'}
          subtitle={'Create a project by assigning a client and name.'}
          style={{ marginBottom: 20 }}
        />
        <Spin spinning={isLoading}>
          <Form.Item
            required
            label={'Client'}
            name={[section, values.clientId]}
            key={1}
          >
            <Row gutter={15}>
              <Col span={16}>
                {showClientInput ? (
                  <Input placeholder={"Enter Client's Name"} />
                ) : (
                  <Select
                    suffixIcon={<ChevronDown size={14} />}
                    allowClear
                    defaultValue={undefined}
                    onChange={(value: string) => {
                      form.setFieldsValue({
                        project: {
                          clientId: value,
                        },
                      });
                    }}
                    placeholder={'Select Client...'}
                  >
                    {clients.map((client: Client, index: number) => {
                      return (
                        <Option value={client?.id} key={index}>
                          {client.name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
              </Col>
              <Col span={8}>
                <Button
                  onClick={() => {
                    setShowClientInput(!showClientInput);
                  }}
                  icon={
                    !showClientInput && (
                      <Plus
                        size={14}
                        className={'feather-icon feather-icon-button-prefix'}
                      />
                    )
                  }
                >
                  {showClientInput ? 'Select Client' : 'New Client'}
                </Button>
              </Col>
            </Row>
          </Form.Item>

          <Row gutter={15}>
            <Col xxl={16} xl={12} lg={12} md={24} sm={24} xs={24}>
              <Form.Item
                required
                label={'Project Name'}
                name={[section, values.name]}
                key={2}
                rules={[{ required: true }, { type: 'string', min: 2 }]}
              >
                <Input placeholder={'e.g. Web Design'} />
              </Form.Item>
            </Col>

            <Col xxl={4} xl={8} lg={6} md={12} sm={24} xs={24}>
              <Form.Item label={'Color'} name={[section, values.color]} key={3}>
                <Input
                  placeholder={'Select Color...'}
                  onClick={() => {
                    setShowColorPicker(!showColorPicker);
                  }}
                />

                {showColorPicker && (
                  <>
                    <div
                      style={{
                        zIndex: 999,
                        height: 300,
                        position: 'absolute',
                      }}
                    >
                      <TwitterPicker
                        color={color}
                        onChangeComplete={onChangeColor}
                      />
                    </div>
                  </>
                )}
              </Form.Item>
            </Col>

            <Col xxl={4} xl={4} lg={6} md={12} sm={24} xs={24}>
              <Form.Item label={'Code'} name={[section, values.code]} key={4}>
                <Input placeholder={'Last: JP-WD'} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={15}>
            <Col xxl={4} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={'Start Date'}
                name={[section, values.startedAt]}
                key={5}
                rules={[
                  {
                    type: 'object',
                    required: false,
                    whitespace: true,
                  },
                ]}
              >
                <DatePicker style={{ width: '100%' }} placeholder={''} />
              </Form.Item>
            </Col>

            <Col xxl={4} xl={8} lg={12} md={12} sm={24} xs={24}>
              <Form.Item
                label={'End Date'}
                name={[section, values.endedAt]}
                key={6}
              >
                <DatePicker
                  disabledDate={disabledEndDates}
                  placeholder={''}
                  style={{ width: '100%' }}
                  //onChange={(date, dateString) => {}}
                />
              </Form.Item>
            </Col>

            <Col xxl={16} xl={8} lg={24} md={24} sm={24} xs={24}>
              <Form.Item label={'Tags'} name={[section, values.tags]} key={7}>
                <Select
                  mode="tags"
                  style={{ width: '100%' }}
                  placeholder={'Add Tags...'}
                  defaultValue={undefined}
                  optionLabelProp="label"
                  options={tags.map((e) => {
                    return { value: e.id, label: e.name };
                  })}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={15}>
            <Col span={24}>
              <Form.Item
                label={'Notes or Description'}
                name={[section, values.description]}
                key={8}
                className={'form-item-description'}
              >
                <Space direction={'vertical'} size={10}>
                  <Text type={'secondary'}>
                    Notes can only be viewed by administrators or those with
                    proper permission on projects.
                  </Text>
                  <TextArea rows={2} style={{ width: '100%' }} />
                </Space>
              </Form.Item>
            </Col>
          </Row>

          <FormFooter
            isSaved={false}
            onReset={onReset}
            form={Forms.ProjectDetailsForm}
          />
        </Spin>
      </Form>
    </>
  );
};

export default NewProjectDetailsTab;
