import { Expose, Type } from 'class-transformer';

export class Location {
  @Expose() id: number | undefined;

  @Expose() name: string | undefined;
  @Expose() description?: string | null | undefined;
  @Expose() meta?: string | null | undefined;

  @Type(() => Date)
  createdAt?: Date | undefined;

  @Type(() => Date)
  updatedAt?: Date | undefined;
}
