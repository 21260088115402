import { Expose, Type } from 'class-transformer';
import { Account } from '@app/model/Account';

class TagMeta {
  color?: string | null;
}
export class Tags {
  @Expose() id: number;
  @Expose() accountId: number;
  @Expose() name: string;
  @Expose() description?: string | null;
  @Type(() => Date)
  disabledAt?: Date;
  disabledByUserId?: number;
  @Type(() => Date)
  archivedAt?: Date;
  archivedByUserId: number;
  @Type(() => Date)
  deletedAt?: Date;
  deletedByUserId: number;
  @Type(() => TagMeta)
  meta?: TagMeta | null;

  createdByUserId: number;
  updatedByUserId: number;
  @Type(() => Date)
  createdAt?: Date;
  @Type(() => Date)
  updatedAt?: Date;

  @Type(() => Account)
  account?: Account | null;
}

export const QuantityType = {
  time: 'time',
  product: 'product',
  service: 'service',
  deliverable: 'deliverable',
};
