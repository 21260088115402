import React from 'react';
import { Button, Col, Row, Tabs, Typography } from 'antd';
import { NavLink, useHistory } from 'react-router-dom';
const { Title, Text } = Typography;

function NotFoundPage() {
  const history = useHistory();

  return (
    <Row
      gutter={15}
      justify={'center'}
      align={'middle'}
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      <Col
        span={8}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Title level={3} style={{ textAlign: 'center' }}>
          Not Found
        </Title>

        <Button
          onClick={() => {
            history.goBack();
          }}
          type={`ghost`}
          style={{ marginTop: 30 }}
        >
          Go Back
        </Button>
      </Col>
    </Row>
  );
}

export default NotFoundPage;
