import { Client } from './Client';
import { Expose, Type } from 'class-transformer';
import { User } from './User';
import { Task } from './Task';
import { Project } from './Project';

export class Expense {
  constructor(
    clientId?: number | null | undefined,
    executedAt?: Date | null | undefined,
    isBillable?: number | null | undefined,
    rate?: number | null | undefined,
    rateFrequency?: string | null | undefined,
    quantity?: number | null | undefined,
  ) {
    this.clientId = clientId;
    this.executedAt = executedAt;
    this.isBillable = isBillable;
    this.rate = rate;
    this.rateFrequency = rateFrequency;
    this.quantity = quantity;
  }

  @Expose() key?: string | null | undefined;
  @Expose() id: number | undefined;
  @Expose() clientId?: number | null | undefined;
  @Expose() projectId?: number | null | undefined;
  @Expose() invoiceId?: number | null | undefined;
  @Expose() taskId?: number | null | undefined;
  @Expose() userId?: number | null | undefined;

  @Expose() type: string | undefined;
  @Expose() quantity?: number | null | undefined;
  @Expose() rate?: number | null | undefined;
  @Expose() rateFrequency?: string | null | undefined;
  @Expose() description?: string | null | undefined;
  @Expose() isBillable?: number | null | undefined;
  @Expose() isReimbursable?: number | undefined;
  @Expose() isReimbursed?: number | undefined;
  @Expose() reimbursableCategoryId?: number | undefined;
  @Expose() reimbursablePercentage?: number | undefined;
  @Expose() reimbursedToUserId?: number | null | undefined;
  @Expose() isApproved?: number | undefined;
  @Expose() approvedByUserId?: number | null | undefined;
  @Expose() isInvoiced?: number | undefined;
  @Expose() purchasedFrom?: string | undefined;
  @Expose() meta?: string | null | undefined;

  children?: any | null | undefined;

  @Type(() => Date)
  createdAt?: Date | undefined;
  @Type(() => Date)
  updatedAt?: Date | undefined;
  @Type(() => Date)
  startAt?: Date | undefined;
  @Type(() => Date)
  endAt?: Date | undefined;
  @Type(() => Date)
  purchasedAt?: Date | null | undefined;
  @Type(() => Date)
  reimbursedAt?: Date | null | undefined;
  @Type(() => Date)
  executedAt?: Date | null | undefined;

  @Type(() => Client)
  client?: Client | null | undefined;
  @Type(() => Project)
  project?: Project | null | undefined;
  @Type(() => Task)
  task?: Task | null | undefined;
  @Type(() => User)
  user?: User | null | undefined;

  getClientName? = () => {
    return this.project && this.project.client && this.project.client.name;
  };

  getProjectName? = () => {
    return this.project && this.project.name;
  };

  getTaskName? = () => {
    return this.task && this.task.name;
  };
}
