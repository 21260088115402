import React from 'react';
import Wrapper from '@app/view/components/Views/Wrapper';

function ClientDetailPage() {
  return (
    <Wrapper title={'Client Detail'}>
      <></>
    </Wrapper>
  );
}

export default ClientDetailPage;
